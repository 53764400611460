import { useEffect, useRef } from 'react'

import {
  isValidNostoPlacementId,
  useNostoClient,
} from '../../../../app/_components/chrome/scripts/NostoScript'
import { useLocalization } from '../../../../app/_providers/LocalizationProvider.client'
import { Cart } from '../../../../types/figs'
import { useFixturesContext } from '../../fixtures/FixturesContext'

export function useTrackNostoCartChanges(cart: Cart) {
  const { nostoUpsellId } = useFixturesContext()
  const { region } = useLocalization()
  const nostoClient = useNostoClient()
  const lastTrackingTrigger = useRef('')

  useEffect(() => {
    const newTrackingTrigger = `${cart.totalPrice}:${cart.items}`
    if (lastTrackingTrigger.current === newTrackingTrigger) return
    lastTrackingTrigger.current = newTrackingTrigger

    const cartChangeProps: Parameters<typeof nostoClient.trackCartChange>[0] = {
      currencyCode: region.currency,
      cartItems: (Object.values(cart.items) ?? []).map(item => {
        return {
          title: item.title,
          quantity: item.quantity,
          effectivePriceInCents: item.effectivePrice,
          shopifyProductGid: item.productId,
          shopifyVariantGid: item.variantId,
        }
      }),
      nostoPlacementIds: isValidNostoPlacementId(nostoUpsellId) ? [nostoUpsellId] : [],
    }
    nostoClient.trackCartChange(cartChangeProps)
  }, [cart.items, cart.totalPrice, nostoClient, nostoUpsellId, region.currency])
}
