import { useLocalization } from '@syconium/magnolia/src/app/_providers/LocalizationProvider.client'
import { useFixturesContext } from '@syconium/magnolia/src/brunswick/containers/fixtures'
import { ContentfulRegion } from '@syconium/magnolia/src/types/graphql'

export function IntlShippingAndTaxMsg() {
  const { region } = useLocalization()
  const { regionData } = useFixturesContext()
  const currentRegionData = regionData?.regions.find((r: ContentfulRegion) => r.id === region.id)
  return (
    <>
      {currentRegionData?.shippingText && <div>{currentRegionData?.shippingText}</div>}
      {currentRegionData?.taxAndDutyText && <div>{currentRegionData?.taxAndDutyText}</div>}
    </>
  )
}
