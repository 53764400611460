import React from 'react'

import { useLocalization } from '@syconium/magnolia/src/app/_providers/LocalizationProvider.client'
import { useTargetCriteria } from '@syconium/magnolia/src/lib/hooks/useTargetCriteria'
import {
  AlternateModelVariantAction,
  NavListItem as NavListItemType,
  NavListSection as Props,
} from '@syconium/magnolia/src/types/graphql'

import { NavListItem } from '../NavListItem'

import { Body, Divider, MarginFixer, MarginFixerList } from './styles'

// Quick hack before BFCM to hide pages we currently do not want to show for international regions
// Will be followed up with a more robust solution post BFCM
const omitFromIntl: (string | null)[] = ['/pages/gift-card-collection', '/collections/figs-eko']

export const NavListSection: React.FC<Props> = ({ variant, items }) => {
  const { isUSRegion } = useLocalization()
  const { checkCriteria } = useTargetCriteria()

  return (
    <Body>
      <MarginFixer variant={variant} role='list'>
        {items.map(item => {
          let listItem = item
          if (item.alternateModelVariant) {
            if (checkCriteria(item.alternateModelVariant.targetCriteria)) {
              if (item.alternateModelVariant.action === AlternateModelVariantAction.hideOriginal) {
                return null
              } else if (
                !!item.alternateModelVariant.validContentfulModel &&
                item.alternateModelVariant.validContentfulModel.__typename === 'NavListItem'
              ) {
                listItem = item.alternateModelVariant.validContentfulModel as NavListItemType
              }
            }
          }

          if (!isUSRegion && omitFromIntl.includes(listItem?.href)) {
            return null
          }
          return (
            <MarginFixerList key={listItem.id} isHeading={listItem.isHeading} role='none'>
              {item.hasDivider && <Divider />}
              <NavListItem {...listItem} asTag='div' role='none' variant={variant} />
            </MarginFixerList>
          )
        })}
      </MarginFixer>
    </Body>
  )
}
