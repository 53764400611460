'use client'

import { useMemo } from 'react'
import { createContainer } from 'unstated-next'

import { gql } from '../../__generated__/graphql/catalog'
import { QueryStatus } from '../../lib/hooks/types'
import { User } from '../_config/User.config'

import { useAuthentication } from './AuthenticationProvider.client'
import { useNextQuery } from './GraphqlClientsProvider.client'

export type UseUserOutput = {
  user: User | undefined
  userStatus: Omit<QueryStatus, 'idle'>
}

const useUserImpl = (serverProvidedUser: User | undefined) => {
  const { isLoggedIn } = useAuthentication()
  const { data, loading, error } = useNextQuery(
    gql(`
      query GetUserForClientSidePageView {
        my {
          id
          email
          shopifyId
          profile {
            firstName
            lastName
            phone
            ordersCount
          }
        }
      }`),
    {
      client: 'auth-supported',
      skip: !isLoggedIn || !!serverProvidedUser,
    }
  )

  const clientProvidedUser = useMemo<UseUserOutput['user']>(() => {
    if (!data) return undefined
    return {
      id: data.my.id,
      email: data.my.email,
      shopifyId: data.my.shopifyId?.replace('gid://shopify/Customer/', '') ?? undefined,
      profile: {
        firstName: data.my.profile.firstName ?? 'FIGS Customer',
        lastName: data.my.profile.lastName ?? undefined,
        phone: data.my.profile.phone ?? undefined,
        ordersCount: data.my.profile.ordersCount,
      },
    }
  }, [data])

  const user = useMemo<UseUserOutput['user']>(() => {
    return clientProvidedUser ?? serverProvidedUser
  }, [clientProvidedUser, serverProvidedUser])

  const userStatus = useMemo<UseUserOutput['userStatus']>(() => {
    if (user) return 'resolved'
    if (loading) return 'pending'
    if (error) return 'rejected'
    return 'resolved'
  }, [loading, user, error])

  return {
    user,
    userStatus,
  }
}

const UserContainer = createContainer(useUserImpl)
export const UserProvider = UserContainer.Provider
export const useUser = UserContainer.useContainer
