import { useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { useCookies } from 'react-cookie'

import {
  GetCustomerSizeProfileQuery,
  MySizePreferencesQuery,
  MySizeProfileQuery,
} from '../../../../__generated__/graphql/catalog/graphql'
import { cookieKeys } from '../../../../app/_config/Cookies.config'
import { useGraphqlClients } from '../../../../app/_providers/GraphqlClientsProvider.client'
import { AuthenticationContainer } from '../../../../containers/AuthenticationContainer'
import { MY_SIZE_PREFERENCES, MY_SIZE_PROFILE } from '../../../../lib/graphql/queries'
import { GET_CUSTOMER_SIZE_PROFILE } from '../../../../lib/graphql/queries/GET_CUSTOMER_SIZE_PROFILE'

export const useFetchMySizeProfile = ({
  productGroupGender,
  productGroupCategory,
}: {
  productGroupGender?: string
  productGroupCategory?: string
}) => {
  const { authorizedClient: figsAuthedClient } = useGraphqlClients()
  const { isLoggedIn } = AuthenticationContainer.useContainer()
  const { data, loading, error } = useQuery<MySizePreferencesQuery>(MY_SIZE_PREFERENCES, {
    client: figsAuthedClient,
    skip: !isLoggedIn,
  })
  if (!productGroupGender || !productGroupCategory || error || !isLoggedIn) {
    return { data: null, loading: false }
  }

  return { data, loading }
}

export const useFetchCustomerSizeProfile = ({
  productGroupGender,
  productGroupCategory,
}: {
  productGroupGender?: string
  productGroupCategory?: string
}) => {
  const [cookies] = useCookies([cookieKeys.menSizeRec.key, cookieKeys.womenSizeRec.key])

  const { isLoggedIn } = AuthenticationContainer.useContainer()

  const recId =
    productGroupGender === 'women'
      ? cookies[cookieKeys.womenSizeRec.key] ?? null
      : cookies[cookieKeys.menSizeRec.key] ?? null

  const { data, loading, error } = useQuery<GetCustomerSizeProfileQuery>(
    GET_CUSTOMER_SIZE_PROFILE,
    {
      variables: {
        id: recId,
      },
      skip: isLoggedIn || !productGroupGender || !productGroupCategory || !recId,
    }
  )
  if (!productGroupGender || !productGroupCategory || error || isLoggedIn) {
    return { data: null, loading }
  }

  return { data, loading }
}

export const useFetchRecommendation = ({
  productGroupGender,
  productGroupCategory,
}: {
  productGroupGender?: string
  productGroupCategory?: string
}) => {
  const { data: myData } = useFetchMySizeProfile({
    productGroupGender,
    productGroupCategory,
  })
  const { data: unauthedData } = useFetchCustomerSizeProfile({
    productGroupGender,
    productGroupCategory,
  })

  const recommendation = useMemo(() => {
    if (!myData && !unauthedData) {
      return { fit: undefined, size: undefined }
    }
    if (myData) {
      const rec = myData.my.profile.sizePreferences[
        productGroupGender === 'women' ? 'women' : 'men'
      ]?.settings?.find(setting => setting.category === productGroupCategory)
      return {
        fit: rec?.fit,
        size: rec?.size,
      }
    }

    if (unauthedData) {
      const rec = unauthedData.getCustomerSizeProfile?.recommendations?.find(
        recommendation => recommendation?.category === productGroupCategory
      )
      return {
        fit: rec?.fit,
        size: rec?.size,
      }
    }
    return { fit: undefined, size: undefined }
  }, [productGroupGender, productGroupCategory, myData, unauthedData])
  return recommendation
}

export const useFetchRecommendations = () => {
  const { authorizedClient: figsAuthedClient } = useGraphqlClients()
  const { isLoggedIn } = AuthenticationContainer.useContainer()
  const [cookies] = useCookies([cookieKeys.menSizeRec.key, cookieKeys.womenSizeRec.key])
  const recIdWomen = cookies[cookieKeys.womenSizeRec.key]
  const recIdMen = cookies[cookieKeys.menSizeRec.key]
  const { data: myData, loading: myLoading } = useQuery<MySizePreferencesQuery>(
    MY_SIZE_PREFERENCES,
    {
      client: figsAuthedClient,
      skip: !isLoggedIn,
    }
  )
  const { data: customerDataMen, loading: customerLoadingMen } =
    useQuery<GetCustomerSizeProfileQuery>(GET_CUSTOMER_SIZE_PROFILE, {
      variables: {
        id: recIdMen,
      },
      skip: isLoggedIn || !recIdMen,
    })
  const { data: customerDataWomen, loading: customerLoadingWomen } =
    useQuery<GetCustomerSizeProfileQuery>(GET_CUSTOMER_SIZE_PROFILE, {
      variables: {
        id: recIdWomen,
      },
      skip: isLoggedIn || !recIdWomen,
    })
  const recommendations = useMemo(() => {
    if (!myData && !customerDataMen && !customerDataWomen) {
      return { recsMen: undefined, recsWomen: undefined }
    }
    if (myData) {
      const recsMen = myData.my.profile?.sizePreferences?.['men']?.settings?.map(setting => {
        return { size: setting.size, fit: setting.fit, category: setting.category }
      })
      const recsWomen = myData.my.profile?.sizePreferences?.['women']?.settings?.map(setting => {
        return { size: setting.size, fit: setting.fit, category: setting.category }
      })
      return { recsMen, recsWomen }
    }

    if (customerDataMen || customerDataWomen) {
      const recsMen = customerDataMen?.getCustomerSizeProfile?.recommendations?.map(
        recommendation => {
          return {
            size: recommendation?.size,
            fit: recommendation?.fit,
            category: recommendation?.category,
          }
        }
      )
      const recsWomen = customerDataWomen?.getCustomerSizeProfile?.recommendations?.map(
        recommendation => {
          return {
            size: recommendation?.size,
            fit: recommendation?.fit,
            category: recommendation?.category,
          }
        }
      )
      return { recsMen, recsWomen }
    }
    return { recsMen: undefined, recsWomen: undefined }
  }, [myData, customerDataMen, customerDataWomen])
  return {
    recommendationsMen: recommendations?.recsMen,
    recommendationsWomen: recommendations?.recsWomen,
    loading: myLoading && customerLoadingMen && customerLoadingWomen,
  }
}

export const useFetchFormEntries = () => {
  const { authorizedClient: figsAuthedClient } = useGraphqlClients()
  const { isLoggedIn } = AuthenticationContainer.useContainer()
  const [cookies] = useCookies([cookieKeys.menSizeRec.key, cookieKeys.womenSizeRec.key])
  const recIdWomen = cookies[cookieKeys.womenSizeRec.key]
  const recIdMen = cookies[cookieKeys.menSizeRec.key]
  const { data: myData, loading: myLoading } = useQuery<MySizeProfileQuery>(MY_SIZE_PROFILE, {
    client: figsAuthedClient,
    skip: !isLoggedIn,
  })
  const { data: customerDataMen, loading: customerLoadingMen } =
    useQuery<GetCustomerSizeProfileQuery>(GET_CUSTOMER_SIZE_PROFILE, {
      variables: {
        id: recIdMen,
      },
      skip: isLoggedIn || !recIdMen,
    })
  const { data: customerDataWomen, loading: customerLoadingWomen } =
    useQuery<GetCustomerSizeProfileQuery>(GET_CUSTOMER_SIZE_PROFILE, {
      variables: {
        id: recIdWomen,
      },
      skip: isLoggedIn || !recIdWomen,
    })

  const formEntriesMen = {
    height:
      myData?.my.sizeProfiles?.men?.height ??
      customerDataMen?.getCustomerSizeProfile?.height ??
      undefined,
    weight:
      myData?.my.sizeProfiles?.men?.weight ??
      customerDataMen?.getCustomerSizeProfile?.weight ??
      undefined,
    age:
      myData?.my.sizeProfiles?.men?.age ??
      customerDataMen?.getCustomerSizeProfile?.age ??
      undefined,
  }

  const formEntriesWomen = {
    height:
      myData?.my.sizeProfiles?.women?.height ??
      customerDataWomen?.getCustomerSizeProfile?.height ??
      undefined,
    weight:
      myData?.my.sizeProfiles?.women?.weight ??
      customerDataWomen?.getCustomerSizeProfile?.weight ??
      undefined,
    age:
      myData?.my.sizeProfiles?.women?.age ??
      customerDataWomen?.getCustomerSizeProfile?.age ??
      undefined,
  }

  return {
    formEntriesWomen,
    formEntriesMen,
    loading: myLoading && customerLoadingMen && customerLoadingWomen,
  }
}
