'use client'

import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  MutationHookOptions,
  NormalizedCacheObject,
  OperationVariables,
  QueryHookOptions,
  TypedDocumentNode,
  useApolloClient,
  useMutation,
  useQuery,
} from '@apollo/client'
import {
  ApolloNextAppProvider,
  ApolloClient as NextSSRApolloClient,
  InMemoryCache as NextSSRInMemoryCache,
  SSRMultipartLink,
} from '@apollo/experimental-nextjs-app-support'
import { ReadonlyRequestCookies } from 'next/dist/server/web/spec-extension/adapters/request-cookies'
import { useCallback, useMemo } from 'react'
import { createContainer } from 'unstated-next'

import { QueryStatus } from '../../lib/hooks/types'
import { cookieKeys } from '../_config/Cookies.config'
import {
  GraphQlClients,
  getGraphqlClientLinks,
  getShopifyGraphqlClientLinks,
  graphqlClientsDefaultOptions,
  magnoliaSupergraphGraphqlCacheConfig,
} from '../_config/GraphqlClient.config'

import { useCookies } from './CookiesProvider.client'
import { useLocalization } from './LocalizationProvider.client'
import { usePreviewDirectives } from './PreviewDirectivesProvider.client'

export type UseGraphqlClientsProps =
  | {
      cookies: ReadonlyRequestCookies
    }
  | undefined

const useMakeFigsGraphqlClient = ({
  alias,
  authorized,
}: {
  alias: string
  authorized: boolean
}) => {
  const [cookies, _setCookie, _removeCookie] = useCookies([cookieKeys.authToken.key])
  const authToken: string | undefined = cookies[cookieKeys.authToken.key]
  const localization = useLocalization()
  const previewDirectives = usePreviewDirectives()
  const link = getGraphqlClientLinks({
    includeAuth: authorized,
    authToken,
    graphAlias: alias,
    localization,
    previewDirectives,
  })
  const makeFigsGraphqlClient = useCallback(() => {
    return new NextSSRApolloClient({
      cache: new NextSSRInMemoryCache(magnoliaSupergraphGraphqlCacheConfig),
      link:
        typeof window === 'undefined'
          ? ApolloLink.from([
              // in a SSR environment, if you use multipart features like
              // @defer, you need to decide how to handle these.
              // This strips all interfaces with a `@defer` directive from your queries.
              new SSRMultipartLink({
                stripDefer: true,
              }),
              link,
            ])
          : link,
      defaultOptions: graphqlClientsDefaultOptions,
    })
  }, [link])
  return makeFigsGraphqlClient
}

const useMakeShopifyGraphqlClient = () => {
  const makeShopifyGraphqlClient = useCallback(() => {
    return new NextSSRApolloClient({
      cache: new NextSSRInMemoryCache(),
      link:
        typeof window === 'undefined'
          ? ApolloLink.from([
              // in a SSR environment, if you use multipart features like
              // @defer, you need to decide how to handle these.
              // This strips all interfaces with a `@defer` directive from your queries.
              new SSRMultipartLink({
                stripDefer: true,
              }),
              getShopifyGraphqlClientLinks(),
            ])
          : getShopifyGraphqlClientLinks(),
      defaultOptions: graphqlClientsDefaultOptions,
    })
  }, [])
  return makeShopifyGraphqlClient
}

type GraphqlClientsProviderInitialState =
  | {
      directory: 'app'
      authorizedClient: NextSSRApolloClient<unknown>
      shopifyClient: NextSSRApolloClient<unknown>
    }
  | {
      directory: 'pages'
      authorizedClient: ApolloClient<NormalizedCacheObject>
      shopifyClient: ApolloClient<NormalizedCacheObject>
    }
  | undefined

const useGraphqlClientsImpl = (initialState: GraphqlClientsProviderInitialState) => {
  return {
    authorizedClient: initialState!.authorizedClient,
    shopifyClient: initialState!.shopifyClient,
  }
}
const GraphqlClientsContainer = createContainer(useGraphqlClientsImpl)

type AppDirectoryGraphqlClientsProviderProps = {
  children: React.ReactNode
  directory: 'app'
}

const AppDirectoryGraphqlClientsProvider = ({
  children,
}: AppDirectoryGraphqlClientsProviderProps) => {
  const makeDefaultFigsGraphqlClient = useMakeFigsGraphqlClient({
    alias: process.env.NEXT_PUBLIC_LOCAL_BASE_URL === 'true' ? '' : 'catalog',
    authorized: false,
  })
  const makeAuthorizedFigsGraphqlClient = useMakeFigsGraphqlClient({
    alias: process.env.NEXT_PUBLIC_LOCAL_BASE_URL === 'true' ? '' : 'shop',
    authorized: true,
  })
  const makeShopifyGraphqlClient = useMakeShopifyGraphqlClient()

  const authorizedClient = useMemo(() => {
    return makeAuthorizedFigsGraphqlClient()
  }, [makeAuthorizedFigsGraphqlClient])

  const shopifyClient = useMemo(() => {
    return makeShopifyGraphqlClient()
  }, [makeShopifyGraphqlClient])

  return (
    <ApolloNextAppProvider makeClient={makeDefaultFigsGraphqlClient}>
      <GraphqlClientsContainer.Provider
        initialState={{ directory: 'app', authorizedClient, shopifyClient }}
      >
        {children}
      </GraphqlClientsContainer.Provider>
    </ApolloNextAppProvider>
  )
}

type PagesDirectoryGraphqlClientsProviderProps = {
  children: React.ReactNode
  directory: 'pages'
  defaultClient: ApolloClient<NormalizedCacheObject>
  authorizedClient: ApolloClient<NormalizedCacheObject>
  shopifyClient: ApolloClient<NormalizedCacheObject>
}

const PagesDirectoryGraphqlClientsProvider = ({
  children,
  defaultClient,
  authorizedClient,
  shopifyClient,
}: PagesDirectoryGraphqlClientsProviderProps) => {
  return (
    <ApolloProvider client={defaultClient}>
      <GraphqlClientsContainer.Provider
        initialState={{
          directory: 'pages',
          authorizedClient: authorizedClient,
          shopifyClient: shopifyClient,
        }}
      >
        {children}
      </GraphqlClientsContainer.Provider>
    </ApolloProvider>
  )
}

type GraphqlClientsProviderProps =
  | AppDirectoryGraphqlClientsProviderProps
  | PagesDirectoryGraphqlClientsProviderProps

export const GraphqlClientsProvider = (props: GraphqlClientsProviderProps) => {
  if (props.directory === 'app') {
    return <AppDirectoryGraphqlClientsProvider {...props} />
  } else {
    return <PagesDirectoryGraphqlClientsProvider {...props} />
  }
}

export const useGraphqlClients = (): {
  cacheOptimizedClient: ApolloClient<object>
} & ReturnType<typeof GraphqlClientsContainer.useContainer> => {
  const mainClient = useApolloClient()
  const additionalClients = GraphqlClientsContainer.useContainer()
  return {
    cacheOptimizedClient: mainClient,
    ...additionalClients,
  }
}

export const useNextQuery = <
  TData = unknown,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: TypedDocumentNode<TData, TVariables>,
  queryOptions?: Omit<QueryHookOptions<NoInfer<TData>, NoInfer<TVariables>>, 'client'> & {
    client?: GraphQlClients
  }
) => {
  const additionalClients = GraphqlClientsContainer.useContainer()
  const queryResult = useQuery(query, {
    ...queryOptions,
    client:
      queryOptions?.client === 'auth-supported'
        ? additionalClients.authorizedClient
        : queryOptions?.client === 'shopify'
        ? additionalClients.shopifyClient
        : undefined,
  })
  const status = useMemo<QueryStatus>(() => {
    if (!queryResult.called) return 'idle'
    if (queryResult.loading) return 'pending'
    if (queryResult.error) return 'rejected'
    return 'resolved'
  }, [queryResult.called, queryResult.error, queryResult.loading])
  return {
    ...queryResult,
    status,
  }
}

export const useNextMutation = <
  TData = unknown,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: TypedDocumentNode<TData, TVariables>,
  queryOptions?: Omit<MutationHookOptions<NoInfer<TData>, NoInfer<TVariables>>, 'client'> & {
    client?: GraphQlClients
  }
) => {
  const additionalClients = GraphqlClientsContainer.useContainer()
  const [mutation, mutationResult] = useMutation(query, {
    ...queryOptions,
    client:
      queryOptions?.client === 'auth-supported'
        ? additionalClients.authorizedClient
        : queryOptions?.client === 'shopify'
        ? additionalClients.shopifyClient
        : undefined,
  })
  const status = useMemo<QueryStatus>(() => {
    if (!mutationResult.called) return 'idle'
    if (mutationResult.loading) return 'pending'
    if (mutationResult.error) return 'rejected'
    return 'resolved'
  }, [mutationResult.called, mutationResult.error, mutationResult.loading])
  const output = useMemo(() => {
    return [
      mutation,
      {
        ...mutationResult,
        status,
      },
    ] as const
  }, [mutation, mutationResult, status])
  return output
}
