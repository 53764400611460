import React, { useMemo } from 'react'
import { ThemeProvider } from 'styled-components'

import { defaultLightTheme } from '@syconium/little-miss-figgy'
import { trackEvent } from '@syconium/magnolia/src/lib/analytics'
import { isUppercase } from '@syconium/magnolia/src/lib/utils'
import { NavListItem as Props } from '@syconium/magnolia/src/types/graphql'

import { MaybeSpaLink } from '../../../lib/adapters/next-routing-service/MaybeSpaLink'

import {
  Badge,
  Body,
  Btn,
  FooterItemIcon,
  FooterItemIconWrapper,
  FooterText,
  HeadingText,
  Icon,
  IconWrap,
  RegularWeightHeadingText,
  Text,
  TextWrap,
} from './styles'

const category = 'navigation'

export const NavListItem: React.FC<Props> = ({
  asTag = 'div',
  role,
  text,
  textColor,
  href,
  isHeading,
  isBold,
  badge,
  badgeColor,
  badgeTextColor,
  icon,
  textRole = 'listitem',
  onClickish,
  variant,
  htmlId,
  htmlClass,
  isBtn,
}) => {
  const isTextBold = isBold || isHeading
  const isFooterVariant = variant === 'footer'
  const isSecondaryText = !href && !isHeading

  const onClick: (event: React.MouseEvent<HTMLElement>) => void = event => {
    if (onClickish) onClickish(event)
  }

  const onKeyDown: (event: React.KeyboardEvent<HTMLElement>) => void = event => {
    if (event.key === 'Enter' && onClickish) onClickish(event)
  }

  const shouldRenderHeader = isTextBold && text !== '3XL-6XL'
  const isTextUppercase = isUppercase(text)

  const textTsx = useMemo(() => {
    if (isFooterVariant) {
      return (
        <FooterText
          as={isHeading && !(href || onClickish) ? 'section' : Text}
          $isSecondaryText={isSecondaryText}
          $isHeading={isHeading}
        >
          {text}
        </FooterText>
      )
    } else if (shouldRenderHeader) {
      return <HeadingText>{text}</HeadingText>
    } else if (isTextUppercase) {
      return <RegularWeightHeadingText>{text}</RegularWeightHeadingText>
    }

    return <Text>{text}</Text>
  }, [
    isFooterVariant,
    isHeading,
    href,
    onClickish,
    text,
    shouldRenderHeader,
    isTextUppercase,
    isSecondaryText,
  ])

  return (
    <Body
      as={asTag}
      role={role}
      $isFooterVariant={isFooterVariant}
      $isHeading={isHeading}
      $isSecondaryText={isSecondaryText}
    >
      {icon && !isFooterVariant && (
        <IconWrap>
          <Icon
            alt='nav icon'
            src={icon}
            aspectRatios={{ sm: 1, md: 1 }}
            widths={{ unit: 'px', sm: 16, md: 16 }}
            role='presentation'
            transparentBackground={true}
            loading='lazy'
          />
        </IconWrap>
      )}

      <TextWrap role='none' textColor={textColor}>
        {isBtn ? (
          <Btn
            $isBold={isTextBold}
            $isUppercase={isUppercase(text)}
            $isSecondaryText={isSecondaryText}
            $isHeading={isHeading}
            id={htmlId ?? undefined}
            className={htmlClass ?? undefined}
            role={textRole}
          >
            {textTsx}
          </Btn>
        ) : href || onClickish ? (
          <MaybeSpaLink
            onClick={onClick}
            onKeyDown={onKeyDown}
            href={href ?? '#'}
            tabIndex={onClickish && !href ? 0 : undefined}
            id={htmlId ?? undefined}
            className={htmlClass ?? undefined}
            {...trackEvent({ category, action: 'click list item', label: text })}
            role={textRole}
          >
            {/*
              There are instances where MaybeSpaLink uses the renderToStaticMarkup function to render the children as raw HTML. (OneTrust)
              In order for theme styles to be applied in these cases it needs to be passed into the renderToStaticMarkup function.
            */}
            {isFooterVariant && icon ? (
              <ThemeProvider theme={defaultLightTheme}>
                <FooterItemIconWrapper
                  style={{ display: 'flex', gap: '10px', alignItems: 'center' }}
                >
                  {textTsx}
                  <FooterItemIcon
                    alt='footer nav icon'
                    src={icon}
                    aspectRatios={null}
                    widths={{ unit: 'px', sm: 25, md: 25 }}
                    loading='lazy'
                    role='presentation'
                    transparentBackground={true}
                  />
                </FooterItemIconWrapper>
              </ThemeProvider>
            ) : (
              textTsx
            )}
          </MaybeSpaLink>
        ) : (
          textTsx
        )}
        {badge && <Badge {...{ badgeColor, badgeTextColor }}>{badge}</Badge>}
      </TextWrap>
    </Body>
  )
}
