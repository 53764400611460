import * as React from 'react'

import { BaseIcon, IProps } from '../BaseIcon'

export const QuestionCircle: React.FC<IProps> = ({
  height = '16px',
  width = '16px',
  fill = 'currentColor',
  ...rest
}) => {
  return (
    <BaseIcon height={height} width={width} viewBox='0 0 16 16' fill='none' {...rest}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z'
        fill={fill}
      />
      <path d='M7.33337 10.8339H8.66671V12.1672H7.33337V10.8339Z' fill={fill} />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.11517 5.02317C6.86717 5.23933 6.66671 5.59109 6.66671 6.16635H5.33337C5.33337 5.24159 5.67458 4.51009 6.23908 4.01806C6.78879 3.53891 7.49592 3.33325 8.16683 3.33337C8.8362 3.3335 9.52563 3.53779 10.0628 3.95113C10.6135 4.37485 11 5.02001 11 5.83382C11 6.53402 10.8208 7.06008 10.4402 7.44067C10.1473 7.73353 9.7648 7.89111 9.52128 7.99144C9.48773 8.00526 9.45683 8.01799 9.42915 8.02984C9.15611 8.14677 9.01063 8.23414 8.90445 8.38066C8.79786 8.52775 8.66671 8.82896 8.66671 9.49898H7.33337C7.33337 8.66978 7.49389 8.05491 7.8248 7.59827C8.15612 7.14106 8.59397 6.93705 8.90426 6.80417C8.94124 6.78834 8.97582 6.77361 9.00822 6.75981C9.28666 6.6412 9.404 6.59122 9.4974 6.49782C9.55429 6.44094 9.66671 6.30031 9.66671 5.83382C9.66671 5.48093 9.51156 5.2093 9.24975 5.00786C8.97445 4.79603 8.58055 4.66678 8.16658 4.66671C7.75416 4.66663 7.37796 4.79411 7.11517 5.02317Z'
        fill={fill}
      />
    </BaseIcon>
  )
}
