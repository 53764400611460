import styled, { css } from 'styled-components'

import { Carat, ResponsiveImage, fromMd, timingSlower, untilMd } from '@syconium/little-miss-figgy'
import { VideoButtons } from '@syconium/little-miss-figgy/dist/components/ProgressiveAsset/styles'
import { fadeIn } from '@syconium/magnolia/src/brunswick/styles/shared'

import { NextModalStyles } from '../../../../modals/NextModal'

export const modalStyles: NextModalStyles = {
  content: {
    border: 0,
    borderRadius: 0,
    bottom: 0,
    left: 0,
    padding: 0,
    right: 0,
    top: 0,
  },
  overlay: {
    zIndex: 1000,
  },
}

const expandTouchArea = css`
  &:after {
    bottom: -10px;
    content: '';
    left: -10px;
    position: absolute;
    right: -10px;
    top: -10px;
  }
`

export const CloseButton = styled.button`
  background: none;
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;

  ${untilMd} {
    align-items: center;
    background: #282828;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    margin: 16px;
    padding: 8px;
  }

  ${fromMd} {
    cursor: pointer;
    margin: 40px;
    padding: 12px;
  }

  ${expandTouchArea}
`

export const Container = styled.div`
  height: 100%;

  .fresnel-container {
    height: 100%;
  }
`

// We need this wrapper to prevent an error caused by unmounting the pinch zoom
// image ref without a parent element
export const PinchZoomImageWrapper = styled.div`
  height: 100%;

  ${untilMd} {
    .pinch-zoom-container {
      min-height: 900px;
    }
  }
`

export const PinchZoomImage = styled(ResponsiveImage)`
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
`

const ArrowWrap = styled.button`
  animation: ${fadeIn} ${timingSlower} ease 0s 1 both;
  background: none;
  border: none;
  bottom: 364px;
  cursor: pointer;
  display: block;
  padding: 12px;
  position: absolute;
  z-index: 1;

  ${untilMd} {
    align-items: center;
    background: #ffffffb2;
    border-radius: 50%;
    box-shadow: 0px 0px 15px rgb(0 0 0 / 5%);
    color: #282828;
    display: flex;
    height: 41px;
    justify-content: center;
    top: 50%;
    width: 41px;

    svg {
      height: 17px;
      width: 17px;
    }
  }

  ${expandTouchArea}
`

export const CaretLeft = styled(Carat)`
  transform: rotate(180deg) translate(1px, 0);
`
export const CaretRight = styled(Carat)`
  transform: translate(1px, 0);
`

const arrowIndent = '200px'
const mobileArrowIndent = '16px'

export const LeftArrowWrap = styled(ArrowWrap)`
  left: ${arrowIndent};

  ${untilMd} {
    left: ${mobileArrowIndent};
  }
`

export const RightArrowWrap = styled(ArrowWrap)`
  right: ${arrowIndent};

  ${untilMd} {
    right: ${mobileArrowIndent};
  }
`

export const Video = styled.video`
  background: ${o => o.theme.color.deprecated.stoneLighter};
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
  width: 100%;

  ${untilMd} {
    min-height: 900px;
  }
`

export const StyledVideoButtonsWrapper = styled(VideoButtons)`
  ${untilMd} {
    bottom: 16px;
    top: unset;
  }
`
