'use client'

import { useLocalization } from '../../app/_providers/LocalizationProvider.client'
import { useSession } from '../../app/_providers/SessionProvider.client'
import { AuthenticationContainer } from '../../containers/AuthenticationContainer'
import { TargetCriteria } from '../../types/graphql'

type TargetCriteriaOptions = {
  inRegion?: string[] | null
}

export const useTargetCriteria = () => {
  const { attributes } = useSession()
  const { isLoggedIn } = AuthenticationContainer.useContainer()
  const { region } = useLocalization()

  const isInitialSession = attributes.customerType === 'firstTime'
  const hasPriorSession = attributes.customerType === 'repeat'

  const checkCriteria = (targetCriteria?: TargetCriteria | null): boolean => {
    const options: TargetCriteriaOptions = {
      inRegion: targetCriteria?.inRegion,
    }
    if (!targetCriteria || !targetCriteria?.targetLogic || !targetCriteria?.targetCriteria)
      return false
    switch (targetCriteria.targetLogic) {
      case 'Match Any':
        return targetCriteria.targetCriteria.some(c => checkTargetCriteria(c, options))
      case 'Match All':
        return targetCriteria.targetCriteria.every(c => checkTargetCriteria(c, options))
      default:
        return false
    }
  }

  const checkTargetCriteria = (criteria: string, options: TargetCriteriaOptions): boolean => {
    switch (criteria) {
      case 'Initial Session':
        return isInitialSession
      case 'Has Prior Session':
        return hasPriorSession
      case 'Logged In':
        return isLoggedIn
      case 'In Region':
        return inRegion(options.inRegion)
      default:
        return false
    }
  }

  const inRegion = (regions?: string[] | null) => {
    if (!regions) return false
    if (regions.includes(region.id)) return true
    return false
  }

  return {
    checkCriteria,
    isInitialSession,
    hasPriorSession,
    isLoggedIn,
  }
}
