import { isStandardEmbroideryItem } from '@syconium/magnolia/src/lib/utils'

import { CartItem } from '../../../types/figs'

export function filterPropertiesToDisplay(
  properties: CartItem['properties']
): CartItem['properties'] {
  const propertiesToDisplay: CartItem['properties'] = {}
  Object.keys(properties).forEach(key => {
    if (key[0] !== '_') {
      propertiesToDisplay[key] = properties[key]
    }
  })
  return propertiesToDisplay
}

export function sortCartItems(
  items: ReadonlyArray<CartItem>,
  excludeKitItems: boolean = true
): ReadonlyArray<CartItem> {
  const standardEmbroideryItemsByGuid: Record<string, CartItem> = {}

  const nonEmbroideryItems: Array<CartItem> = []
  items.forEach(item => {
    if (excludeKitItems && item.properties['_kitId']) return
    if (isStandardEmbroideryItem(item) && item.properties._guid) {
      standardEmbroideryItemsByGuid[item.properties._guid] = item
    } else {
      nonEmbroideryItems.push(item)
    }
  })
  const sortedItems: Array<CartItem> = []

  nonEmbroideryItems.forEach(item => {
    sortedItems.push(item)
    const guid: string | undefined = item.properties._guid
    if (guid !== undefined) {
      const itemForGuid = standardEmbroideryItemsByGuid[guid]
      if (itemForGuid) {
        sortedItems.push(itemForGuid)
      }
    }
  })
  return sortedItems
}

export function groupKitItems(items: ReadonlyArray<CartItem>) {
  const itemsByKit = items
    .filter(o => o.properties._kitId && !o.properties._is_logo_setup) // exclude logo digitization setup SKU item
    .reduce<Record<string, CartItem[]>>((accumulator, item) => {
      const key: string = item.properties._kitId || ''
      if (!accumulator[key]) accumulator[key] = []
      accumulator[key]!.push(item)
      return accumulator
    }, {})
  return Object.values(itemsByKit)
}
