/* eslint-disable */
import * as types from './graphql'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  '\n  mutation CreateCheckoutWithLineItems(\n    $allowPartialAddresses: Boolean\n    $lineItems: [CheckoutLineItemInput!]!\n    $shippingAddress: MailingAddressInput\n    $customAttributes: [AttributeInput!]\n    $country: CountryCode!\n    $language: LanguageCode\n  ) @inContext(country: $country, language: $language) {\n    checkoutCreate(\n      input: {\n        allowPartialAddresses: $allowPartialAddresses\n        lineItems: $lineItems\n        shippingAddress: $shippingAddress\n        customAttributes: $customAttributes\n        buyerIdentity: { countryCode: $country }\n      }\n    ) {\n      checkout {\n        buyerIdentity {\n          countryCode\n        }\n        id\n        webUrl\n      }\n      checkoutUserErrors {\n        code\n        field\n        message\n      }\n    }\n  }\n':
    types.CreateCheckoutWithLineItemsDocument,
  '\n  query FetchCheckout($id: ID!) {\n    node(id: $id) {\n      ... on Checkout {\n        completedAt\n      }\n    }\n  }\n':
    types.FetchCheckoutDocument,
  '\n  mutation AssociateCustomerToCheckout($checkoutId: ID!, $customerAccessToken: String!) {\n    checkoutCustomerAssociateV2(\n      checkoutId: $checkoutId\n      customerAccessToken: $customerAccessToken\n    ) {\n      checkoutUserErrors {\n        code\n        field\n        message\n      }\n    }\n  }\n':
    types.AssociateCustomerToCheckoutDocument,
}

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateCheckoutWithLineItems(\n    $allowPartialAddresses: Boolean\n    $lineItems: [CheckoutLineItemInput!]!\n    $shippingAddress: MailingAddressInput\n    $customAttributes: [AttributeInput!]\n    $country: CountryCode!\n    $language: LanguageCode\n  ) @inContext(country: $country, language: $language) {\n    checkoutCreate(\n      input: {\n        allowPartialAddresses: $allowPartialAddresses\n        lineItems: $lineItems\n        shippingAddress: $shippingAddress\n        customAttributes: $customAttributes\n        buyerIdentity: { countryCode: $country }\n      }\n    ) {\n      checkout {\n        buyerIdentity {\n          countryCode\n        }\n        id\n        webUrl\n      }\n      checkoutUserErrors {\n        code\n        field\n        message\n      }\n    }\n  }\n'
): (typeof documents)['\n  mutation CreateCheckoutWithLineItems(\n    $allowPartialAddresses: Boolean\n    $lineItems: [CheckoutLineItemInput!]!\n    $shippingAddress: MailingAddressInput\n    $customAttributes: [AttributeInput!]\n    $country: CountryCode!\n    $language: LanguageCode\n  ) @inContext(country: $country, language: $language) {\n    checkoutCreate(\n      input: {\n        allowPartialAddresses: $allowPartialAddresses\n        lineItems: $lineItems\n        shippingAddress: $shippingAddress\n        customAttributes: $customAttributes\n        buyerIdentity: { countryCode: $country }\n      }\n    ) {\n      checkout {\n        buyerIdentity {\n          countryCode\n        }\n        id\n        webUrl\n      }\n      checkoutUserErrors {\n        code\n        field\n        message\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query FetchCheckout($id: ID!) {\n    node(id: $id) {\n      ... on Checkout {\n        completedAt\n      }\n    }\n  }\n'
): (typeof documents)['\n  query FetchCheckout($id: ID!) {\n    node(id: $id) {\n      ... on Checkout {\n        completedAt\n      }\n    }\n  }\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation AssociateCustomerToCheckout($checkoutId: ID!, $customerAccessToken: String!) {\n    checkoutCustomerAssociateV2(\n      checkoutId: $checkoutId\n      customerAccessToken: $customerAccessToken\n    ) {\n      checkoutUserErrors {\n        code\n        field\n        message\n      }\n    }\n  }\n'
): (typeof documents)['\n  mutation AssociateCustomerToCheckout($checkoutId: ID!, $customerAccessToken: String!) {\n    checkoutCustomerAssociateV2(\n      checkoutId: $checkoutId\n      customerAccessToken: $customerAccessToken\n    ) {\n      checkoutUserErrors {\n        code\n        field\n        message\n      }\n    }\n  }\n']

export function graphql(source: string) {
  return (documents as any)[source] ?? {}
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never
