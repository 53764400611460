'use client'

import { useCallback, useMemo } from 'react'
import { createContainer } from 'unstated-next'

import {
  ShopRegion,
  SupportedRegionIdentifier,
  retrieveLanguageGroupsForRegion,
  supportedLocaleIdentifiers,
  supportedRegions,
} from '@syconium/weeping-figs'

import { LocalizationAttributes } from '../_config/Localization.config'

type UseLocalizationResponse = LocalizationAttributes & {
  isUSRegion: boolean
  locales: typeof supportedLocaleIdentifiers
  regions: Array<(typeof supportedRegions)[SupportedRegionIdentifier]>
  changeLocale: (requestedRegion: ShopRegion, requestedLocale?: string) => void
}

const useLocalizationImpl = (
  localizationAttributes?: LocalizationAttributes
): UseLocalizationResponse => {
  const localization = localizationAttributes!

  const changeLocale = useCallback(
    (requestedRegion: ShopRegion, requestedLocale?: string) => {
      const priorLanguageAndRegionParam = `/${localization.languageGroup}-${localization.region.id}`
      const newRegion = requestedRegion
      const newLocale = requestedLocale ?? requestedRegion.defaultLocale

      // Our locales within our app context are true locales. That is also how Contentful content
      // is broken up. But our URLs are a language plus a country. Which is not a true locale.
      // For example: en-EU, aka European English is a locale but not in our routing method.
      // So we get the languageGroup, then use that with the region code to reload the page.
      const newLanguageGroup = retrieveLanguageGroupsForRegion(newRegion, {
        locale: newLocale,
      })
      const newLanguageAndRegionParam = `/${newLanguageGroup}-${newRegion.id}`

      // Strip out existing prefix, add new prefix.
      const destination = new URL(window.location.href)
      destination.pathname = `${newLanguageAndRegionParam}${destination.pathname.replace(
        priorLanguageAndRegionParam,
        ''
      )}`
      window.location.replace(destination.toString())
    },
    [localization.languageGroup, localization.region.id]
  )

  const regions = useMemo(() => {
    return Object.entries(supportedRegions)
      .filter(([k, _]) => k !== 'default')
      .map(([_, region]) => region)
      .sort((a, b) => a.name.localeCompare(b.name))
  }, [])

  return {
    ...localization,
    isUSRegion: localization.region.id === 'US',
    locales: supportedLocaleIdentifiers,
    regions,
    changeLocale,
  }
}

const LocalizationContainer = createContainer(useLocalizationImpl)
export const LocalizationProvider = ({
  initialState,
  children,
}: {
  initialState: LocalizationAttributes
  children: React.ReactNode
}) => {
  return (
    <LocalizationContainer.Provider initialState={initialState}>
      {children}
    </LocalizationContainer.Provider>
  )
}
export const useLocalization = LocalizationContainer.useContainer
