import { MutableRefObject, useCallback, useState } from 'react'

import { useResizeObserver } from './useResizeObserver'

type ElementDimensions = {
  width: number
  height: number
}

// Get the width & height of a given SVG or HTML element
function deriveElementDimensions(target: Element): ElementDimensions {
  const { width, height } = target.getBoundingClientRect()
  return { width, height }
}

/** Keep track of the dimensions (width & height) of a given DOM element. */
export function useElementDimensions<T extends HTMLElement>(
  ref: MutableRefObject<T | null>,
  initialValues?: ElementDimensions
) {
  const [dimensions, setDimensions] = useState<{ width: number; height: number }>(() => {
    // If initial values are provided, use them for initial render
    if (initialValues) return initialValues
    // If the element reference is immediately available, derive dimensions
    if (ref.current) return deriveElementDimensions(ref.current)
    // Otherwise, default to 0x0
    return { width: 0, height: 0 }
  })

  // Update dimensions state when the element is resized
  const handleResize = useCallback(
    (entries: ResizeObserverEntry[]) => {
      if (!entries[0]) return
      const rect = deriveElementDimensions(entries[0].target)
      setDimensions({ width: rect.width, height: rect.height })
    },
    [setDimensions]
  )
  useResizeObserver(ref, handleResize)

  return dimensions
}
