import { LDOptions as LDClientOptions } from 'launchdarkly-js-client-sdk'

import {
  createExperimentationAccountContext,
  createExperimentationAppContext,
  createExperimentationContext,
  createExperimentationUserContext,
} from '@syconium/weeping-figs'

import { SessionAttributes } from './Session.config'

import type { AccountAuthorization } from './Authentication.config'
import type { LDOptions as LDServerOptions } from '@launchdarkly/node-server-sdk'

type NecessarySessionAttributes = SessionAttributes & {
  sessionId: NonNullable<SessionAttributes['sessionId']>
  visitorId: NonNullable<SessionAttributes['visitorId']>
}

export const createLaunchDarklyContext = ({
  account: authorizedAccount,
  sessionAttributes,
}: {
  account: AccountAuthorization | undefined
  sessionAttributes: NecessarySessionAttributes
}) => {
  const app = createExperimentationAppContext({
    name: 'magnolia',
    version: process.env.NEXT_PUBLIC_MAGNOLIA_VERSION ?? '0.0.0',
  })

  const user = createExperimentationUserContext({
    visitorId: sessionAttributes.visitorId,
    email: authorizedAccount?.email,
    stylePreference: sessionAttributes.stylePreference,
    customerType: sessionAttributes.customerType,
  })

  const account = createExperimentationAccountContext({
    userId: authorizedAccount?.id,
    visitorId: sessionAttributes.visitorId,
  })

  return createExperimentationContext({
    app,
    user,
    account,
    // TODO: Remove session context after currently running LD experiments finish up
    session: {
      key: sessionAttributes.sessionId,
    },
  })
}
export const createLaunchDarklyClientConfig = ({
  bootstrap,
}: {
  bootstrap: object | undefined
}): LDClientOptions => {
  return {
    streaming: true,
    streamReconnectDelay: 3000,
    sendEvents: true,
    sendEventsOnlyForVariation: true,
    bootstrap,
  }
}

export const createLaunchDarklyServerConfig = (): LDServerOptions => {
  return {
    stream: true,
    streamInitialReconnectDelay: 3,
    sendEvents: true,
  }
}
