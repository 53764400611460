import { ReactElement, useCallback, useEffect, useRef, useState } from 'react'

import {
  Carat,
  MediaAsContents,
  RotateClockwise,
  useSupportsHover,
} from '@syconium/little-miss-figgy'

import { MaxIterationsVideo } from '../../../app/_components/media/MaxIterationsVideo.client'
import { ContentfulStyledImage } from '../../../components/contentful/ContentfulStyledImage/ContentfulStyledImage'
import { useResponsiveVideoSrc } from '../../../lib/hooks/useResponsiveVideoSrc'

import {
  FeaturedAsset,
  Grid,
  GridFlex,
  GridTile,
  GridWrapper,
  Slider,
  SliderButtonNext,
  SliderButtonPrevious,
  SliderRow,
  SliderTile,
  SliderWrapper,
  tileGapDefault,
} from './styles'
import { TileLayoutProps } from './types'

function GridLayoutComponent<T>({
  sameHeightGridTiles,
  fullBleed = false,
  keyFromTile,
  renderTile,
  tileGapDesktop = tileGapDefault,
  tileGapMobile = tileGapDefault,
  tiles,
  visibleTilesDesktop = 4,
  visibleTilesMobile = 2,
  hasHorizontalMargin = true,
  hasVerticalMargin = false,
  hasTileGaps = true,
}: TileLayoutProps<T>) {
  return (
    <GridWrapper>
      <Grid
        data-testid='tile-layout-grid'
        fullBleed={fullBleed}
        hasHorizontalMargin={hasHorizontalMargin}
      >
        <GridFlex
          tileGapDesktop={tileGapDesktop}
          tileGapMobile={tileGapMobile}
          hasHorizontalMargin={hasHorizontalMargin}
        >
          {tiles.map((tile, index) => (
            <GridTile
              sameHeightGridTiles={sameHeightGridTiles}
              tileGapDesktop={tileGapDesktop}
              tileGapMobile={tileGapMobile}
              visibleTilesDesktop={visibleTilesDesktop}
              visibleTilesMobile={visibleTilesMobile}
              key={keyFromTile(tile, index)}
              hasTileGaps={hasTileGaps}
              hasVerticalMargin={hasVerticalMargin}
            >
              {renderTile(tile, { layoutVariant: 'grid' }, index)}
            </GridTile>
          ))}
        </GridFlex>
      </Grid>
    </GridWrapper>
  )
}

function SliderLayoutComponent<T>({
  keyFromTile,
  minTileWidth,
  maxTileWidth,
  renderTile,
  hideSectionSideGaps,
  tileGapDesktop = tileGapDefault,
  tileGapMobile = tileGapDefault,
  tiles,
  liftedButtons = false,
  visibleTilesDesktop = 4,
  visibleTilesMobile = 2,
  hasHorizontalMargin = true,
  hasVerticalMargin = true,
  featuredImage,
  featuredVideo,
}: TileLayoutProps<T>) {
  const [scrollsLeft, setScrollsLeft] = useState(false)
  const [scrollsRight, setScrollsRight] = useState(false)
  const hoverSupported = useSupportsHover()
  const hasAsset: boolean = !!featuredImage || !!featuredVideo
  const sliderRef = useRef<HTMLDivElement | null>(null)
  const scrollRight = useCallback(() => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft += sliderRef.current.getBoundingClientRect().width * 0.8
    }
  }, [])
  const scrollLeft = useCallback(() => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft -= sliderRef.current.getBoundingClientRect().width * 0.8
    }
  }, [])
  const checkIfScrollable = useCallback(() => {
    if (sliderRef.current && sliderRef.current.scrollWidth > sliderRef.current.clientWidth) {
      setScrollsLeft(sliderRef.current.scrollLeft > 8)
      setScrollsRight(
        sliderRef.current.scrollLeft + sliderRef.current.clientWidth + 8 <
          sliderRef.current.scrollWidth
      )
    } else {
      setScrollsLeft(false)
      setScrollsRight(false)
    }
  }, [])

  const video = useResponsiveVideoSrc({
    inlineVideoDesktop: featuredVideo?.featuredInlineVideoDesktop,
    inlineVideoMobile: featuredVideo?.featuredInlineVideoMobile,
  })

  useEffect(() => {
    checkIfScrollable()
    window?.addEventListener('resize', checkIfScrollable, { passive: true })
    return () => {
      window?.removeEventListener('resize', checkIfScrollable)
    }
  }, [checkIfScrollable])

  return (
    <>
      {hasAsset ? (
        <MediaAsContents lessThan={'md'}>
          <FeaturedAsset>
            {featuredVideo && !featuredImage && (
              <MaxIterationsVideo
                autoPlay
                loop
                maxIterations={5}
                muted
                playsInline
                src={video?.src}
                aspectRatios={null}
              />
            )}
            {featuredImage && (
              <ContentfulStyledImage
                {...featuredImage}
                __typename='StyledImage'
                widths={{ unit: 'vw', sm: 100, md: 50 }}
                aspectRatioMobile={featuredImage.aspectRatioMobile ?? 375 / 433}
              />
            )}
          </FeaturedAsset>
        </MediaAsContents>
      ) : null}

      <SliderWrapper>
        <Slider
          tabIndex={0}
          ref={sliderRef}
          onScroll={checkIfScrollable}
          hasVerticalMargin={hasVerticalMargin}
          hasAsset={hasAsset}
        >
          {hasAsset ? (
            <MediaAsContents greaterThanOrEqual={'md'}>
              <FeaturedAsset>
                {featuredVideo && !featuredImage && (
                  <MaxIterationsVideo
                    autoPlay
                    loop
                    maxIterations={5}
                    muted
                    playsInline
                    src={video?.src}
                    aspectRatios={null}
                  />
                )}
                {featuredImage && (
                  <ContentfulStyledImage
                    {...featuredImage}
                    __typename='StyledImage'
                    widths={{ unit: 'vw', sm: 100, md: 50 }}
                    aspectRatioDesktop={featuredImage.aspectRatioDesktop ?? 259 / 212}
                  />
                )}
              </FeaturedAsset>
            </MediaAsContents>
          ) : null}

          <SliderRow
            tileGapDesktop={tileGapDesktop}
            tileGapMobile={tileGapMobile}
            totalSlides={tiles.length}
            hideSectionSideGaps={hideSectionSideGaps}
            hasAsset={hasAsset}
            hasHorizontalMargin={hasHorizontalMargin}
          >
            {hoverSupported && scrollsLeft ? (
              <SliderButtonPrevious
                aria-label='Scroll left'
                onClick={scrollLeft}
                $lifted={liftedButtons}
              >
                <Carat height='18' rotateClockwise={RotateClockwise.Half} width='16' />
              </SliderButtonPrevious>
            ) : null}
            {tiles.map((tile, index) => (
              <SliderTile
                tileGapDesktop={tileGapDesktop}
                tileGapMobile={tileGapMobile}
                visibleTilesDesktop={visibleTilesDesktop}
                visibleTilesMobile={visibleTilesMobile}
                minTileWidth={minTileWidth}
                maxTileWidth={maxTileWidth}
                hideSectionSideGaps={hideSectionSideGaps}
                key={keyFromTile(tile, index)}
              >
                {renderTile(tile, { layoutVariant: 'slider' }, index)}
              </SliderTile>
            ))}
            {hoverSupported && scrollsRight ? (
              <SliderButtonNext
                aria-label='Scroll right'
                onClick={scrollRight}
                $lifted={liftedButtons}
              >
                <Carat height='18' width='16' />
              </SliderButtonNext>
            ) : null}
          </SliderRow>
        </Slider>
      </SliderWrapper>
    </>
  )
}

export function TileLayout<T>(props: TileLayoutProps<T>): ReactElement | null {
  return (
    <>
      <MediaAsContents lessThan='md'>
        {props.layoutVariantMobile === 'grid' ? (
          <GridLayoutComponent {...props} />
        ) : (
          <SliderLayoutComponent {...props} />
        )}
      </MediaAsContents>
      <MediaAsContents greaterThanOrEqual='md'>
        {props.layoutVariantDesktop === 'grid' ? (
          <GridLayoutComponent {...props} />
        ) : (
          <SliderLayoutComponent {...props} />
        )}
      </MediaAsContents>
    </>
  )
}
