import * as React from 'react'

import { BaseIcon, IProps } from '../../BaseIcon'

interface Props extends IProps {
  viewBox?: string
}

export const Underscrubs: React.FC<Props> = ({
  height = '40px',
  width = '40px',
  viewBox = '0 0 40 40',
  ...rest
}) => {
  return (
    <BaseIcon height={height} width={width} viewBox={viewBox} {...rest}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20 40.4873C31.0457 40.4873 40 31.533 40 20.4873C40 9.44161 31.0457 0.487305 20 0.487305C8.9543 0.487305 0 9.44161 0 20.4873C0 31.533 8.9543 40.4873 20 40.4873ZM14.8571 18.8125V28.4873H25.1429V18.8125C25.1429 18.593 25.2656 18.3992 25.4454 18.3346C25.6716 18.2534 25.9114 18.4015 25.981 18.6654L27.7143 26.4873H32L28.3198 13.4888C27.9569 12.2894 26.9852 11.4873 25.895 11.4873H22.7044L21.8656 13.6584C21.2066 15.3641 18.7934 15.3641 18.1344 13.6584L17.2956 11.4873H14.105C13.0148 11.4873 12.0431 12.2894 11.6802 13.4888L8 26.4873H12.2857L14.019 18.6654C14.0886 18.4015 14.3284 18.2534 14.5546 18.3346C14.7344 18.3992 14.8571 18.593 14.8571 18.8125Z'
      />
    </BaseIcon>
  )
}
