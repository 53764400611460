import { ReadonlyRequestCookies } from 'next/dist/server/web/spec-extension/adapters/request-cookies'
import qs from 'query-string'
import { Cookies as ReactCookies } from 'react-cookie'

import { cookieKeys } from './Cookies.config'

export type PreviewDirectives = {
  includeUnpublishedCmsContent: boolean
  includeUnavailableProducts: boolean
  includeUnpublishedProductsForLaunchTags: string | undefined
}

export const convertRequestToPreviewDirectives = (
  request:
    | {
        cookies: ReadonlyRequestCookies
        url?: never
      }
    | {
        cookies: ReactCookies
        url: string
      }
): PreviewDirectives => {
  if (request.url) {
    // Pages directory support
    const searchParams = qs.parseUrl(request.url).query

    const contentSearchParam = Array.isArray(searchParams['content'])
      ? searchParams['content'][0]
      : searchParams['content']
    const contentCookie = request.cookies.get<string | undefined>(cookieKeys.contentMode.key)
    const contentMode = contentSearchParam ?? contentCookie

    const productDataSearchParam = Array.isArray(searchParams['product-data'])
      ? searchParams['product-data'][0]
      : searchParams['product-data']
    const productDataCookie = request.cookies.get<string | undefined>(cookieKeys.productData.key)
    const productData = productDataSearchParam ?? productDataCookie

    const includeUnpublishedCmsContent = contentMode === 'preview'
    const includeUnavailableProducts = productData === 'include-unavailable'
    const includeUnpublishedProductsForLaunchTags =
      productData !== 'include-unavailable' ? productData : undefined

    return {
      includeUnpublishedCmsContent,
      includeUnavailableProducts,
      includeUnpublishedProductsForLaunchTags,
    }
  } else {
    // App directory support
    const contentMode = request.cookies.get(cookieKeys.contentMode.key)
    const productData = request.cookies.get(cookieKeys.productData.key)

    const includeUnpublishedCmsContent = contentMode?.value === 'preview'
    const includeUnavailableProducts = productData?.value === 'include-unavailable'
    const includeUnpublishedProductsForLaunchTags =
      productData?.value && productData?.value !== 'include-unavailable'
        ? productData.value
        : undefined

    const attributes: PreviewDirectives = {
      includeUnpublishedCmsContent,
      includeUnavailableProducts,
      includeUnpublishedProductsForLaunchTags,
    }

    return attributes
  }
}
