import { useCallback, useState } from 'react'
import { createContainer } from 'unstated-next'

import { SignalClient } from '../../lib/signals/SignalClient'
import { getUserDataFromLS } from '../../lib/utils'

const figsCartHashId = '@figs:SignalCartHash'

export enum PageCategory {
  Home = 'home',
  Landing = 'landing',
  Collection = 'collection',
  Product = 'product',
  Search = 'search',
  Not_Found = 'not found',
  Other = 'other',
}

export interface SignalVariant {
  category: string
  color: string
  discountPrice: number | null
  externalParentId: string
  genderCategory: string
  fit?: string
  handle: string
  id: string
  name: string
  price: number
  size: string | null
  sku: string
  soldOut: boolean
  style: string
}

export interface VariantImpressionInformation {
  size: string
  soldOut: boolean
  discounted: boolean
}

export interface ProductGroupImpressionSignal {
  [key: string]: string | undefined | null | boolean

  handle: string
}

export interface ProductImpressionSignal {
  [key: string]: string | undefined | null | boolean | VariantImpressionInformation[]

  featuredProduct: boolean
  handle: string
  id: string
  shopifyId: string
  soldOut: boolean
  variantInformation: VariantImpressionInformation[]
}

export interface ProductInteractionSignal {
  [key: string]: string | undefined | null | boolean

  handle: string
  id: string
  shopifyId: string
}

export interface PageViewSignal {
  handle: string | undefined
  category: PageCategory
}

export interface CartItem {
  quantity: number
  variantShopifyId: string
  variantSku: string
}

export interface CartModifiedSignal {
  [key: string]: string | CartItem[]

  id: string
  items: CartItem[]
}

function useSignalTrackingImpl() {
  const [signalClient, _setSignalClient] = useState(() => {
    return typeof window !== 'undefined' ? new SignalClient() : undefined
  })

  const trackPageView = useCallback(
    ({ handle, category }: PageViewSignal) => {
      const eventAttributes = {
        pageHandle: handle,
        pageCategory: category,
      }

      if (handle) {
        const lsUser = getUserDataFromLS()
        signalClient?.track({
          event: 'page_view',
          attributes: eventAttributes,
          user: lsUser?.shopifyId
            ? {
                shopifyCustomerId: lsUser.shopifyId,
                emailAddress: lsUser.email,
              }
            : undefined,
        })
      }
    },
    [signalClient]
  )

  const trackProductGroupImpression = useCallback(
    (productGroupSignal: ProductGroupImpressionSignal) => {
      const lsUser = getUserDataFromLS()
      signalClient?.track({
        event: 'product_group_impression',
        attributes: productGroupSignal,
        user: lsUser?.shopifyId
          ? {
              shopifyCustomerId: lsUser.shopifyId,
              emailAddress: lsUser.email,
            }
          : undefined,
      })
    },
    [signalClient]
  )

  const trackProductImpression = useCallback(
    (productSignal: ProductImpressionSignal) => {
      const lsUser = getUserDataFromLS()
      signalClient?.track({
        event: 'product_impression',
        attributes: productSignal,
        user: lsUser?.shopifyId
          ? {
              shopifyCustomerId: lsUser.shopifyId,
              emailAddress: lsUser.email,
            }
          : undefined,
      })
    },
    [signalClient]
  )

  const trackProductInteraction = useCallback(
    (productSignal: ProductInteractionSignal) => {
      const lsUser = getUserDataFromLS()
      signalClient?.track({
        event: 'product_interaction',
        attributes: productSignal,
        user: lsUser?.shopifyId
          ? {
              shopifyCustomerId: lsUser.shopifyId,
              emailAddress: lsUser.email,
            }
          : undefined,
      })
    },
    [signalClient]
  )

  const trackCartModified = useCallback(
    (cartSignal: CartModifiedSignal) => {
      const cartHash =
        cartSignal.id +
        '||' +
        cartSignal.items.map(item => item.variantSku + '|' + item.quantity).join('||')

      if (cartHash !== localStorage?.getItem(figsCartHashId)) {
        localStorage?.setItem(figsCartHashId, cartHash)
        const lsUser = getUserDataFromLS()
        signalClient?.track({
          event: 'cart_modified',
          attributes: cartSignal,
          user: lsUser?.shopifyId
            ? {
                shopifyCustomerId: lsUser.shopifyId,
                emailAddress: lsUser.email,
              }
            : undefined,
        })
      }
    },
    [signalClient]
  )

  return {
    trackCartModified,
    trackPageView,
    trackProductImpression,
    trackProductInteraction,
    trackProductGroupImpression,
  }
}

const SignalTrackingContainer = createContainer(useSignalTrackingImpl)
export const SignalTrackingProvider = SignalTrackingContainer.Provider
export const useSignalTracking = SignalTrackingContainer.useContainer
