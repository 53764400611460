import * as React from 'react'

import { BaseIcon, IProps } from '../../BaseIcon'

interface Props extends IProps {
  viewBox?: string
}

export const ScrubPants: React.FC<Props> = ({
  height = '40px',
  width = '40px',
  viewBox = '0 0 40 40',
  ...rest
}) => {
  return (
    <BaseIcon height={height} width={width} viewBox={viewBox} {...rest}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20 40.4873C31.0457 40.4873 40 31.533 40 20.4873C40 9.44161 31.0457 0.487305 20 0.487305C8.9543 0.487305 0 9.44161 0 20.4873C0 31.533 8.9543 40.4873 20 40.4873ZM20 16.4873C20.5634 16.4873 21.0311 16.9227 21.0712 17.4848L22 30.4873H28L27 10.4873H13L12 30.4873H18L18.9288 17.4848C18.9689 16.9227 19.4366 16.4873 20 16.4873Z'
      />
    </BaseIcon>
  )
}
