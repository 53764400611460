import styled from 'styled-components'

import { LocaleButtonIcon, LocaleButtonText } from '@syconium/little-miss-figgy'
import { TypeStyle } from '@syconium/little-miss-figgy'
import { useLocalization } from '@syconium/magnolia/src/app/_providers/LocalizationProvider.client'
import { useFixturesContext } from '@syconium/magnolia/src/brunswick/containers/fixtures'
import { ContentfulRegion } from '@syconium/magnolia/src/types/graphql'

const TextSpan = styled(LocaleButtonText)`
  ${TypeStyle.css.headlineExtraExtraSmall};
  padding-left: ${o => o.theme.spacing(2)};
  text-transform: uppercase;
`

export const RegionLocaleTab = () => {
  const { regionData } = useFixturesContext()
  const { region, locale, languageGroup } = useLocalization()
  const flagSrc =
    regionData?.regions.find((r: ContentfulRegion) => r.id === region.id)?.flagSrc ?? region.flagSrc
  return (
    <>
      <div>
        <LocaleButtonIcon src={flagSrc} alt={locale} />
      </div>
      <TextSpan>{languageGroup}</TextSpan>
    </>
  )
}
