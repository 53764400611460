import { useEffect } from 'react'

import { useNostoClient } from '../../../../../app/_components/chrome/scripts/NostoScript'

const nostoPlacementIds = ['notfound-nosto-1', 'notfound-nosto-2'] as const

export function useTrackNostoNotFoundPageView() {
  const nostoClient = useNostoClient()

  useEffect(() => {
    nostoClient.trackNotFoundPageView(nostoPlacementIds)
  }, [nostoClient])
}
