'use client'

import { useCallback, useEffect, useMemo } from 'react'
import { createContainer } from 'unstated-next'

import { cookieKeys } from '../_config/Cookies.config'
import {
  SessionAttributes,
  convertCookiesToSessionAttributes,
  createNewSessionId,
  sessionStorageKeys,
} from '../_config/Session.config'

import { useCookies } from './CookiesProvider.client'
import { useLocalization } from './LocalizationProvider.client'

const useSessionImpl = () => {
  const { locale } = useLocalization()
  const [cookies, setCookie, _removeCookie] = useCookies([
    cookieKeys.initialSessionIndicator.key,
    cookieKeys.stylePreference.key,
    cookieKeys.priorPageViewIndicator.key,
    cookieKeys.sessionId.key,
    cookieKeys.visitorId.key,
  ])

  const initialSessionCookie = cookies[cookieKeys.initialSessionIndicator.key]
  const stylePreferenceCookie = cookies[cookieKeys.stylePreference.key]
  const priorPageViewCookie = cookies[cookieKeys.priorPageViewIndicator.key]
  const sessionIdCookie = cookies[cookieKeys.sessionId.key]
  const visitorIdCookie = cookies[cookieKeys.visitorId.key]

  const sessionAttributes = useMemo(() => {
    return convertCookiesToSessionAttributes({
      initialSessionCookie,
      stylePreferenceCookie,
      priorPageViewCookie,
      sessionIdCookie,
      visitorIdCookie,
    })
  }, [
    initialSessionCookie,
    priorPageViewCookie,
    sessionIdCookie,
    stylePreferenceCookie,
    visitorIdCookie,
  ])

  const setStylePreference = useCallback(
    (newStylePreference: SessionAttributes['stylePreference']) => {
      if (newStylePreference) {
        setCookie(
          cookieKeys.stylePreference.key,
          newStylePreference,
          cookieKeys.stylePreference.options
        )
      }
    },
    [setCookie]
  )

  // Middleware already sets these IDs, but we do it here also for the pages directory since they are not in its cache key.
  useEffect(() => {
    if (sessionIdCookie === undefined) {
      setCookie(cookieKeys.sessionId.key, createNewSessionId(), cookieKeys.sessionId.options)
    }

    if (visitorIdCookie === undefined) {
      setCookie(cookieKeys.visitorId.key, createNewSessionId(), cookieKeys.visitorId.options)
    }
  }, [sessionIdCookie, setCookie, visitorIdCookie])

  // This session stuff can probably shift to middleware so that it is simpler.
  // But it works for now as is to do the setting in the browser.
  const priorPageViewIndicator = cookies[cookieKeys.priorPageViewIndicator.key]
  useEffect(() => {
    if (!priorPageViewIndicator) {
      setCookie(
        cookieKeys.priorPageViewIndicator.key,
        'true',
        cookieKeys.priorPageViewIndicator.options
      )
      setCookie(
        cookieKeys.initialSessionIndicator.key,
        'true',
        cookieKeys.priorPageViewIndicator.options
      )
    }
  }, [priorPageViewIndicator, setCookie])

  // We store an array of locales that were used in session storage and then update the Heap session to include them.
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const usedLocales: string[] = []
      const previouslyUsedLocalesString = globalThis.sessionStorage?.getItem(
        sessionStorageKeys.usedLocales
      )

      if (previouslyUsedLocalesString) {
        const previouslyUsedLocales = JSON.parse(previouslyUsedLocalesString)
        for (let locale of previouslyUsedLocales) {
          usedLocales.push(locale)
        }
      }
      if (typeof locale === 'string') {
        if (!usedLocales.includes(locale)) {
          usedLocales.push(locale)
        }
      }
      const usedLocalesString = JSON.stringify(usedLocales)

      globalThis.sessionStorage?.setItem(sessionStorageKeys.usedLocales, usedLocalesString)

      // TODO: Leverage a typed Heap provider/container/hook rather than directly hitting a window object.
      if (typeof window.heap?.addEventProperties === 'function') {
        window.heap.addEventProperties({ '@figs:used-locales': usedLocalesString })
      }
    }
  }, [locale])

  // On page load we store any discount query param in session storage.
  // Then we pass that on to the Shopify checkout later.
  useEffect(() => {
    if (typeof window === 'undefined') return

    const searchParams = new URLSearchParams(window.location.search)
    const discountQueryParam = searchParams.get('discount')
    if (discountQueryParam) {
      if (discountQueryParam.trim() === '') {
        globalThis.sessionStorage?.removeItem(sessionStorageKeys.discount)
      } else {
        globalThis.sessionStorage?.setItem(sessionStorageKeys.discount, discountQueryParam)
      }
    }
  }, [])

  return {
    attributes: sessionAttributes,
    setStylePreference,
  }
}

const SessionContainer = createContainer(useSessionImpl)
export const SessionProvider = SessionContainer.Provider
export const useSession = SessionContainer.useContainer
