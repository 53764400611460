'use client'

import Script from 'next/script'
import { FC } from 'react'

import { useConsentPolicy } from '../../../_providers/ConsentPolicyProvider.client'

type DataDogClientEvent = {
  label: string
  context: Record<string, string>
}

// TODO: Purge this queue when leaving the page or tab goes to the background
const queuedClientEvents: DataDogClientEvent[] = []

export const reportClientEvent = (event: DataDogClientEvent) => {
  if (typeof window === undefined) return

  if (
    typeof window.DD_RUM?.onReady === 'function' &&
    typeof window.DD_RUM?.addAction === 'function'
  ) {
    if (queuedClientEvents.length > 0) {
      queuedClientEvents.forEach(event => {
        window.DD_RUM.onReady(function () {
          window.DD_RUM.addAction(event.label, event.context)
        })
      })
      queuedClientEvents.length = 0
    }

    window.DD_RUM.onReady(function () {
      window.DD_RUM.addAction(event.label, event.context)
    })
  } else {
    queuedClientEvents.push(event)
  }
}

type DataDogClientError = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any
  context: Record<string, string>
}

const queuedClientErrors: DataDogClientError[] = []

export const reportClientError = (error: DataDogClientError) => {
  if (typeof window === undefined) return

  if (
    typeof window.DD_RUM?.onReady === 'function' &&
    typeof window.DD_RUM?.addError === 'function'
  ) {
    if (queuedClientErrors.length > 0) {
      queuedClientErrors.forEach(error => {
        window.DD_RUM.onReady(function () {
          window.DD_RUM.addError(error.error, error.context)
        })
      })
      queuedClientErrors.length = 0
    }

    window.DD_RUM.onReady(function () {
      window.DD_RUM.addError(error.error, error.context)
    })
  } else {
    queuedClientErrors.push(error)
  }
}

export const DataDogRumScript: FC = () => {
  const { performance } = useConsentPolicy()
  const appId = process.env.NEXT_PUBLIC_DD_RUM_APP_ID
  const clientToken = process.env.NEXT_PUBLIC_DD_RUM_CLIENT_TOKEN

  if (!appId || !clientToken || performance !== 'allowed') return null

  const ddEnv =
    process.env.NEXT_PUBLIC_FIGS_ENV === 'prod' || process.env.NEXT_PUBLIC_FIGS_ENV === 'staging'
      ? process.env.NEXT_PUBLIC_FIGS_ENV + '-east'
      : process.env.NEXT_PUBLIC_FIGS_ENV ?? 'missing'

  return (
    <Script
      id='datadog-rum-script'
      strategy='afterInteractive' // Analytics is set to afterInteractive
      dangerouslySetInnerHTML={{
        __html: `
          (function(h,o,u,n,d) {
            h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
            d=o.createElement(u);d.async=1;d.src=n
            n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
          })(window,document,'script','https://www.datadoghq-browser-agent.com/us1/v5/datadog-rum.js','DD_RUM')
          window.DD_RUM.onReady(function() {
            window.DD_RUM.init({
              applicationId: '${appId}',
              clientToken: '${clientToken}',
              env: '${ddEnv}',
              version: '${process.env.NEXT_PUBLIC_MAGNOLIA_VERSION ?? '0.0.0'}',
              service: 'magnolia',
              sessionSampleRate: ${parseInt(
                process.env.NEXT_PUBLIC_DD_RUM_SAMPLE_RATE || '0'
              )}, // How often to track RUM performance data (1 = 1%)
              traceSampleRate: 1, // How often to attach activity to traces (1 = 1%)
              allowedTracingUrls: [/https:\\/\\/(www\\.|preview\\.|staging\\.|sandbox\\.|)wearfigs\\.com/, /https:\\/\\/.*\\.figs\\.live/],
              sessionReplaySampleRate: 0, // How often to record user sessions (1 = 1%)
              telemetrySampleRate: 0, // How often to send DataDog's code debugging data to DataDog (1 = 1%)
              trackLongTasks: true,
              trackResources: true,
              silentMultipleInit: true, // Tells this function to check if it was already init, and fail silently if it was.
              site: 'datadoghq.com', // What datadog source serves our company's setup
            });
          })
        `,
      }}
    />
  )
}
