'use client'

import Script from 'next/script'
import { useCallback, useEffect, useRef } from 'react'

import { sessionStorageKeys } from '../../../../app/_config/Session.config'
import { useConsentPolicy } from '../../../../app/_providers/ConsentPolicyProvider.client'
import { AuthenticationContainer } from '../../../../containers/AuthenticationContainer'
import { useGoogleTagManager } from '../../../../lib/hooks/useGoogleTagManager/useGoogleTagManager'

export const Gtm = () => {
  const containerId = process.env.NEXT_PUBLIC_GTM_CONTAINER_ID
  const { performance, targeting, social, functional } = useConsentPolicy()
  const currentUserEmailRef = useRef<string | undefined>('INITIAL')
  const { currentUser: user } = AuthenticationContainer.useContainer()
  const { getDataLayer, pushCustomerData, pushClearCustomerData, pushGtmData } =
    useGoogleTagManager()

  const updateUser = useCallback(() => {
    if (currentUserEmailRef.current === user?.email) return
    currentUserEmailRef.current = user?.email

    if (user) {
      pushCustomerData({
        shopifyId: user.shopifyId ?? undefined,
        email: user.email,
        ordersCount: user.ordersCount ?? undefined,
        firstName: user.firstName ?? undefined,
        lastName: user.lastName ?? undefined,
        phone: user.phone ?? undefined,
      })
    } else {
      pushClearCustomerData()
    }
  }, [pushClearCustomerData, pushCustomerData, user])

  const updateLandingPage = useCallback(() => {
    const alreadyPushed = getDataLayer().some(dataLayerEntry => {
      return Boolean(dataLayerEntry?.originalLocation)
    })

    if (alreadyPushed) return

    const currentLocation =
      window.document.location.protocol +
      '//' +
      window.document.location.hostname +
      window.document.location.pathname +
      window.document.location.search
    const originalLocation =
      window.sessionStorage.getItem(sessionStorageKeys.landingPage) ?? currentLocation
    window.sessionStorage.setItem(sessionStorageKeys.landingPage, originalLocation)

    pushGtmData({
      originalLocation,
    })
  }, [getDataLayer, pushGtmData])

  useEffect(() => {
    updateUser()
    updateLandingPage()
  }, [updateLandingPage, updateUser])

  if (
    !containerId ||
    performance !== 'allowed' ||
    social !== 'allowed' ||
    targeting !== 'allowed' ||
    functional !== 'allowed'
  ) {
    return null
  }

  return (
    <Script
      id='gtm-script'
      strategy='lazyOnload' // Marketing is set to lazy on load
      dangerouslySetInnerHTML={{
        __html: `
            (function(w,d,s,l,i){
              w[l]=w[l]||[];w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
              var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
              j.async=true;j.src='https://www.googletagmanager.com/gtm.js?gtm_cookies_win=x&id='+i+dl;
              f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${containerId}');
          `,
      }}
    />
  )
}
