import * as React from 'react'

import { BaseIcon, IProps } from '../BaseIcon'

export const Download: React.FC<IProps> = ({
  height = '16px',
  width = '16px',
  fill = '#282828',
  ...rest
}) => {
  return (
    <BaseIcon height={height} width={width} viewBox='0 0 16 16' fill='none' {...rest}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.5 2.66675L7.5 8.84037L4.6854 6.02578L3.97829 6.73289L8 10.7546L12.0217 6.73289L11.3146 6.02578L8.5 8.84037L8.5 2.66675H7.5Z'
        fill={fill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14 13.1667H2V12.1667H14V13.1667Z'
        fill={fill}
      />
    </BaseIcon>
  )
}
