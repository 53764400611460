import * as React from 'react'
import { useTheme } from 'styled-components'

import { BaseIcon, IProps } from '../BaseIcon'

type Props = IProps & {
  viewBox?: string
}

export const Minus: React.FC<Props> = ({
  height = '12px',
  stroke,
  width = '12px',
  viewBox = '0 0 12 2',
  ...rest
}) => {
  const { color } = useTheme()

  return (
    <BaseIcon height={height} width={width} viewBox={viewBox} {...rest}>
      <path
        d='M10.335 1h-8.92'
        stroke={stroke || color.deprecated.black.primary}
        strokeWidth='1.5'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='square'
      />
    </BaseIcon>
  )
}
