import { gql } from '@syconium/magnolia/src/__generated__/graphql/catalog'

export const ORDER_FRAGMENT = gql(`
  fragment Order on Order {
    createdAt
    displayFinancialStatus
    displayFulfillmentStatus
    status
    id
    name
    total
    fulfillments {
      deliveredAt
      displayStatus
      trackingInfo {
        company
        number
        url
      }
    }
  } 
`)

export const MY_LIMITED_ORDER = gql(`
  query myLimitedOrder($first: Int, $reverse: Boolean = false, $after: String) {
    my {
      id
      email
      defaultAddress {
        address1
        address2
        city
        country
        firstName
        lastName
        phone
        province
        shopifyId
        zip
      }
      profile {
        id
        avatarUri
        firstName
        lastName
        occupation
        stipend
      }
      portal {
        collectionHandle
        accountHeroSectionCG {
          ...CollectionViewHeroSectionWithCtaFragment
        }
      }
      orders: ordersStorefront(first: $first, reverse: $reverse, after: $after) {
        nodes {
          name
          createdAt
          total
          fulfillments {
            deliveredAt
            displayStatus
            trackingInfo {
              url
            }
          }
          lineItems {
            nodes {
              title
              image {
                altText
                url
              }
            }
          }
        }
      }
    }
  }
`)

gql(`
  fragment MyProfileFragment on Customer {
    id
    email
    groups
    shopifyId
    profile {
      avatarUri
      firstName
      id
      lastName
      occupation
      ordersCount
      phone
      stylePreference
      birthday
    }
    portal {
      collectionHandle
    }
  }
`)

export const MY_PROFILE = gql(`
  query myProfile {
    my {
      ...MyProfileFragment
    }
  }
`)

export const MY_PROFILE_EDIT = gql(`
  query myProfileEdit {
    getUploadAvatarUrl
    my {
      ...MyProfileFragment
    }
  }
`)

export const MY_PORTAL_PROFILE = gql(`
  query myPortalProfile {
    my {
      id
      profile {
        stipend
      }
      portal {
        collectionHandle
        collectionHeroSectionCG {
          backgroundColor
          ctaText
          id
          imageDesktop
          imageMobile
          imagePlacement
          inlineVideoDesktop
          inlineVideoMobile
          inlineVimeoIdDesktop
          inlineVimeoIdMobile
          subtitle
          textColor
          title
          videoId
          videoPlatform
        }
        promoBarPages {
          id
          content
        }
        embroidery {
          image
          addText
          yesText
          noText
          logoName
          eligibleProductCategories
          mandatoryTextEmbroideryCategories
          mandatoryLogoEmbroideryCategories
          embroideryDiscountPercentage
          defaultTextEmbroideryValues {
            textColor
            font
            textPlacement
          }
          hiddenTextEmbroideryFields
        }
        name
        discountPercentage
        pdpCtaText
        quickBuyText
      }
    }
  }
`)
export const ADDRESS_FRAGMENT = gql(`
  fragment Address on Address {
    address1
    address2
    city
    company
    country
    firstName
    lastName
    phone
    province
    shopifyId
    zip
  }`)

export const MY_ADDRESSES = gql(`
  query myAddresses {
    my {
      id
      email
      groups
      shopifyId
      addresses {
        nodes {
          ...Address
        }
      }
      defaultAddress {
        ...Address
      }
    }
  }
`)

export const MY_ORDERS_DETAILS = gql(`
  query myOrderDetails($first: Int, $reverse: Boolean = false, $after: String) {
    my {
      id
      profile {
        ordersCount
      }
      orders(first: $first, reverse: $reverse, after: $after) {
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        nodes {
          ...Order
        }
      }
    }
  }
`)

export const MY_ORDERS_PAGE_INFO = gql(`
  query myOrdersPageInfo($first: Int, $reverse: Boolean = false, $after: String) {
    my {
      id
      orders(first: $first, reverse: $reverse, after: $after) {
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  }
`)

export const MY_EMBROIDERY_LOGOS = gql(`
  query myEmbroideryLogos {
    my {
      id
      embroideryLogos {
        id
        status
        name
        notes
        preview
        digitizationSetting
      }
    }
  }
`)

export const MY_SIZE_PREFERENCES = gql(`
  query mySizePreferences {
    my {
      id
      profile {
        sizePreferences {
          men {
            settings {
              id
              category
              size
              fit
            }
          }
          women {
            settings {
              id
              category
              size
              fit
            }
          }
        } 
      }
    }
  }
`)

export const MY_SIZE_PROFILE = gql(`
  query mySizeProfile {
    my {
      id
      sizeProfiles {
        men {
          gender
          age
          weight
          height
          id
        }
        women {
          gender
          age
          weight
          height
          id
        }
      }
    }
  }
`)
