import * as React from 'react'

import { BaseIcon, IProps } from '../BaseIcon'

export const DoubleCarat: React.FC<IProps> = ({
  className = '',
  height = '13px',
  rotateClockwise,
  fill = 'currentColor',
  stroke = 'none',
  width = '12px',
  ...rest
}) => {
  return (
    <BaseIcon
      className={className}
      height={height}
      width={width}
      rotateClockwise={rotateClockwise}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 12 13'
      {...rest}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.87861 6.03033L0.908936 1.06066L1.9696 0L7.99993 6.03033L1.9696 12.0607L0.908936 11L5.87861 6.03033Z'
        stroke={stroke}
        fill={fill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.87861 6.03033L4.90894 1.06066L5.9696 0L11.9999 6.03033L5.9696 12.0607L4.90894 11L9.87861 6.03033Z'
        stroke={stroke}
        fill={fill}
      />
    </BaseIcon>
  )
}
