import styled, { CSSProperties, css } from 'styled-components'

import { fromMd, fromSm, untilSm } from '@syconium/little-miss-figgy'
import { fadableSegment } from '@syconium/magnolia/src/brunswick/components/Header/styles'

import { headerHeight } from '../../../constants'
import { MaybeSpaLink } from '../../../lib/adapters/next-routing-service/MaybeSpaLink'
import { Body as NavDropdown } from '../NavDropdown/styles'
import { SearchContainer } from '../NavHeader/SearchBar.styles'
import { Body as NavTray } from '../NavTray/styles'

const bottomGutter = '30vh'

export const TabContentWrapper = styled.div<{ isActive: boolean }>`
  display: ${o => (o.isActive ? 'contents' : 'none')};
  position: relative;
`
// Body is NOT a position parent, so trays can take the width of its parent.
export const Body = styled.ul<{
  wrapItems: boolean
  promoBarHeightDesktop: CSSProperties['height']
  promoBarHeightMobile: CSSProperties['height']
}>`
  display: flex;
  height: ${headerHeight};
  padding: 0 6px 0 10px;

  ${o =>
    o.wrapItems &&
    css`
      ${fromMd} {
        flex-wrap: wrap;
        justify-content: center;
        overflow: hidden;
      }
    `}

  ${NavTray},
  ${NavDropdown} {
    max-height: calc(100vh - ${headerHeight} - ${o => o.promoBarHeightMobile} - ${bottomGutter});
    overscroll-behavior: contain;
    position: absolute;
    top: 100%;

    ${fromMd} {
      max-height: calc(100vh - ${headerHeight} - ${o => o.promoBarHeightDesktop});
    }
  }

  ${NavTray} {
    left: 0;
    width: 100%;
  }

  ${NavDropdown} {
    margin-inline-start: -14px; /* align dropdown content with tab text */
  }

  ${fromMd} {
    padding: 0 10px;
    &:not(:hover) {
      ${TabContentWrapper} {
        display: none;
      }
    }
  }
`

// Tab is not a position parent, so trays can take the width of Body parent.
export const Tab = styled.li`
  align-items: center;
  display: flex;
  height: 100%;
`

export interface TabItemProps {
  hideUnderline?: boolean
  isActive: boolean
  isFadedOut?: boolean
  textColor?: string | null
  children?: React.ReactNode
  className?: string
}

const tabItemStyles = css<TabItemProps>`
  ${fadableSegment}

  align-items: center;
  align-self: stretch;
  color: ${o => o.textColor};
  display: flex;
  font-size: 13px;
  height: 100%;
  letter-spacing: 0.65px;
  line-height: 18px;
  position: relative;
  user-select: none;

  ${fromSm} {
    > :first-child {
      padding-inline-start: 10px;
    }

    > :last-child {
      padding-inline-end: 10px;
    }
  }

  > button {
    height: 100%;
  }

  > b {
    font-weight: 700;
  }

  /* hover styles for the unfurled search bar */
  &:hover ${SearchContainer} {
    border: ${o => `1px solid ${o.theme.color.deprecated.obsidian}`};
    color: ${o => o.theme.color.deprecated.obsidian};
  }

  &::after {
    background: ${o => o.isActive && !o.hideUnderline && 'currentColor'};
    bottom: 0;
    content: '';
    display: block;
    height: 5px;
    left: 0;
    position: absolute;
    width: 100%;
  }

  ${fromMd} {
    &:hover svg g {
      stroke: ${o => o.theme.color.deprecated.obsidian}; /* temp override [nav-v2-cleanup] */
    }
  }
`

export const TabLink = styled(MaybeSpaLink)<TabItemProps>`
  ${tabItemStyles}
`

export const TabDiv = styled.div<TabItemProps>`
  ${tabItemStyles}

  cursor: pointer;
`

export const TabButton = styled.button<TabItemProps>`
  ${tabItemStyles}

  cursor: pointer;
  width: max-content;

  ${untilSm} {
    padding: 0 8px;
  }
`

export const TabSubMenuButton = styled.button<TabItemProps>`
  ${tabItemStyles}

  cursor: pointer;
  padding: 0 8px;
`
