import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'

import { Body } from './styles'

export const Interstitial: FC<{ initEnabled?: boolean }> = ({ initEnabled }) => {
  const router = useRouter()
  const [enabled, setEnabled] = useState(initEnabled)

  useEffect(() => {
    const onRouteChangeStart = () => setEnabled(true)
    const onRouteChangeComplete = () => setEnabled(false)
    const onRouteChangeError = () => setEnabled(false)

    router.events.on('routeChangeStart', onRouteChangeStart)
    router.events.on('routeChangeComplete', onRouteChangeComplete)
    router.events.on('routeChangeError', onRouteChangeError)

    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart)
      router.events.off('routeChangeComplete', onRouteChangeComplete)
      router.events.off('routeChangeError', onRouteChangeError)
    }
  }, [router, setEnabled])

  return enabled ? <Body /> : null
}
