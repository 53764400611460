import { FC, useCallback, useState } from 'react'
import { ThemeProvider, useTheme } from 'styled-components'

import {
  MediaAsContents,
  OpenVideoModalTextButton,
  useDarkTheme,
} from '@syconium/little-miss-figgy'

import { PageEditorialCarouselFragmentFragment } from '../../../__generated__/graphql/catalog/graphql'
import { TileLayout } from '../../../brunswick/components/TileLayout'
import { VideoModal } from '../../../brunswick/components/VideoModal'
import { useFixturesContext } from '../../../brunswick/containers/fixtures'
import { trackEvent } from '../../../lib/analytics'
import { PageTileSectionTile } from '../../../types/graphql/contentful-components/page-tile-section'
import { ContentfulStyledText } from '../../contentful/ContentfulStyledText/ContentfulStyledText'
import { usePageSectionContext } from '../PageSectionContext'

import {
  ActionsWrapper,
  BackgroundColor,
  BackgroundImage,
  ButtonsWrapper,
  CarouselWrapper,
  Content,
  ContentWrapper,
  CtaButton,
  EditorialWrapper,
  HeadlineWrapper,
  OverlayColor,
  Wrapper,
} from './styles'
import { Tile } from './Tile'

export const PageEditorialCarouselSection: FC<PageEditorialCarouselFragmentFragment> = ({
  backgroundImage,
  backgroundColor,
  bodies,
  cta1,
  cta2,
  displayVideoLink,
  headline,
  tileCarousel,
  modalVimeoIdDesktop,
  modalVimeoIdMobile,
  hasVerticalMargin,
  overlayOpacity,
}) => {
  const hasDesktopModalVideo = Boolean(modalVimeoIdDesktop)
  const hasMobileModalVideo = Boolean(modalVimeoIdMobile)
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false)
  const [isMobileVideoModalOpen, setIsMobileVideoModalOpen] = useState(false)

  const {
    magnolia: {
      general: { watchTheFilm },
    },
  } = useFixturesContext()
  const { pageSectionIndex: pageSectionIndex } = usePageSectionContext()

  const changeVideoModalState = useCallback(
    (isMobile: boolean, isOpen: boolean) => {
      if (isMobile && hasMobileModalVideo) {
        setIsMobileVideoModalOpen(isOpen)
      } else if (hasDesktopModalVideo) {
        setIsVideoModalOpen(isOpen)
      }
    },
    [hasDesktopModalVideo, hasMobileModalVideo]
  )

  const openVideoModal = useCallback(
    (isMobile: boolean) => {
      changeVideoModalState(isMobile, true)
    },
    [changeVideoModalState]
  )

  const closeVideoModal = useCallback(
    (isMobile: boolean) => {
      changeVideoModalState(isMobile, false)
    },
    [changeVideoModalState]
  )

  // Contentful doesn't ask Merch if their color or image needs dark mode text colors... so lets just assume for now it does.
  const currentTheme = useTheme()
  const darkTheme = useDarkTheme()
  const theme = backgroundColor || backgroundImage ? darkTheme : currentTheme

  const actions = (
    <ActionsWrapper>
      <ButtonsWrapper>
        {(cta1?.title || cta1?.links[0]?.text) && cta1?.links[0]?.url ? (
          <CtaButton
            as='a'
            href={cta1?.links[0]?.url}
            $variant={'white-on-black-no-border'}
            {...trackEvent({
              label: cta1?.title ?? cta1?.links[0]?.text,
              category: 'page-editorial-carousel-section',
              action: 'click cta 1',
              pageSectionName: headline?.title ?? headline?.paragraph ?? undefined,
              pageSectionIndex,
              correspondingAsset: backgroundImage?.imageDesktop ?? undefined,
            })}
          >
            {cta1.title ? cta1?.title : cta1?.links[0]?.text}
          </CtaButton>
        ) : null}
        {(cta2?.title || cta2?.links[0]?.text) && cta2?.links[0]?.url ? (
          <CtaButton
            as='a'
            href={cta2?.links[0]?.url}
            $variant={'white-on-black-no-border'}
            {...trackEvent({
              label: cta2?.title ?? cta2?.links[0]?.text,
              category: 'page-editorial-carousel-section',
              action: 'click cta 2',
              pageSectionName: headline?.title ?? headline?.paragraph ?? undefined,
              pageSectionIndex,
              correspondingAsset: backgroundImage?.imageDesktop ?? undefined,
            })}
          >
            {cta2?.title ? cta2?.title : cta2?.links[0]?.text}
          </CtaButton>
        ) : null}
      </ButtonsWrapper>

      <>
        {displayVideoLink && modalVimeoIdMobile ? (
          <MediaAsContents lessThan={'md'}>
            <OpenVideoModalTextButton
              {...trackEvent({
                category: 'page-editorial-carousel-section',
                action: 'open video modal',
                pageSectionName: headline?.title ?? headline?.paragraph ?? undefined,
                pageSectionIndex,
                correspondingAsset: String(modalVimeoIdMobile),
              })}
              onClick={() => {
                openVideoModal(true)
              }}
              text={watchTheFilm ?? 'WATCH THE FILM'}
            />
          </MediaAsContents>
        ) : null}
        {displayVideoLink && modalVimeoIdDesktop ? (
          <MediaAsContents greaterThanOrEqual={'md'}>
            <OpenVideoModalTextButton
              {...trackEvent({
                category: 'page-editorial-carousel-section',
                action: 'open video modal',
                pageSectionName: headline?.title ?? headline?.paragraph ?? undefined,
                pageSectionIndex,
                correspondingAsset: String(modalVimeoIdMobile),
              })}
              onClick={() => {
                openVideoModal(false)
              }}
              text={watchTheFilm ?? 'WATCH THE FILM'}
            />
          </MediaAsContents>
        ) : null}
      </>
    </ActionsWrapper>
  )

  return (
    <ThemeProvider theme={theme}>
      <Wrapper isFullBleedDesktop={true} hasVerticalMargin={hasVerticalMargin}>
        <ContentWrapper>
          <OverlayColor overlayOpacity={overlayOpacity}>
            {backgroundColor ? <BackgroundColor backgroundColor={backgroundColor} /> : null}
            {backgroundImage?.imageDesktop ? (
              <BackgroundImage
                src={backgroundImage?.imageDesktop ? backgroundImage?.imageDesktop : ''}
                alt={backgroundImage?.altText}
                loading='lazy'
                aspectRatios={{
                  sm: backgroundImage.aspectRatioMobile ?? 65 / 176,
                  md: backgroundImage.aspectRatioDesktop ?? 360 / 289,
                }}
                widths={{ unit: 'vw', sm: 100, md: 100 }}
              />
            ) : null}
          </OverlayColor>
          <Content>
            <EditorialWrapper>
              <div>
                <HeadlineWrapper>
                  {headline ? <ContentfulStyledText {...headline} /> : null}
                </HeadlineWrapper>
                <MediaAsContents greaterThanOrEqual='md'>{actions}</MediaAsContents>
              </div>
              <div>
                {bodies.map((body, index) => {
                  return (
                    <ContentfulStyledText
                      {...body}
                      key={body.id}
                      defaultBottomMargin={index < bodies.length - 1 ? 20 : 0}
                    />
                  )
                })}
              </div>
              <MediaAsContents lessThan='md'>{actions}</MediaAsContents>
            </EditorialWrapper>
            <CarouselWrapper hasBackground={!!backgroundColor || !!backgroundImage}>
              {tileCarousel?.tiles ? (
                <TileLayout
                  keyFromTile={(tile: PageTileSectionTile, index: number) => `${index}${tile.id}`}
                  layoutVariantDesktop='slider'
                  layoutVariantMobile='slider'
                  renderTile={(tile: PageTileSectionTile, _options, index) => (
                    <Tile
                      description={tile.description}
                      headline={tile.jumboTitle}
                      loading='lazy'
                      widths={{ unit: 'vw', sm: 100, md: 100 / 3 }}
                      vimeoId={tile.inlineVimeoId}
                      image={tile.image ? tile.image : ''}
                      ctaHref={tile.ctaLinkHref}
                      ctaText={tile.ctaText}
                      analyticsContext={{
                        pageSectionName: headline?.title ?? headline?.paragraph ?? undefined,
                        tileIndex: index,
                      }}
                    />
                  )}
                  tiles={tileCarousel.tiles}
                  visibleTilesDesktop={3}
                  visibleTilesMobile={1}
                  hideSectionSideGaps={true}
                />
              ) : null}
            </CarouselWrapper>
            {hasDesktopModalVideo && (
              <VideoModal
                isOpen={isVideoModalOpen}
                onClose={() => closeVideoModal(false)}
                videoPlatform={'vimeo'}
                videoId={String(modalVimeoIdDesktop)}
              />
            )}
            {hasMobileModalVideo && (
              <VideoModal
                isOpen={isMobileVideoModalOpen}
                onClose={() => closeVideoModal(true)}
                videoPlatform={'vimeo'}
                videoId={String(modalVimeoIdMobile)}
              />
            )}
          </Content>
        </ContentWrapper>
      </Wrapper>
    </ThemeProvider>
  )
}
