import * as React from 'react'

import { Bag as BagIcon, DeprecatedText } from '@syconium/little-miss-figgy'
import { trackEvent } from '@syconium/magnolia/src/lib/analytics'
import { useGoogleTagManager } from '@syconium/magnolia/src/lib/hooks/useGoogleTagManager'

import { useLocalization } from '../../../../app/_providers/LocalizationProvider.client'
import { CartContainer } from '../../../containers/cart'
import { useFixturesContext } from '../../../containers/fixtures'
import { MiniCartSlideOutContainer } from '../../../containers/mini-cart-slideout'

import { IProps } from './IProps'
import { Button } from './styles'

export const CartButton: React.FC<IProps> = ({ ...rest }) => {
  const {
    magnolia: { navigation: strings },
  } = useFixturesContext()
  const { cart } = CartContainer.useContainer()
  const { toggleIsRevealed } = MiniCartSlideOutContainer.useContainer()
  const buttonRef = React.useRef<HTMLButtonElement>(null)
  const { pushOpenMiniCart } = useGoogleTagManager()
  const { region } = useLocalization()

  return (
    <Button
      ref={buttonRef}
      onClick={() => {
        toggleIsRevealed()
        pushOpenMiniCart(cart, region.currency)
      }}
      aria-label={strings.cartButtonAriaLabel}
      {...trackEvent({ category: 'navigation', action: 'open' })}
      {...rest}
    >
      <BagIcon />
      <DeprecatedText textType='tag' asTag='span'>
        {(cart && cart.itemCount) || 0}
      </DeprecatedText>
    </Button>
  )
}
