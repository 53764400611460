import { useRouter } from 'next/router'
import React from 'react'

import { useLocalization } from '@syconium/magnolia/src/app/_providers/LocalizationProvider.client'
import { NavContainer } from '@syconium/magnolia/src/brunswick/containers/NavContainer'
import { trackEvent } from '@syconium/magnolia/src/lib/analytics'

import { SearchOverlayForm } from '../../pages/search/Search/SearchOverlayForm'

import { MobileSearchWrap, Overlay } from './styles'

export const MobileSearch: React.FC<{
  onAutoComplete: () => void
  onClearFormClick: () => void
  onClickSearchOverlayProduct: () => void
  onFormSubmit: (searchText: string) => void
  pushedUp: boolean
  searchResults: boolean
}> = ({
  onAutoComplete,
  onClearFormClick,
  onClickSearchOverlayProduct,
  onFormSubmit,
  pushedUp,
  searchResults,
}) => {
  const { languageGroup } = useLocalization()
  const router = useRouter()
  const { promoBarHeightDesktop, promoBarHeightMobile } = NavContainer.useContainer()
  const shouldDisplaySearchOverlay = false
  const isMobileSearch = true

  if (languageGroup !== 'en') return null
  if (router.pathname === '/search') return null

  return (
    <>
      <MobileSearchWrap
        pushedUp={pushedUp}
        searchResults={searchResults}
        {...trackEvent({ category: 'navigation', action: 'click tab', label: 'Open Search' })}
      >
        <SearchOverlayForm
          isMobileSearch={isMobileSearch}
          onAutoComplete={onAutoComplete}
          onClearFormClick={onClearFormClick}
          onFormSubmit={onFormSubmit}
          onClickSearchOverlayProduct={onClickSearchOverlayProduct}
          shouldDisplaySearchOverlay={shouldDisplaySearchOverlay}
          {...trackEvent({ category: 'navigation', action: 'click tab', label: 'Open Search' })}
        />
      </MobileSearchWrap>
      {searchResults && (
        <Overlay
          promoBarHeightDesktop={promoBarHeightDesktop}
          promoBarHeightMobile={promoBarHeightMobile}
        />
      )}
    </>
  )
}
