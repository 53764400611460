import React from 'react'

import {
  Accordion,
  Column,
  Facebook,
  Grid,
  HoverUnderline,
  Instagram,
  LocaleButton,
  LocaleButtonIcon,
  LocaleButtonText,
  Media,
  Pinterest,
  Row,
  Spacer,
  Twitter,
  YouTube,
} from '@syconium/little-miss-figgy'
import { RegionAndLocalePickerModal } from '@syconium/magnolia/src/components/modals/RegionAndLocalePickerModal'
import { useModalState } from '@syconium/magnolia/src/components/modals/useModalState'

import { useLocalization } from '../../../../app/_providers/LocalizationProvider.client'
import { useFixturesContext } from '../../../../brunswick/containers/fixtures'
import { useRegionLocalePicker } from '../../../../lib/hooks/useRegionLocalePicker'
import { ContentfulRegion } from '../../../../types/graphql'

import {
  AccordionContainer,
  ContactLink,
  Container,
  CountryWrapper,
  CountryWrapperChildren,
  FooterCopyright,
  FooterDarkText,
  FooterLeft,
  FooterLinksDivider,
  FooterLinksList,
  FooterLinksListItem,
  FooterListLink,
  FooterSocialLinkList,
  MenuLink,
  MenuLinkItem,
  MenuList,
  MenuListItem,
  MenuTitle,
  Promo,
  PromoHashtag,
  PromoSocialItem,
  PromoSocialLink,
  PromoSocialLinkSRText,
  PromoTagline,
  StyledFooter,
} from './styles'

const showChat = true

const defaultSocialLinks = [
  {
    text: 'Facebook',
    href: 'http://www.facebook.com/wearFIGS',
    icon: <Facebook />,
  },
  {
    text: 'Twitter',
    href: 'http://www.twitter.com/wearFIGS',
    icon: <Twitter />,
  },
  {
    text: 'Instagram',
    href: 'http://www.instagram.com/wearFIGS',
    icon: <Instagram />,
  },
  {
    text: 'Pinterest',
    href: 'http://www.pinterest.com/wearFIGS',
    icon: <Pinterest />,
  },
  {
    text: 'YouTube',
    href: 'https://www.youtube.com/c/wearFIGS',
    icon: <YouTube />,
  },
]

export interface FooterSocialLinkProps {
  href: string
  text: string
  icon: React.ReactNode
}

export const FooterSocialLink: React.FC<FooterSocialLinkProps> = ({ href, text, icon }) => {
  const pageName = text.length ? text.charAt(0).toUpperCase() + text.slice(1) : ''
  const ariaLabel = `Go to Wearfigs ${pageName} Page`

  return (
    <PromoSocialItem role='listitem'>
      <PromoSocialLink aria-label={ariaLabel} href={href}>
        {icon && (
          <span aria-hidden='true' role='presentation'>
            {icon}
          </span>
        )}
        <PromoSocialLinkSRText>{text}</PromoSocialLinkSRText>
      </PromoSocialLink>
    </PromoSocialItem>
  )
}

export interface FooterPromoProps {
  children?: React.ReactNode
  hashtag: string
  socialLinks: FooterSocialLinkProps[]
}

export const FooterPromo: React.FC<FooterPromoProps> = ({ children, hashtag, socialLinks }) => {
  return (
    <Promo>
      <PromoTagline>{children}</PromoTagline>
      <PromoHashtag>#{hashtag}</PromoHashtag>
      {socialLinks.length > 0 && (
        <FooterSocialLinkList role='list'>
          {socialLinks.map((link, index) => (
            <FooterSocialLink {...link} key={`${link.text}-${index}`} />
          ))}
        </FooterSocialLinkList>
      )}
    </Promo>
  )
}

export interface LinkItem {
  href: string
  title: string
}

export interface FooterGetHelpProps {
  helpCenter: LinkItem
  shipping: LinkItem
  returnsAndExchanges: LinkItem
  teamsOrders: LinkItem
  contactUs: LinkItem
  phone: {
    number: string
    cta: string
    timeText: string
    availableDaysText: string
  }
  chat: {
    title: string
    href: string
    timeText: string
    availableDaysText: string
  }
  children?: React.ReactNode
}

export const FooterGetHelp: React.FC<FooterGetHelpProps> = ({
  helpCenter,
  shipping,
  returnsAndExchanges,
  teamsOrders,
  contactUs,
  phone,
  children,
  chat,
}) => {
  return (
    <>
      <FooterMenuTitle>Get help</FooterMenuTitle>
      <FooterMenuList>
        <FooterMenuListItem>
          <FooterMenuLink href={helpCenter.href}>{helpCenter.title}</FooterMenuLink>
        </FooterMenuListItem>
        <FooterMenuListItem>
          <FooterMenuLink href={shipping.href}>{shipping.title}</FooterMenuLink>
        </FooterMenuListItem>
        <FooterMenuListItem>
          <FooterMenuLink href={returnsAndExchanges.href}>
            {returnsAndExchanges.title}
          </FooterMenuLink>
        </FooterMenuListItem>
        <FooterMenuListItem>
          <FooterMenuLink href={teamsOrders.href}>{teamsOrders.title}</FooterMenuLink>
        </FooterMenuListItem>
        <FooterMenuListItem>
          <FooterMenuLink href={contactUs.href}>{contactUs.title}</FooterMenuLink>
        </FooterMenuListItem>
        {phone && (
          <FooterMenuListItem>
            <ContactLink href={`tel:${phone.number}`}>{phone.number}</ContactLink>
            <br />
            <FooterDarkText>{phone.cta}</FooterDarkText>
            <br />
            <FooterDarkText>{phone.timeText}</FooterDarkText>
            <br />
            <FooterDarkText>{phone.availableDaysText}</FooterDarkText>
          </FooterMenuListItem>
        )}
        {showChat && (
          <FooterMenuListItem>
            <div>{chat.title}</div>
            <FooterDarkText>{chat.timeText}</FooterDarkText>
            <br />
            <FooterDarkText>{chat.availableDaysText}</FooterDarkText>
          </FooterMenuListItem>
        )}
        {children}
      </FooterMenuList>
    </>
  )
}

export interface FooterReferAFriendProps {
  href: string
  text: string
}

export const FooterReferAFriend: React.FC<FooterReferAFriendProps> = ({ href, text }) => {
  return (
    <>
      <Media greaterThanOrEqual='md'>
        <Spacer height={24} />
        {/* TODO: Handle this auto disabled ESLint rule. */}
        {/* eslint-disable-next-line rulesdir/require-routing-service */}
        <a href={href}>
          <MenuTitle>{text}</MenuTitle>
        </a>
      </Media>
      <Media lessThan='md'>
        <FooterLink href={href}>{text}</FooterLink>
      </Media>
    </>
  )
}

export const FooterLink: React.FC<React.AnchorHTMLAttributes<HTMLAnchorElement>> = ({
  children,
  ...anchorProps
}) => (
  <MenuLinkItem {...anchorProps}>
    <MenuTitle>{children}</MenuTitle>
  </MenuLinkItem>
)

export interface FooterMenuProps {
  children?: React.ReactNode
  accordionIndex: number
  isAccordionDisabled?: boolean
}

export const FooterMenu: React.FC<FooterMenuProps> = ({
  children,
  accordionIndex,
  isAccordionDisabled,
}) => {
  return (
    <>
      <Media greaterThanOrEqual='md'>
        <Column desktop={2}>{children}</Column>
      </Media>
      <Media lessThan='md'>
        <Accordion.Section index={accordionIndex} disabled={isAccordionDisabled}>
          {children}
        </Accordion.Section>
      </Media>
    </>
  )
}

export const FooterMenuTitle: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <>
      <Media greaterThanOrEqual='md'>
        <MenuTitle>{children}</MenuTitle>
      </Media>
      <Media lessThan='md'>
        <Accordion.Header>
          <MenuTitle>{children}</MenuTitle>
        </Accordion.Header>
      </Media>
    </>
  )
}

export const FooterMenuList: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <>
      <Media greaterThanOrEqual='md'>
        <MenuList>{children}</MenuList>
      </Media>
      <Media lessThan='md'>
        <Accordion.Body>
          <MenuList>{children}</MenuList>
        </Accordion.Body>
      </Media>
    </>
  )
}

export const FooterMenuListItem: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return <MenuListItem>{children}</MenuListItem>
}

export interface FooterMenuLinkProps {
  children: React.ReactNode
  href: string
}

export const FooterMenuLink: React.FC<FooterMenuLinkProps> = ({ children, href }) => {
  return (
    <HoverUnderline>
      <MenuLink href={href}>{children}</MenuLink>
    </HoverUnderline>
  )
}

export interface FooterFooterProps {
  asTag?: keyof JSX.IntrinsicElements
  copyright: string
  legalLinks?: LinkItem[]
  children?: React.ReactNode
}

export const FooterFooter: React.FC<FooterFooterProps> = ({
  asTag = 'footer',
  copyright,
  legalLinks,
  children,
}) => {
  const { regionData } = useFixturesContext()
  const { region, locale } = useLocalization()
  const { getRegionName, getLanguageName } = useRegionLocalePicker()
  const languageCount = Object.keys(region.languages).length
  const languageNameText = languageCount > 1 ? ` | ${getLanguageName(locale)}` : ''
  const flagSrc = regionData?.regions.find((r: ContentfulRegion) => r.id === region.id)?.flagSrc
  const { isOpen, closeModal, openModal } = useModalState()

  return (
    <StyledFooter as={asTag}>
      <FooterLeft>
        <FooterCopyright>{copyright}</FooterCopyright>
        <FooterLinksList role='list' divider={<FooterLinksDivider>•</FooterLinksDivider>}>
          {legalLinks?.map(link => {
            return (
              <FooterLinksListItem key={link.title} role='listitem'>
                <FooterListLink href={link.href}> {link.title} </FooterListLink>
              </FooterLinksListItem>
            )
          })}
        </FooterLinksList>
      </FooterLeft>
      {/** GE's target query -> "footer div[class*='Footer__CountryWrapper'] button" */}
      <CountryWrapper className='Footer__CountryWrapper__GETarget'>
        <LocaleButton isColorInverted={true} onClick={openModal}>
          <LocaleButtonIcon src={flagSrc ?? region.flagSrc} />
          <LocaleButtonText>
            <>{getRegionName(region.name)}</>
            <>{languageNameText}</>
          </LocaleButtonText>
        </LocaleButton>
        <RegionAndLocalePickerModal isOpen={isOpen} closeModal={closeModal} />
        <CountryWrapperChildren>{children}</CountryWrapperChildren>
      </CountryWrapper>
    </StyledFooter>
  )
}

export default interface FooterProps {
  children?: React.ReactNode
  promoChildren(): React.ReactNode
  hashtag: string
  copyright: string
  termsLinkHref: string
  termsLinkText: string
  privacyLinkHref: string
  privacyLinkText: string
}

export const Footer = React.forwardRef<HTMLDivElement, FooterProps>(
  (
    {
      children,
      promoChildren,
      hashtag,
      copyright,
      termsLinkHref,
      termsLinkText,
      privacyLinkHref,
      privacyLinkText,
      ...rest
    },
    ref
  ) => {
    if (React.Children.count(children) < 3)
      throw new Error('Footer requires 3 submenus for desktop grid layout')

    const legalLinks = [
      {
        href: termsLinkHref,
        title: termsLinkText,
      },
      {
        href: privacyLinkHref,
        title: privacyLinkText,
      },
    ]

    return (
      <Container ref={ref} {...rest}>
        <Media greaterThanOrEqual='md'>
          <Grid>
            <Row>
              <Column desktop={6}>
                <FooterPromo hashtag={hashtag} socialLinks={defaultSocialLinks}>
                  {promoChildren()}
                </FooterPromo>
              </Column>
              {children}
            </Row>
          </Grid>
        </Media>

        <Media lessThan='md'>
          <FooterPromo hashtag={hashtag} socialLinks={defaultSocialLinks}>
            {promoChildren()}
          </FooterPromo>
          <AccordionContainer>
            <Accordion
              variant='dark'
              icon='plus'
              isTransparent
              showBorder={true}
              ariaLabel='Footer Navigation'
            >
              {children}
            </Accordion>
          </AccordionContainer>
        </Media>

        <FooterFooter copyright={copyright} legalLinks={legalLinks} />
      </Container>
    )
  }
)
