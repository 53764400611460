import styled from 'styled-components'

import { ResponsiveImage, fromMd, timingDefault } from '@syconium/little-miss-figgy'

export const Body = styled.div<{
  $isHeading: boolean | null
  $isFooterVariant: boolean | null
  $isSecondaryText: boolean | null
}>`
  align-items: center;
  display: flex;
  line-height: ${o => (o.$isFooterVariant ? '1' : '1.5')};
  padding: ${o => (!o.$isFooterVariant ? '6px' : o.$isSecondaryText ? '4px' : '10px')} 0;

  ${fromMd} {
    padding: ${o => (!o.$isFooterVariant ? '6px' : o.$isSecondaryText ? '2px' : '5px')} 0;
  }
`

export const Btn = styled.button<{
  $isHeading: boolean | null
  $isSecondaryText: boolean | null
  $isBold: boolean | null
  $isUppercase: boolean
}>`
  border: none;
  padding: 0;
  color: ${o => (o.$isSecondaryText ? o.theme.color.deprecated.stoneDark : 'white')};
  font-size: ${o => (o.$isHeading ? '11px' : '13px')};
  letter-spacing: ${o => (o.$isUppercase ? '0.15em' : '0.05em')};
  font-weight: ${o => (o.$isBold ? 'bold' : 'normal')};

  ${fromMd} {
    font-size: 11px;
    font-weight: ${o => (o.$isHeading ? 'bold' : '500')};
    margin-bottom: ${o => (o.$isHeading ? '24px' : '0')};

    &:hover {
      font-weight: ${o => (o.$isSecondaryText ? '500' : 'bold')};
    }
  }

  span {
    pointer-events: none;
  }
`

// IconWrap prevents layout shift on icon load when content is long
export const IconWrap = styled.div`
  flex-shrink: 0;
  height: 16px;
  width: 28px;

  ${fromMd} {
    width: 24px;
  }
`

export const Icon = styled(ResponsiveImage)`
  height: 16px;
  width: 16px;
`

export const TextWrap = styled.div<{
  textColor?: string | null
}>`
  align-items: center;
  color: ${o => o.textColor};
  display: flex;

  a,
  button {
    position: relative;

    &::after {
      background: currentColor;
      bottom: -4px;
      content: '';
      height: 1px;
      left: 0;
      opacity: 0.7;
      position: absolute;
      transform-origin: left;
      transform: scaleX(0);
      transition: transform ${timingDefault} ease-in;
      width: 100%;
    }

    &:hover::after {
      transform: scaleX(1);
    }

    &[tabindex='0'] {
      cursor: pointer;
    }
  }
`

export const Text = styled.span`
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.05em;

  ${fromMd} {
    font-size: 12px;
  }
`

export const HeadingText = styled.h2`
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.15em;

  ${fromMd} {
    font-size: 13px;
  }
`
// NOTE: exists for accessibility reasons
export const RegularWeightHeadingText = styled.h2`
  font-size: 15px;
  font-weight: regular;
  letter-spacing: 0.15em;

  ${fromMd} {
    font-size: 13px;
  }
`

export const FooterText = styled(Text)<{
  $isHeading: boolean | null
  $isSecondaryText: boolean | null
}>`
  color: ${o => (o.$isSecondaryText ? o.theme.color.deprecated.stoneDark : 'white')};
  font-size: ${o => (o.$isHeading ? '11px' : '13px')};
  font-weight: ${o => (o.$isHeading ? 'bold' : '500')};

  ${fromMd} {
    font-size: 11px;
    margin-bottom: ${o => (o.$isHeading ? '24px' : '0')};

    &:hover {
      font-weight: ${o => (o.$isSecondaryText ? '500' : 'bold')};
    }
  }
`

export const Badge = styled.b<{
  badgeColor: string | null
  badgeTextColor: string | null
}>`
  background: ${o => o.badgeColor || o.theme.color.deprecated.stoneLight};
  border-radius: 11px;
  color: ${o => o.badgeTextColor || o.theme.color.deprecated.obsidian};
  font-size: 9px;
  margin-inline-start: 8px;
  padding: 5px 9px 4px;
  text-transform: uppercase;
`

export const FooterItemIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  gap: ${o => o.theme.spacing(2)}
  padding: 3px 0 0 0;

  ${fromMd} {
    padding: 5px 0 0 0;
  }
`

export const FooterItemIcon = styled(ResponsiveImage)`
  display: block;
  max-height: 1rem;
  width: 25px;
  flex: 0 0 auto;
`
