import * as React from 'react'

import { BaseIcon, IProps } from '../../BaseIcon'

interface Props extends IProps {
  viewBox?: string
}

export const ScrubCap: React.FC<Props> = ({
  height = '40px',
  width = '40px',
  viewBox = '0 0 40 40',
  ...rest
}) => {
  return (
    <BaseIcon height={height} width={width} viewBox={viewBox} {...rest}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM27.6037 28.385C27.5802 28.2084 27.6071 28.0286 27.6814 27.8666C28.3404 27.8903 28.7886 28.0389 29.0444 28.2369C29.1332 28.2991 29.2025 28.3853 29.2441 28.4855C29.2674 28.5499 29.2661 28.6207 29.2403 28.6842C29.1778 28.8102 29.0732 28.9104 28.9446 28.9676C28.6159 29.0348 28.2743 28.9969 27.9682 28.8595C27.9622 28.8556 27.9562 28.8518 27.9496 28.8484C27.8575 28.7991 27.7772 28.7305 27.7141 28.6473C27.6571 28.5698 27.6193 28.4799 27.6037 28.385ZM27.3536 27.2596C27.679 26.7614 27.9735 26.2809 28.237 25.8183C24.7593 25.4579 21.3244 24.7635 17.9798 23.7446C14.9091 22.8214 11.9521 21.5556 9.16459 19.9711C9.10709 20.3855 9.05223 20.8166 9 21.2644C11.7899 22.9149 14.7513 24.2567 17.8317 25.2662C20.9241 26.2736 24.1168 26.942 27.3536 27.2596ZM10.2017 15.0285C9.76992 16.4398 9.45333 17.8839 9.25502 19.3464C12.0626 20.9651 15.0482 22.2529 18.1523 23.1841C21.5395 24.2158 25.0202 24.9113 28.5438 25.2604C28.8722 24.6497 29.1573 24.0168 29.3968 23.3662C29.8077 22.2249 29.9495 21.2417 29.8225 20.4165C28.3159 16.9716 26.0705 14.5854 23.0863 13.258C20.0858 11.9234 16.3275 11.6507 11.8113 12.4399C11.2089 12.7312 10.6723 13.5941 10.2017 15.0285Z'
      />
    </BaseIcon>
  )
}
