import { FC, useCallback, useRef, useState } from 'react'

import { Media, RotateClockwise, useSupportsHover } from '@syconium/little-miss-figgy'
import { CenteredTileLayout } from '@syconium/magnolia/src/brunswick/components/CenteredTileLayout'

import { Carat } from '../../../../../../../../components/pages/claim-gift/cart/OptionToggle/styles'
import {
  VisualFiltersSection as Props,
  VisualFilterTile,
} from '../../../../../../../../types/graphql'

import { CoreStylesVisualFilterSectionTile } from './CoreStylesVisualFilterSectionTile'
import {
  CoreStylesVariantSlider,
  EntryCard,
  EntryCardDescription,
  EntryCardHeader,
  Legend,
  SliderButtonLeft,
  SliderButtonRight,
  SpacerTile,
  TileWrapper,
  VariantWrapper,
  Wrapper,
} from './styles'
import { VisualFilterSectionTile } from './VisualFilterTile'

export const tileGapDesktop = 40
export const tileGapMobile = 24
export const minTileWidth = 110
export const maxTileWidth = 190
export const numberOfTilesDesktop = 9
export const numberOfTilesMobile = 5
export const coreStylesVisibleTilesDesktop = 5
export const coreStylesVisibleTilesMobile = 2.5

const calcMinWordLength = (s: string) => {
  return Math.min(...s.split(' ').map(w => w.length))
}

interface EntryCardTile {
  header: string | null
  description: string | null
  color: string
  isEntryCard: boolean
  id: string
}

export const VisualFilters: FC<Props> = ({
  layoutVariantDesktop,
  tiles,
  layoutVariantMobile,
  hideOnDesktop,
  hideOnMobile,
  isCoreStylesVariant,
  entryCardDescription,
  entryCardHeader,
  id,
}) => {
  const [scrollsLeft, setScrollsLeft] = useState(false)
  const [scrollsRight, setScrollsRight] = useState(true)
  const hoverSupported = useSupportsHover()
  const entryCardTile: EntryCardTile = {
    header: entryCardHeader,
    description: entryCardDescription,
    color: 'black',
    id: id,
    isEntryCard: true,
  }
  const scrollRef = useRef<HTMLDivElement>(null)
  const [rollingFilters, setRollingFilters] = useState<string[]>([])
  const imagePriority = (index: number) => {
    const entryCardExists = entryCardDescription && entryCardHeader ? true : false
    const imageIndex = entryCardExists ? index - 1 : index
    return imageIndex === 0 ? 'preload' : imageIndex >= 2 ? 'lazy' : 'default'
  }
  var body = (
    <Wrapper>
      <CenteredTileLayout
        hasVerticalMargin={false}
        layoutVariantDesktop={layoutVariantDesktop}
        layoutVariantMobile={layoutVariantMobile}
        tileGapDesktop={tileGapDesktop}
        tileGapMobile={tileGapMobile}
        minTileWidth={minTileWidth}
        maxTileWidth={maxTileWidth}
        tiles={tiles.filter(tile => tile.description === null)}
        liftedButtons
        keyFromTile={(o: VisualFilterTile, index: number) => `${index}--${o.id}`}
        renderTile={(o: VisualFilterTile) => {
          return (
            <VisualFilterSectionTile
              {...o}
              toggleRollingFilter={(filter: string) => {
                if (rollingFilters.includes(filter)) {
                  setRollingFilters(prev => prev.filter(v => v !== filter))
                } else {
                  setRollingFilters(prev => [...prev, filter])
                }
              }}
              rollingFilters={rollingFilters}
              ctaText={o.ctaText}
              image={o.image}
              value={o.value}
              filter={o.filter}
              filterSpec={o.filterSpec}
              visibleTilesDesktop={numberOfTilesDesktop}
              visibleTilesMobile={numberOfTilesMobile}
            />
          )
        }}
        visibleTilesDesktop={numberOfTilesDesktop}
        visibleTilesMobile={numberOfTilesMobile}
      />
    </Wrapper>
  )
  const entryCardAndTiles =
    entryCardDescription && entryCardHeader ? [entryCardTile, ...tiles] : tiles
  const checkIfScrollable = () => {
    if (scrollRef.current && scrollRef.current.scrollWidth > scrollRef.current.clientWidth) {
      setScrollsLeft(scrollRef.current.scrollLeft > 8)
      setScrollsRight(
        scrollRef.current.scrollLeft + scrollRef.current.clientWidth + 8 <
          scrollRef.current.scrollWidth
      )
    } else {
      setScrollsLeft(false)
      setScrollsRight(false)
    }
  }
  const scrollRight = useCallback(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft += scrollRef.current.getBoundingClientRect().width * 0.8
    }
  }, [])
  const scrollLeft = useCallback(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft -= scrollRef.current.getBoundingClientRect().width * 0.8
    }
  }, [])
  const isGrid = entryCardAndTiles.length <= coreStylesVisibleTilesDesktop
  var variant = (
    <VariantWrapper
      layoutVariantDesktop={
        entryCardAndTiles.length <= coreStylesVisibleTilesDesktop ? 'grid' : layoutVariantDesktop
      }
    >
      <CoreStylesVariantSlider
        ref={scrollRef}
        onScroll={() => {
          checkIfScrollable()
        }}
        layoutVariantDesktop={
          entryCardAndTiles.length <= coreStylesVisibleTilesDesktop ? 'grid' : layoutVariantDesktop
        }
        aria-hidden={true}
      >
        <TileWrapper>
          <SpacerTile></SpacerTile>
          <Legend>Style Filters</Legend>
          {entryCardDescription && entryCardHeader && (
            <EntryCard
              layoutVariantDesktop={
                entryCardAndTiles.length <= coreStylesVisibleTilesDesktop
                  ? 'grid'
                  : layoutVariantDesktop
              }
            >
              <EntryCardHeader
                oneWordPerLine={true}
                minWordLength={calcMinWordLength(entryCardHeader)}
              >
                {entryCardHeader}
              </EntryCardHeader>
              <EntryCardDescription>{entryCardDescription}</EntryCardDescription>
            </EntryCard>
          )}
          {tiles.map((tile, index) => {
            return (
              <CoreStylesVisualFilterSectionTile
                {...tile}
                key={tile.id}
                toggleRollingFilter={(filter: string) => {
                  if (rollingFilters.includes(filter)) {
                    setRollingFilters(prev => prev.filter(v => v !== filter))
                  } else {
                    setRollingFilters(prev => [...prev, filter])
                  }
                }}
                rollingFilters={rollingFilters}
                ctaText={tile.ctaText}
                image={tile.image}
                value={tile.value}
                filter={tile.filter}
                filterSpec={tile.filterSpec}
                imagePriority={imagePriority(index)}
                desktopLayout={
                  entryCardAndTiles.length <= coreStylesVisibleTilesDesktop
                    ? 'grid'
                    : layoutVariantDesktop
                }
              />
            )
          })}
        </TileWrapper>
      </CoreStylesVariantSlider>
      {!isGrid && hoverSupported && scrollsRight ? (
        <SliderButtonRight aria-label='Scroll right' onClick={scrollRight} $lifted={true}>
          <Carat height='18' width='16' />
        </SliderButtonRight>
      ) : null}
      {!isGrid && hoverSupported && scrollsLeft ? (
        <SliderButtonLeft aria-label='Scroll left' onClick={scrollLeft} $lifted={true}>
          <Carat height='18' rotateClockwise={RotateClockwise.Half} width='16' />
        </SliderButtonLeft>
      ) : null}
    </VariantWrapper>
  )

  if (hideOnDesktop && hideOnMobile) return null
  if (hideOnDesktop) return <Media lessThan='md'>{body}</Media>
  if (hideOnMobile) return <Media greaterThanOrEqual='md'>{body}</Media>
  return isCoreStylesVariant ? variant : body
}
