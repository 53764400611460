'use client'

import { useCallback, useEffect, useRef, useState } from 'react'
import { createContainer } from 'unstated-next'

import { asyncGlobalVariable } from '../../lib/utils'

const OneTrustObservable$ = asyncGlobalVariable(() => {
  if (
    typeof globalThis.OneTrust?.GetDomainData === 'function' &&
    typeof globalThis.OneTrust?.OnConsentChanged === 'function'
  ) {
    return globalThis.OneTrust
  } else {
    return undefined
  }
})

type OneTrustConsentChangeEvent = Parameters<
  Parameters<NonNullable<NonNullable<typeof OneTrust>['OnConsentChanged']>>[0]
>[0]

export const consentCategoryCodes = {
  essential: 'C0001',
  performance: 'C0002',
  functional: 'C0003',
  targeting: 'C0004',
  social: 'C0005',
} as const

export type ConsentCategoryAlias = keyof typeof consentCategoryCodes
export type ConsentCategoryCode = (typeof consentCategoryCodes)[keyof typeof consentCategoryCodes]
export type ConsentStatus = 'allowed' | 'blocked'
export type ConsentPolicy = Record<ConsentCategoryAlias, ConsentStatus>

function isConsentCategoryCode(code: string | undefined): code is ConsentCategoryCode {
  if (!code) return false
  return Object.values(consentCategoryCodes).includes(code as ConsentCategoryCode)
}

const getConsentCategoryAliasForCode = (code: ConsentCategoryCode): ConsentCategoryAlias => {
  const categoryAlias = Object.keys(consentCategoryCodes).find(key => {
    return consentCategoryCodes[key as unknown as ConsentCategoryAlias] === code
  })
  return categoryAlias as unknown as ConsentCategoryAlias
}

const consentPoliciesMatch = (these: ConsentPolicy, those: ConsentPolicy): boolean => {
  let matches = true
  Object.keys(these).forEach(key => {
    if (
      those[key as unknown as ConsentCategoryAlias] !==
      these[key as unknown as ConsentCategoryAlias]
    ) {
      matches = false
    }
  })
  return matches
}

const getCurrentConsentPolicy = (): ConsentPolicy => {
  const consentPolicy: ConsentPolicy = {
    essential: 'allowed',
    performance: 'blocked',
    functional: 'blocked',
    targeting: 'blocked',
    social: 'blocked',
  }

  if (typeof globalThis.OneTrust?.GetDomainData !== 'function') {
    return consentPolicy
  }

  const purposes =
    globalThis.OneTrust.GetDomainData().ConsentIntegrationData.consentPayload.purposes ?? []
  const groups = globalThis.OneTrust.GetDomainData().Groups ?? []
  purposes.forEach(purpose => {
    const group = groups.find(group => {
      return group.PurposeId === purpose.Id
    })
    const categoryCode = group?.OptanonGroupId
    if (isConsentCategoryCode(categoryCode)) {
      const categoryAlias = getConsentCategoryAliasForCode(categoryCode)
      const consent: ConsentStatus =
        purpose.TransactionType === 'CONFIRMED'
          ? 'allowed'
          : purpose.TransactionType === 'OPT_OUT'
          ? 'blocked'
          : group?.Status === 'always active'
          ? 'allowed'
          : group?.Status === 'active'
          ? 'allowed'
          : 'blocked'
      consentPolicy[categoryAlias] = consent
    }
  })
  return consentPolicy
}

const useConsentPolicyImpl = (initialState: ConsentPolicy | undefined): ConsentPolicy => {
  const [containerState, setContainerState] = useState<ConsentPolicy>(() => {
    return initialState ?? getCurrentConsentPolicy()
  })

  const onOneTrustConsentPolicyChange = useCallback(
    (event: OneTrustConsentChangeEvent) => {
      const currentConsentPolicy: ConsentPolicy = {
        essential: 'allowed',
        performance: 'blocked',
        functional: 'blocked',
        targeting: 'blocked',
        social: 'blocked',
      }
      event.detail.forEach(categoryCode => {
        if (isConsentCategoryCode(categoryCode)) {
          const categoryAlias = getConsentCategoryAliasForCode(categoryCode)
          currentConsentPolicy[categoryAlias] = 'allowed'
        }
      })
      if (!consentPoliciesMatch(currentConsentPolicy, containerState)) {
        setContainerState(currentConsentPolicy)
      }
    },
    [containerState]
  )

  const referenceToLatestOnChangeFunction = useRef(onOneTrustConsentPolicyChange)

  useEffect(() => {
    referenceToLatestOnChangeFunction.current = onOneTrustConsentPolicyChange

    const observableSubscription = OneTrustObservable$.subscribe(() => {
      // Update based on what exists.
      const currentConsentPolicy = getCurrentConsentPolicy()
      if (!consentPoliciesMatch(currentConsentPolicy, containerState)) {
        setContainerState(currentConsentPolicy)
      }

      // Subscribe to changes.
      if (typeof globalThis.OneTrust?.OnConsentChanged === 'function') {
        // Note: OneTrust.OnConsentChanged is not architected to handle calling it multiple times. To avoid bugs, we keep setting it to a function that references a pointer to the most recent `useCallback` result. So even if it has a stale onchange function, the stale function would still call the most recent callback.
        globalThis.OneTrust.OnConsentChanged(event => {
          referenceToLatestOnChangeFunction.current?.(event)
        })
      }
    })

    return () => {
      observableSubscription?.unsubscribe()
    }
  }, [containerState, onOneTrustConsentPolicyChange])

  return containerState
}

const ConsentPolicyContainer = createContainer(useConsentPolicyImpl)
export const ConsentPolicyProvider = ConsentPolicyContainer.Provider
export const useConsentPolicy = ConsentPolicyContainer.useContainer
