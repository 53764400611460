import { createContainer } from 'unstated-next'

import { ICheckoutClient } from '../../../lib/cart-client/interfaces'

function useCheckoutClientImpl(checkoutClient: ICheckoutClient | undefined) {
  return checkoutClient!
}

const CheckoutClientContainer = createContainer(useCheckoutClientImpl)

export const CheckoutClientProvider = ({
  initialState,
  children,
}: {
  initialState: ICheckoutClient
  children: React.ReactNode
}) => {
  return (
    <CheckoutClientContainer.Provider initialState={initialState}>
      {children}
    </CheckoutClientContainer.Provider>
  )
}

export const useCheckoutClient = CheckoutClientContainer.useContainer
