import React, { FC } from 'react'

import { Accordion, FigsLoader, ProgressBar, TypeStyleBodyLarge } from '@syconium/little-miss-figgy'
import {
  CondensedSizeRecCategoryLabel,
  CondensedSizeRecommendation,
  SizeRecCategoryLabel,
  SizeRecommendation,
} from '@syconium/little-miss-figgy/dist/components/SizeRecommendation/SizeRecommendation'

import {
  FitQuiz as IFitQuiz,
  Maybe,
  ProductGroup,
  StyledLink,
  StyledText,
} from '../../../__generated__/graphql/catalog/graphql'
import { useFixturesContext } from '../../../brunswick/containers/fixtures'
import { trackEvent } from '../../../lib/analytics'

import FitQuizForm from './form/form'
import { FitQuizModalStateContainer } from './hooks/useFitQuizModalState'
import { FitQuizStringMapsContainer } from './hooks/useFitQuizStringMaps'
import {
  FitQuizUserStateContainer,
  Recommendation as RecommendationType,
} from './hooks/useFitQuizUserState'
import { InitialSizeProfileContainer } from './hooks/useInitialSizeProfile'
import {
  AccordionContainer,
  AccordionSizeRecContainer,
  AccordionTitle,
  Body,
  CallToLogin,
  ErrorButtonsContainer,
  ErrorContainer,
  ErrorDescription,
  ErrorTitle,
  ExitLink,
  ExitModalButton,
  LoadingBody,
  ProgressContainer,
  RecommendationButtonsContainer,
  RestartButton,
  SizeRecContainer,
  SmallHeaderContentfulStyledText,
  StepContainer,
  StepText,
  TextLink,
} from './styles'
export type FitQuizType = IFitQuiz & {
  rawFits: ProductGroup['rawFits']
  preferredGender?: string
  incomingProductGroupCategory?: ProductGroup['category']
  displayExitLink?: boolean
  closeModal?: () => void
}

export const isAFitQuizLink = (href: string): boolean => {
  return /^\/fit-quiz\/.+/.test(href)
}

export const FitQuiz: FC<FitQuizType> = props => {
  return (
    <FitQuizStringMapsContainer.Provider>
      <InitialSizeProfileContainer.Provider>
        <FitQuizUserStateContainer.Provider
          initialState={
            props.preferredGender === 'women'
              ? 'women'
              : props.preferredGender === 'men'
              ? 'men'
              : undefined
          }
        >
          <FitQuizModalStateContainer.Provider>
            <FitQuizContent {...props} />
          </FitQuizModalStateContainer.Provider>
        </FitQuizUserStateContainer.Provider>
      </InitialSizeProfileContainer.Provider>
    </FitQuizStringMapsContainer.Provider>
  )
}

export const FitQuizContent: FC<FitQuizType> = props => {
  const {
    magnolia: {
      fitQuiz: { progressText1, progressText2 },
    },
  } = useFixturesContext()
  const { step } = FitQuizUserStateContainer.useContainer()
  return (
    <Body>
      <StepContainer>
        <StepText>
          {step === 'recommendation' || step === 'error'
            ? progressText2?.toUpperCase()
            : progressText1?.toUpperCase()}
        </StepText>
        <StepText>{step === 'recommendation' || step === 'error' ? 2 : 1}/2</StepText>
      </StepContainer>
      <ProgressContainer>
        <ProgressBar
          percentageComplete={step === 'recommendation' || step === 'error' ? 100 : 50}
        />
      </ProgressContainer>
      {step === 'intro' && props.introText && (
        <Intro introText={props.introText} preferredGender={props.preferredGender} />
      )}
      {step === 'loading' && <Loading />}
      {step === 'recommendation' && (
        <Recommendation
          recommendationText={props.recommendedFitText}
          incomingProductGroupCategory={props.incomingProductGroupCategory}
          exitLink={props.displayExitLink ? props.exitLink : undefined}
          closeModal={props.closeModal}
        />
      )}
      {step === 'error' && (
        <ErrorState
          exitLink={props.displayExitLink ? props.exitLink : undefined}
          incomingProductGroupCategory={props.incomingProductGroupCategory}
          closeModal={props.closeModal}
        />
      )}
    </Body>
  )
}

export const ErrorState: FC<{
  exitLink?: Maybe<StyledLink>
  closeModal?: () => void
  incomingProductGroupCategory?: ProductGroup['category']
}> = ({ exitLink, closeModal, incomingProductGroupCategory }) => {
  const {
    magnolia: {
      fitQuiz: {
        size,
        recommendations: { finishedQuiz, restartQuiz },
        apiError: { errorDescription, errorTitle, unavailable },
      },
    },
  } = useFixturesContext()
  const { isValidCategory, categoryLabelMap } = FitQuizStringMapsContainer.useContainer()
  const { setStep } = FitQuizUserStateContainer.useContainer()
  return (
    <>
      <ErrorTitle>{errorTitle}</ErrorTitle>
      <ErrorDescription>{errorDescription} </ErrorDescription>
      <ErrorContainer>
        <div>
          {incomingProductGroupCategory && (
            <SizeRecCategoryLabel>
              {isValidCategory(incomingProductGroupCategory)
                ? categoryLabelMap[incomingProductGroupCategory]?.toUpperCase()
                : undefined}
            </SizeRecCategoryLabel>
          )}
          <SizeRecommendation
            size={unavailable.toUpperCase()}
            sizeLabel={size.toUpperCase()}
            unavailable
          />
        </div>
        <ErrorButtonsContainer>
          <RestartButton
            onClick={() => setStep('intro')}
            variant='black-on-white'
            {...trackEvent({
              category: 'fit quiz',
              action: 'restart fit quiz from api error',
            })}
          >
            {restartQuiz}
          </RestartButton>
          {exitLink ? (
            <ExitLink
              as='a'
              href={exitLink.url}
              {...trackEvent({
                category: 'fit quiz',
                action: 'click exit cta from api error',
                value: exitLink.url,
              })}
            >
              {exitLink.text}
            </ExitLink>
          ) : (
            closeModal && (
              <ExitModalButton
                onClick={() => {
                  closeModal()
                }}
                {...trackEvent({
                  category: 'fit quiz',
                  action: 'click finished fit quiz from api error',
                })}
              >
                {finishedQuiz}
              </ExitModalButton>
            )
          )}
        </ErrorButtonsContainer>
      </ErrorContainer>
    </>
  )
}

export const Intro: FC<{
  introText: StyledText
  preferredGender?: string
}> = ({ introText, preferredGender }) => {
  const {
    magnolia: {
      fitQuiz: {
        intro: { pointSymbol, login, or, createAccount, endText },
      },
    },
  } = useFixturesContext()
  return (
    <>
      {introText && <SmallHeaderContentfulStyledText {...introText} />}
      <CallToLogin>
        {pointSymbol}{' '}
        <TextLink
          href={'/account/login'}
          {...trackEvent({
            category: 'fit quiz',
            action: 'click to login',
          })}
        >
          {login}
        </TextLink>{' '}
        {or}{' '}
        <TextLink
          href={'/account/register'}
          {...trackEvent({
            category: 'fit quiz',
            action: 'click to register',
          })}
        >
          {createAccount}
        </TextLink>{' '}
        {endText}
      </CallToLogin>
      <FitQuizForm initialGender={preferredGender?.toUpperCase()} />
    </>
  )
}

export const Loading: FC = () => {
  const {
    magnolia: {
      fitQuiz: { loadingText },
    },
  } = useFixturesContext()
  return (
    <LoadingBody>
      <FigsLoader size={'100px'} />
      <TypeStyleBodyLarge>{loadingText} </TypeStyleBodyLarge>
    </LoadingBody>
  )
}

export const Recommendation: FC<{
  recommendationText?: Maybe<StyledText>
  incomingProductGroupCategory?: ProductGroup['category']
  exitLink?: Maybe<StyledLink>
  closeModal?: () => void
}> = ({ recommendationText, incomingProductGroupCategory, exitLink, closeModal }) => {
  const {
    recommendations: allRecommendations,
    setResubmitQuizFlowActive,
    selectedGender,
  } = FitQuizUserStateContainer.useContainer()
  const { categoryLabelMap, fitsMap, sizesMap, isValidCategory, isValidFit, isValidSize } =
    FitQuizStringMapsContainer.useContainer()
  const {
    magnolia: {
      fitQuiz: {
        size,
        sizeType,
        recommendations: { finishedQuiz, restartQuiz },
      },
    },
  } = useFixturesContext()
  const recommendations =
    selectedGender === 'men' ? allRecommendations?.men : allRecommendations?.women

  if (!recommendations) return null

  const incomingProductGroupRecommendation = recommendations?.find(
    recommendation => recommendation?.category === incomingProductGroupCategory
  )

  const remainingProductGroupRecommendations = recommendations.filter(
    recommendation => recommendation?.category !== incomingProductGroupCategory
  )

  return (
    <>
      {recommendationText && <SmallHeaderContentfulStyledText {...recommendationText} />}
      {incomingProductGroupCategory ? (
        <>
          {incomingProductGroupRecommendation ? (
            <SizeRecContainer>
              <div>
                <SizeRecCategoryLabel>
                  {isValidCategory(incomingProductGroupCategory)
                    ? categoryLabelMap[incomingProductGroupCategory]?.toUpperCase()
                    : undefined}
                </SizeRecCategoryLabel>
                <SizeRecommendation
                  size={
                    isValidSize(incomingProductGroupRecommendation.size)
                      ? sizesMap[incomingProductGroupRecommendation.size]?.toUpperCase()
                      : undefined
                  }
                  fit={
                    isValidFit(incomingProductGroupRecommendation.fit)
                      ? fitsMap[incomingProductGroupRecommendation.fit]?.toUpperCase()
                      : undefined
                  }
                  sizeLabel={size.toUpperCase()}
                  fitLabel={sizeType.toUpperCase()}
                />
              </div>
            </SizeRecContainer>
          ) : null}

          {remainingProductGroupRecommendations &&
            remainingProductGroupRecommendations.length > 0 && (
              <FitAccordion recommendations={remainingProductGroupRecommendations} />
            )}
        </>
      ) : (
        <SizeRecContainer>
          {recommendations?.map((recommendation, i) => {
            const category = recommendation?.category
            const recommendedSize = recommendation?.size
            const recommendedFit = recommendation?.fit

            return (
              category && (
                <div>
                  <CondensedSizeRecCategoryLabel>
                    {isValidCategory(category)
                      ? categoryLabelMap[category].toUpperCase()
                      : undefined}
                  </CondensedSizeRecCategoryLabel>
                  <CondensedSizeRecommendation
                    key={i}
                    size={isValidSize(recommendedSize) ? sizesMap[recommendedSize] : undefined}
                    fit={isValidFit(recommendedFit) ? fitsMap[recommendedFit] : undefined}
                    sizeLabel={size}
                    fitLabel={sizeType}
                  />
                </div>
              )
            )
          })}
        </SizeRecContainer>
      )}
      <RecommendationButtonsContainer>
        <RestartButton
          onClick={() => setResubmitQuizFlowActive(true)}
          variant='black-on-white'
          {...trackEvent({
            category: 'fit quiz',
            action: 'restart fit quiz',
          })}
        >
          {restartQuiz}
        </RestartButton>
        {exitLink ? (
          <ExitLink
            forwardedAs={'a'}
            variant='white-on-black'
            href={exitLink.url}
            {...trackEvent({
              category: 'fit quiz',
              action: 'click exit cta',
              value: exitLink.url,
            })}
          >
            {exitLink.text}
          </ExitLink>
        ) : (
          closeModal && (
            <ExitModalButton
              onClick={() => {
                closeModal()
              }}
              {...trackEvent({
                category: 'fit quiz',
                action: 'click finished fit quiz',
              })}
            >
              {finishedQuiz}
            </ExitModalButton>
          )
        )}
      </RecommendationButtonsContainer>
    </>
  )
}

export const FitAccordion: FC<{ recommendations: RecommendationType[] }> = ({
  recommendations,
}) => {
  const { categoryLabelMap, sizesMap, fitsMap, isValidCategory, isValidFit, isValidSize } =
    FitQuizStringMapsContainer.useContainer()
  const {
    magnolia: {
      fitQuiz: {
        size,
        sizeType,
        recommendations: { accordionTitle },
      },
    },
  } = useFixturesContext()
  return (
    <AccordionContainer>
      <Accordion withAnimation variant='light' icon='plus'>
        <Accordion.Section index={0}>
          <Accordion.Header>
            <AccordionTitle>{accordionTitle.toUpperCase()}</AccordionTitle>
          </Accordion.Header>
          <Accordion.Body>
            <AccordionSizeRecContainer>
              {recommendations?.map((recommendation, i) => {
                const category = recommendation?.category
                const recommendedSize = recommendation?.size
                const recommendedFit = recommendation?.fit
                return (
                  category && (
                    <div>
                      <CondensedSizeRecCategoryLabel>
                        {isValidCategory(category)
                          ? categoryLabelMap[category].toUpperCase()
                          : undefined}
                      </CondensedSizeRecCategoryLabel>
                      <CondensedSizeRecommendation
                        key={i}
                        size={isValidSize(recommendedSize) ? sizesMap[recommendedSize] : undefined}
                        fit={isValidFit(recommendedFit) ? fitsMap[recommendedFit] : undefined}
                        sizeLabel={size}
                        fitLabel={sizeType}
                      />
                    </div>
                  )
                )
              })}
            </AccordionSizeRecContainer>
          </Accordion.Body>
        </Accordion.Section>
      </Accordion>
    </AccordionContainer>
  )
}
