'use client'

import { ComponentProps } from 'react'
import styled from 'styled-components'

export enum RotateClockwise {
  Quarter = '90deg',
  Half = '180deg',
  ThreeQuarters = '270deg',
  QuarterReverse = '-90deg',
}

export const StyledIcon = styled.svg<{
  $fill?: string
  $rotateClockwise?: RotateClockwise
  $transition?: string
}>(({ $fill, $rotateClockwise, $transition }) => ({
  fill: $fill ?? 'currentColor',
  transform: $rotateClockwise && `rotateZ(${$rotateClockwise})`,
  transition: $transition && `transform ${$transition}`,
}))

export type IProps = {
  transition?: string
  fill?: string
  rotateClockwise?: RotateClockwise
} & ComponentProps<'svg'>

export const BaseIcon = ({ fill, rotateClockwise, transition, ...rest }: IProps) => {
  return (
    <StyledIcon
      $fill={fill}
      $rotateClockwise={rotateClockwise}
      $transition={transition}
      {...rest}
    />
  )
}
