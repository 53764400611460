import { useLocalization } from '../../../app/_providers/LocalizationProvider.client'

import { IdMeSection } from './IdMeSection'
import { StudentBeansSection } from './StudentBeansSection'

export const StudentDiscount = () => {
  const { region, isUSRegion, languageGroup } = useLocalization()

  if (isUSRegion) {
    return <IdMeSection />
  } else {
    return <StudentBeansSection region={region.id} language={languageGroup} />
  }
}
