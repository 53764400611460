import { ComponentProps, FC } from 'react'

import { Accordion, Hamburger, Media, SelectMark } from '@syconium/little-miss-figgy'

import HeaderStateContainer from './context'
import {
  MenuContentLayout,
  MenuLayout,
  MenuTitleLayout,
  NavButtonLayout,
  NavCloseButtonLayout,
  NavLayout,
  NavMobileBottomLayout,
  NavSlideOutOverlay,
} from './styles'

interface HeaderMenuProps extends ComponentProps<typeof Accordion.Section> {
  className?: string
}

export const Menu: FC<HeaderMenuProps> = ({ className, disabled, index, children }) => {
  const desktopLayout = <MenuLayout className={className}>{children}</MenuLayout>
  const mobileLayout = (
    <Accordion.Section index={index} disabled={disabled}>
      {desktopLayout}
    </Accordion.Section>
  )

  return (
    <>
      <Media lessThan='md'>{mobileLayout}</Media>
      <Media greaterThanOrEqual='md'>{desktopLayout}</Media>
    </>
  )
}

export const MenuContent: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const desktopLayout = <MenuContentLayout>{children}</MenuContentLayout>
  const mobileLayout = <Accordion.Body>{desktopLayout}</Accordion.Body>

  return (
    <>
      <Media lessThan='md'>{mobileLayout}</Media>
      <Media greaterThanOrEqual='md'>{desktopLayout}</Media>
    </>
  )
}

export const MenuTitle: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const desktopLayout = <MenuTitleLayout>{children}</MenuTitleLayout>
  const mobileLayout = <Accordion.Header>{desktopLayout}</Accordion.Header>

  return (
    <>
      <Media lessThan='md'>{mobileLayout}</Media>
      <Media greaterThanOrEqual='md'>{desktopLayout}</Media>
    </>
  )
}

export const Nav: React.FC<ComponentProps<typeof Accordion>> = ({
  variant,
  ariaLabel,
  children,
}) => {
  const { isToggled, setToggled } = HeaderStateContainer.useContainer()
  const toggle = () => setToggled(!isToggled)

  const closeOnLinkClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if ((event.target as HTMLElement).tagName === 'A') setToggled(false)
  }

  const desktopLayout = (
    <>
      <NavLayout aria-expanded={isToggled} onClick={closeOnLinkClick}>
        <NavCloseButtonLayout onClick={toggle} disabled={!isToggled} aria-label='Close Navigation'>
          <SelectMark />
        </NavCloseButtonLayout>
        {children}
      </NavLayout>
      <NavSlideOutOverlay onClick={toggle} aria-expanded={isToggled} />
      <NavButtonLayout onClick={toggle} aria-label='Navigation menu'>
        <Hamburger />
      </NavButtonLayout>
    </>
  )

  const mobileLayout = (
    <Accordion multiOpen variant={variant} ariaLabel={ariaLabel} icon={'carat'}>
      {desktopLayout}
    </Accordion>
  )

  return (
    <>
      <Media lessThan='md'>{mobileLayout}</Media>
      <Media greaterThanOrEqual='md'>{desktopLayout}</Media>
    </>
  )
}

export const NavMobileBottom: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return <NavMobileBottomLayout>{children}</NavMobileBottomLayout>
}

export const Authorized: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { isAuthorized } = HeaderStateContainer.useContainer()
  return isAuthorized ? <>{children}</> : <></>
}

export const Unauthorized: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { isAuthorized } = HeaderStateContainer.useContainer()
  return isAuthorized ? <></> : <>{children}</>
}
