import { useCallback, useState } from 'react'

import { useScrollDirection } from '@syconium/little-miss-figgy'

/**
 * Determine if the navigation header should be visible or not.
 *
 * @returns Whether the navigation header should be displayed based on scroll direction. `forceVisibleState` will allow you to override the current visibility until scroll direction changes.
 */
export function useNavVisibility() {
  // `forcedState` allows us to override the current visibility state until the next change in scroll direction.
  const [forcedState, setForcedState] = useState<boolean | undefined>(undefined)

  // Reset forced state when scroll direction changes
  const handleScrollDirectionChange = useCallback(() => setForcedState(undefined), [])
  const scrollDirection = useScrollDirection({ onChange: handleScrollDirectionChange })

  return {
    isVisible: forcedState ?? scrollDirection !== 'down',
    forceVisibleState: setForcedState,
  }
}
