import * as React from 'react'

import { BaseIcon, IProps } from '../../BaseIcon'

interface Props extends IProps {
  viewBox?: string
}

export const ScrubTop: React.FC<Props> = ({
  height = '40px',
  width = '40px',
  viewBox = '0 0 40 40',
  ...rest
}) => {
  return (
    <BaseIcon height={height} width={width} viewBox={viewBox} {...rest}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20 40.4873C31.0457 40.4873 40 31.533 40 20.4873C40 9.44161 31.0457 0.487305 20 0.487305C8.9543 0.487305 0 9.44161 0 20.4873C0 31.533 8.9543 40.4873 20 40.4873ZM26 28.4873V17.4873H30V13.1387L23.144 11.4873L20.8682 15.4686C20.4842 16.1402 19.5158 16.1402 19.1318 15.4686L16.856 11.4873L10 13.1387V17.4873H14V28.4873H26Z'
      />
    </BaseIcon>
  )
}
