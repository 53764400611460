import { CookieSetOptions } from 'universal-cookie'

type CookieInformation = {
  key: string
  options: CookieSetOptions
}

const figsCookieKeys = {
  discountCode: {
    key: 'figs-discount-code',
    options: {
      path: '/',
      secure: true,
      httpOnly: false,
      sameSite: 'strict',
      maxAge: 2419200, // 4 weeks
    },
  },
  authToken: {
    key: 'figs-auth-token',
    options: {
      path: '/',
      secure: true,
      httpOnly: false,
      sameSite: 'strict',
      maxAge: 15600000, // .5 years
    },
  },
  authRefreshToken: {
    key: 'figs-refresh-token',
    options: {
      path: '/',
      secure: true,
      httpOnly: false,
      sameSite: 'strict',
      maxAge: 15600000, // .5 years
    },
  },
  shopifyCustomerToken: {
    key: 'figs-customer-access-token',
    options: {
      path: '/',
      secure: true,
      httpOnly: false,
      sameSite: 'strict',
      maxAge: 15600000, // .5 years
    },
  },
  stylePreference: {
    key: 'navGender',
    options: {
      path: '/',
      secure: true,
      httpOnly: false,
      sameSite: 'strict',
      maxAge: 31200000, // 1 year
    },
  },
  promoBarDismissed: {
    key: 'figs-promo-bar-dismissed',
    options: {
      path: '/',
      secure: true,
      httpOnly: false,
      sameSite: 'strict',
      maxAge: 31200000, // 1 year
    },
  },
  sessionId: {
    key: 'figs-session-id',
    options: {
      path: '/',
      secure: true,
      httpOnly: false,
      sameSite: 'strict',
      maxAge: 604800, // 7 days (TODO: Change to 30 minutes after currently running LD experiments finish up)
    },
  },
  visitorId: {
    key: 'figs-visitor-id',
    options: {
      path: '/',
      secure: true,
      httpOnly: false,
      sameSite: 'strict',
      maxAge: 34164000, // 13 months (matches Heap duration, also Chrome caps at 400 days)
    },
  },
  contentMode: {
    key: 'figs-content',
    options: {
      path: '/',
      secure: true,
      httpOnly: false,
      sameSite: 'strict',
      maxAge: 10, // 10 seconds
    },
  },
  productData: {
    key: 'figs-product-data',
    options: {
      path: '/',
      secure: true,
      httpOnly: false,
      sameSite: 'strict',
      maxAge: 10, // 10 seconds
    },
  },
  initialSessionIndicator: {
    key: 'figs-initial-session',
    options: {
      path: '/',
      secure: true,
      httpOnly: false,
      sameSite: 'strict',
      maxAge: 3600, // 1 hour
    },
  },
  priorPageViewIndicator: {
    key: 'figs-prior-pageview',
    options: {
      path: '/',
      secure: true,
      httpOnly: false,
      sameSite: 'strict',
      maxAge: 31200000, // 1 year
    },
  },
  region: {
    key: 'x-figs-shop-region',
    options: {
      path: '/',
      secure: true,
      httpOnly: false,
      sameSite: 'strict',
      maxAge: 31200000, // 1 year
    },
  },
  locale: {
    key: 'x-figs-shop-locale',
    options: {
      path: '/',
      secure: true,
      httpOnly: false,
      sameSite: 'strict',
      maxAge: 31200000, // 1 year
    },
  },
  currency: {
    key: 'x-figs-shop-currency',
    options: {
      path: '/',
      secure: true,
      httpOnly: false,
      sameSite: 'strict',
      maxAge: 31200000, // 1 year
    },
  },
  shop: {
    key: 'x-figs-shop-name',
    options: {
      path: '/',
      secure: true,
      httpOnly: false,
      sameSite: 'strict',
      maxAge: 31200000, // 1 year
    },
  },
  menSizeRec: {
    key: 'figs-men-size-rec-id',
    options: {
      path: '/',
      secure: true,
      httpOnly: false,
      sameSite: 'strict',
      maxAge: 31200000, // 1 year
    },
  },
  womenSizeRec: {
    key: 'figs-women-size-rec-id',
    options: {
      path: '/',
      secure: true,
      httpOnly: false,
      sameSite: 'strict',
      maxAge: 31200000, // 1 year
    },
  },
} as const satisfies Record<PropertyKey, CookieInformation>

const externalCookieKeys = {
  facebook: {
    key: '_fbc',
    options: {},
  },
  oneTrustConsents: {
    key: 'OptanonConsent', // OneTrust cookie that contains info about cookie consents
    options: {},
  },
} as const satisfies Record<PropertyKey, CookieInformation>

export const cookieKeys = {
  ...figsCookieKeys,
  ...externalCookieKeys,
} as const satisfies Record<PropertyKey, CookieInformation>
