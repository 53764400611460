import Link from 'next/link'
import * as React from 'react'

import { ProgressiveAsset } from '@syconium/little-miss-figgy'
import { BadgesSection } from '@syconium/magnolia/src/components/pages/products/ProductPage/ProductHighlights/BadgesSection'
import { trackEvent } from '@syconium/magnolia/src/lib/analytics'
import { GtmVariant } from '@syconium/magnolia/src/lib/hooks/useGoogleTagManager'

import { useLocalization } from '../../../../../app/_providers/LocalizationProvider.client'
import { CartItem } from '../../../../../types/figs'
import { useFixturesContext } from '../../../../containers/fixtures'
import { CartItemProperties } from '../CartItemProperties'

import { Body, Details, FitDescription, ImagePlaceholder, OptionsText, Title, Top } from './styles'

export const Template: React.FC<{
  cartItem: CartItem
  eventCategory: 'minicart' | 'cart'
  propertiesToDisplay: CartItem['properties']
  pushProductClick(data: { variant: GtmVariant; list?: string; position?: number }): void
  toggleMiniCartSlideOut?: () => void
}> = ({
  cartItem: {
    color,
    effectivePrice,
    finalSale,
    fit,
    fullPrice,
    externalParentId,
    image,
    productGroupHandle,
    productType,
    properties,
    size,
    sku,
    title,
    variantId,
  },
  eventCategory,
  propertiesToDisplay,
  pushProductClick,
  toggleMiniCartSlideOut = () => ({}),
}) => {
  const { region } = useLocalization()
  const { cart: miniCartCopy } = useFixturesContext()

  const linkQueryParams: string = color ? `?color=${color}` : ''
  const linkSuffix: string | undefined =
    productGroupHandle && `${productGroupHandle}${linkQueryParams}`

  // pull out the property for final sale because there is a separate badge with different formatting
  // for final sale than other properties, but we still want the line item prop for the checkout page
  const { [miniCartCopy.finalSale]: finalSaleProp, ...otherPropertiesToDisplay } =
    propertiesToDisplay

  //is the product itself finalSale (socks) or is the kit marked as final Sale
  const isFinalSaleBadgeVisible = finalSale || finalSaleProp

  return (
    <Body data-testid='cart-kit-item' data-cart-item-id={properties._setUid}>
      <Top>
        <Link
          href={linkSuffix ? `/products/${linkSuffix}` : '/'}
          onClick={() => {
            pushProductClick({
              variant: {
                category: productType,
                externalParentId: externalParentId || '',
                currencyType: region.currency,
                discountPrice: effectivePrice,
                handle: productGroupHandle,
                price: fullPrice,
                shopifyId: variantId,
                sku,
              },
              list: `${eventCategory}-cart`,
            })
            toggleMiniCartSlideOut()
          }}
          {...trackEvent({
            category: 'minicart 2.0',
            action: 'click item thumbnail',
            label: linkSuffix,
          })}
        >
          {image?.url ? (
            <ProgressiveAsset.Image src={image.url} alt={title} />
          ) : (
            <ImagePlaceholder />
          )}
        </Link>

        <Details>
          <div>
            <Title>
              {title} {productType}
            </Title>

            <OptionsText>
              {size}
              {fit && <FitDescription>&nbsp;• {fit}</FitDescription>}
              &nbsp;• {color}
            </OptionsText>

            <CartItemProperties properties={otherPropertiesToDisplay} />

            {isFinalSaleBadgeVisible && <BadgesSection badges={[miniCartCopy.finalSale]} />}
          </div>
        </Details>
      </Top>
    </Body>
  )
}
