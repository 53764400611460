import { FC, KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import FocusLock from 'react-focus-lock'
import LazyHydrate from 'react-lazy-hydration'

import { Animate, Minus as MinusIcon, SelectMark } from '@syconium/little-miss-figgy'
import { trackEvent } from '@syconium/magnolia/src/lib/analytics'
import {
  formatPrettyCurrency,
  intlPrettyCurrency,
  isStandardEmbroideryItem,
} from '@syconium/magnolia/src/lib/utils'

import { reportClientError } from '../../../app/_components/chrome/scripts/DataDogRumScript'
import { useLocalization } from '../../../app/_providers/LocalizationProvider.client'
import { useFeatureFlag } from '../../../containers/ExperimentationContainer'
import { CartContainer } from '../../containers/cart'
import { useFixturesContext } from '../../containers/fixtures'
import { PortalConfigContainer } from '../../containers/PortalConfigContainer'
import { KitGroup } from '../Cart'
import {
  CheckoutButtonWrapper,
  EmbroideryWarningContainer,
  EmptyCartMessage,
  Header,
  ItemCount,
  KeepShopping,
  KeepShoppingContainer,
  KeepShoppingLink,
  MiniCartMessage,
  Title,
  TotalSection,
} from '../Cart/styles'
import { groupKitItems, sortCartItems } from '../Cart/utils'
import { CtaButton } from '../CtaButton'
import { EmbroideryDisclosure } from '../EmbroideryDisclosure'
import { IntlShippingAndTaxMsg } from '../IntlShippingAndTaxMsg'

import { FreeShippingSection } from './FreeShipping'
import { MiniCartItem } from './MiniCartItem'
import {
  CartItemsWrapper,
  CheckoutErrorMessage,
  CloseButton,
  Container,
  IntlMsgWrap,
  PortalDiscountText,
  TotalAndCheckout,
  TotalLineItems,
} from './styles'
import { TotalLine } from './TotalLine'
import { Upsells } from './Upsells'

/**
 * Temporary addition to support Product Bundle Discounts for the Ribbed Longsleeve Underscrub.
 * @deprecated do not use outside of temporary Product Bundle Discounts work.
 * @todo remove this after we remove this feature.
 */
const rlsusProductGroupHandle = 'womens-ribbed-longsleeve-underscrub' as const

export const MiniCart: FC<{
  isHidden: boolean
  isRevealed: boolean
  toggleIsRevealed(): void
  titleId?: string
}> = ({ isHidden, isRevealed, toggleIsRevealed, titleId = 'minicart-title' }) => {
  const {
    cart,
    status: cartStatus,
    proceedToCheckout,
    removeItems,
    updateItemsQuantity,
  } = CartContainer.useContainer()

  const { cart: miniCartCopy, miniCartMessage } = useFixturesContext()
  const { locale, region } = useLocalization()
  const { portal, stipend } = PortalConfigContainer.useContainer()

  const [isCheckoutInFlight, setIsCheckoutInFlight] = useState<boolean>(false)
  const [checkoutFailed, setCheckoutFailed] = useState<boolean>(false)

  const cartItems = Object.values(cart.items)

  const kitGroups = groupKitItems(cartItems)

  const containerRef = useRef<HTMLDivElement>(null)
  const closeButtonRef = useRef<HTMLButtonElement>(null)

  const formattedPrice = intlPrettyCurrency({
    cents: cart.totalPrice,
    region: region,
    locale: locale,
    explicitFormat: true,
  })

  useEffect(() => {
    if (!isRevealed) {
      setCheckoutFailed(false)
    }
  }, [isRevealed])

  const handleCheckoutClick = useCallback(async () => {
    try {
      setIsCheckoutInFlight(true)
      setCheckoutFailed(false)
      await proceedToCheckout({})
      setIsCheckoutInFlight(false)
    } catch (error) {
      setCheckoutFailed(true)
      setIsCheckoutInFlight(false)
      reportClientError({
        error,
        context: {
          scope: 'MiniCart',
        },
      })
    }
  }, [proceedToCheckout])

  const manageFocusOnMiniCart = useCallback((): void => {
    if (isRevealed && containerRef && containerRef.current) {
      containerRef.current.focus()
    } else if (
      !isRevealed &&
      containerRef &&
      containerRef.current &&
      closeButtonRef &&
      closeButtonRef.current
    ) {
      containerRef.current.blur()
      closeButtonRef.current.blur()
    }
  }, [isRevealed, containerRef, closeButtonRef])

  useEffect(() => {
    manageFocusOnMiniCart()
  }, [manageFocusOnMiniCart])

  function closeMiniCartOnEscapeKey(event: KeyboardEvent<HTMLDivElement>): void {
    if (event.key === 'Escape') {
      toggleIsRevealed()
    }
  }

  const doesCartContainAStandardEmbroideryItem: boolean = Object.values(cart?.items || {}).some(
    item => isStandardEmbroideryItem(item)
  )

  const checkoutButton: JSX.Element = (
    <CtaButton
      allCaps={true}
      block
      data-testid='checkout-button'
      disabled={cartItems.length === 0 || isCheckoutInFlight}
      isProcessing={isCheckoutInFlight}
      onClick={handleCheckoutClick}
      {...trackEvent({
        category: 'minicart',
        action: 'checkout',
        label: miniCartCopy.checkoutCTA,
        value: cart.totalPrice,
      })}
    >
      {miniCartCopy.checkoutCTA}
    </CtaButton>
  )

  const hasPortalItemsSelected = useMemo(() => {
    return cartItems.filter(item => item.isPortalColor).length > 0
  }, [cartItems])

  /**
   * Start :: Temporary addition to support Product Bundle Discounts for the Ribbed Longsleeve Underscrub.
   * @deprecated do not use outside of temporary Product Bundle Discounts work.
   * @todo remove this after we remove this feature.
   */
  const hasEnoughRLSUS = useMemo(() => {
    const rlsusCount = cartItems.reduce((accumulator, current) => {
      if (current.productGroupHandle === rlsusProductGroupHandle) {
        accumulator += current.quantity
      }
      return accumulator
    }, 0)
    return rlsusCount >= 3
  }, [cartItems])

  const productBundleDiscountCopyFlag = useFeatureFlag({
    key: 'enable-product-bundle-discount-copy-text',
    defaultVariant: 'hide-discount-copy',
    ifAccessedPriorToDecisionInitialization: 'return-null-while-pending',
    skip: !hasEnoughRLSUS,
  })

  const showDiscountAtCheckoutCopy = useMemo(() => {
    return productBundleDiscountCopyFlag === 'show-discount-copy'
  }, [productBundleDiscountCopyFlag])

  // End :: Temporary addition to support Product Bundle Discounts for the Ribbed Longsleeve Underscrub.

  const hasStipend = portal && stipend !== null

  return (
    <Container
      role='dialog'
      aria-labelledby={titleId}
      aria-hidden={isHidden}
      hidden={isHidden}
      isRevealed={isRevealed}
      ref={containerRef}
      tabIndex={0}
      onKeyDown={closeMiniCartOnEscapeKey}
    >
      <FocusLock>
        <Header>
          <CloseButton
            aria-label={miniCartCopy.close}
            onClick={() => {
              toggleIsRevealed()
            }}
            ref={closeButtonRef}
            {...trackEvent({ category: 'minicart', action: 'close' })}
          >
            <SelectMark height='16px' stroke='currentColor' width='16px' />
          </CloseButton>

          <Title id={titleId} asTag='h2' textTransform='uppercase'>
            {miniCartCopy.title}
          </Title>
          {cart.itemCount > 0 ? (
            <>
              <MinusIcon stroke='currentColor' />
              <ItemCount>{cart.itemCount}</ItemCount>
            </>
          ) : null}
        </Header>
        <FreeShippingSection cartTotal={cart.totalPrice} />
        {cart && Object.keys(cart.items).length > 0 ? (
          <CartItemsWrapper>
            {kitGroups.map(group => {
              const key = group.map(o => o.key).join('')
              return (
                <KitGroup
                  key={key}
                  cartItems={group}
                  eventCategory='minicart'
                  embroideryLabel={miniCartCopy.embroideryItemLabel}
                  toggleMiniCartSlideOut={toggleIsRevealed}
                  cartIsUpdating={cartStatus === 'pending'}
                  decrementQuantity={() => {
                    updateItemsQuantity(
                      group.map(subItem => subItem.key),
                      -1
                    )
                  }}
                  incrementQuantity={() => {
                    updateItemsQuantity(
                      group.map(subItem => subItem.key),
                      1
                    )
                  }}
                  setQuantity={(newQuantity: number) => {
                    updateItemsQuantity(
                      group.map(subItem => subItem.key),
                      () => newQuantity
                    )
                  }}
                  removeAll={() => {
                    removeItems(group.map(subItem => subItem.key))
                  }}
                  removeItem={(key: string) => removeItems([key])}
                />
              )
            })}
            {sortCartItems(cartItems).map((item, index) => (
              <MiniCartItem
                key={`${item.key}-${index}`}
                item={item}
                index={index}
                toggleIsRevealed={toggleIsRevealed}
                showDiscountAtCheckoutCopy={
                  item.productGroupHandle === rlsusProductGroupHandle && showDiscountAtCheckoutCopy
                }
              />
            ))}
          </CartItemsWrapper>
        ) : (
          <Animate.FadeIn>
            <EmptyCartMessage>{miniCartCopy.emptyCartMessage}</EmptyCartMessage>
          </Animate.FadeIn>
        )}

        <KeepShoppingContainer>
          <KeepShopping>
            <KeepShoppingLink
              as='button'
              onClick={toggleIsRevealed}
              aria-label='Close cart and keep shopping'
              {...trackEvent({
                category: 'minicart',
                action: 'keep shopping',
                label: miniCartCopy.keepShopping,
              })}
            >
              {miniCartCopy.keepShopping}
            </KeepShoppingLink>
          </KeepShopping>
        </KeepShoppingContainer>
        <TotalAndCheckout>
          {cart && (
            <TotalSection>
              <EmbroideryWarningContainer>
                {doesCartContainAStandardEmbroideryItem && (
                  <EmbroideryDisclosure style={{ marginBottom: '20px' }} />
                )}
              </EmbroideryWarningContainer>
              {miniCartMessage && <MiniCartMessage>{miniCartMessage}</MiniCartMessage>}
              <TotalLineItems>
                {hasStipend && (
                  <TotalLine
                    label={miniCartCopy.stipendBalanceLabel}
                    price={formatPrettyCurrency(stipend * 100)}
                    {...trackEvent({
                      category: 'minicart',
                      action: 'snapshot',
                      label: 'stipend balance',
                      value: stipend,
                    })}
                  />
                )}
                <TotalLine
                  label={miniCartCopy.totalLabel}
                  price={formattedPrice}
                  loading={cartStatus === 'pending'}
                  {...trackEvent({
                    category: 'minicart',
                    action: 'snapshot',
                    label: 'total',
                    value: cart.totalPrice,
                  })}
                />

                {hasPortalItemsSelected && !!portal?.discountPercentage && (
                  <PortalDiscountText>
                    {portal?.name} {portal?.discountPercentage}
                    {miniCartCopy.portalDiscountMessage}
                  </PortalDiscountText>
                )}
              </TotalLineItems>
              <IntlMsgWrap>
                <IntlShippingAndTaxMsg />
              </IntlMsgWrap>
            </TotalSection>
          )}

          {cart && (
            <CheckoutButtonWrapper>
              {checkoutButton}
              {checkoutFailed && (
                <CheckoutErrorMessage>{miniCartCopy.checkoutErrorMessage}</CheckoutErrorMessage>
              )}
            </CheckoutButtonWrapper>
          )}
        </TotalAndCheckout>
        {!isHidden && (
          <LazyHydrate whenVisible>
            <Upsells />
          </LazyHydrate>
        )}
      </FocusLock>
    </Container>
  )
}
