import { useLocalization } from '../../../../../app/_providers/LocalizationProvider.client'
import { useFixturesContext } from '../../../../containers/fixtures'

export function useViewModel() {
  const { locale } = useLocalization()

  const {
    collections: {
      productGroupTile: { valueSignalLabel },
    },
  } = useFixturesContext()

  return { locale, valueSignalLabel }
}
