import * as React from 'react'

import { BaseIcon, IProps } from '../../BaseIcon'

interface Props extends IProps {
  viewBox?: string
}

export const Outerwear: React.FC<Props> = ({
  height = '40px',
  width = '40px',
  viewBox = '0 0 40 40',
  ...rest
}) => {
  return (
    <BaseIcon height={height} width={width} viewBox={viewBox} {...rest}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20 40.4873C31.0457 40.4873 40 31.533 40 20.4873C40 9.44161 31.0457 0.487305 20 0.487305C8.9543 0.487305 0 9.44161 0 20.4873C0 31.533 8.9543 40.4873 20 40.4873ZM15 11.4873H19V16.6769V30.4873H14.8571V20.8125C14.8571 20.593 14.7344 20.3992 14.5546 20.3346C14.3284 20.2534 14.0886 20.4015 14.019 20.6654L12.2857 28.4873H8L11.6802 15.4888C12.0431 14.2894 13.0148 13.4873 14.105 13.4873H15V11.4873ZM21 30.4873H25.1429V20.8125C25.1429 20.593 25.2656 20.3992 25.4454 20.3346C25.6716 20.2534 25.9114 20.4015 25.981 20.6654L27.7143 28.4873H32L28.3198 15.4888C27.9569 14.2894 26.9852 13.4873 25.895 13.4873H25V11.4873H21V16.6769V18.4873V30.4873Z'
      />
    </BaseIcon>
  )
}
