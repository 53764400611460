import React from 'react'

import { Search as SearchIcon } from '@syconium/little-miss-figgy'
import { useLocalization } from '@syconium/magnolia/src/app/_providers/LocalizationProvider.client'
import { useFixturesContext } from '@syconium/magnolia/src/brunswick/containers/fixtures'
import { useSearchOverlayContext } from '@syconium/magnolia/src/containers/SearchOverlayContext'
import { NavTab } from '@syconium/magnolia/src/types/graphql'

import { SearchContainer, SearchText } from './SearchBar.styles'

const Search = () => {
  const { languageGroup } = useLocalization()
  const {
    search: { inputPlaceholder },
  } = useFixturesContext()
  if (languageGroup !== 'en') return null

  return (
    <SearchContainer>
      <SearchIcon />
      <SearchText>{inputPlaceholder}</SearchText>
    </SearchContainer>
  )
}

export function useSearchTab(): NavTab {
  const { shouldDisplaySearchOverlay, toggleSearchOverlay } = useSearchOverlayContext()
  const {
    search: { searchButtonAriaLabelClose, searchButtonAriaLabelOpen },
  } = useFixturesContext()

  const searchTab: NavTab = {
    ariaLabel: shouldDisplaySearchOverlay ? searchButtonAriaLabelClose : searchButtonAriaLabelOpen,
    href: null,
    id: 'search',
    onClickish: () => {
      if (shouldDisplaySearchOverlay) {
        if (document.activeElement instanceof HTMLElement) document.activeElement.blur()
      }
      toggleSearchOverlay()
    },
    render: () => !shouldDisplaySearchOverlay && <Search />,
    sections: [],
    text: '',
    textColor: null,
    wrapperElement: 'button',
  }

  return searchTab
}
