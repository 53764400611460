import styled, { css } from 'styled-components'

import {
  ResponsiveImage,
  TypeStyle,
  focusOutline,
  fromMd,
  timingSlower,
  untilMd,
  withHoverSupport,
} from '@syconium/little-miss-figgy'
import { StyledButton } from '@syconium/little-miss-figgy/dist/components/Button'

import { BasePageSection } from '../../../brunswick/components/page-sections/styles'
import { SliderTile } from '../../../brunswick/components/TileLayout/styles'
import { trackEvent } from '../../../lib/analytics'

export const BackgroundColor = styled.div<{ backgroundColor: string }>`
  position: absolute;
  inset: 0;
  background-color: ${o => o.backgroundColor};
  z-index: -1;
`

export const BackgroundImage = styled(ResponsiveImage)`
  position: absolute;
  inset: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
`

export const Wrapper = styled(BasePageSection)<{
  isFullBleedDesktop?: boolean
  hasVerticalMargin?: boolean
}>`
  position: relative;
  width: 100%;
`

const expandedToggleHeadlineIconCss = css`
  background-color: ${o => o.theme.color.textOverlay.transparent.content.on.fill};

  &::before {
    background-color: ${o => o.theme.color.textOverlay.transparent.content.on.content};
  }

  &::after {
    background-color: transparent;
  }
`

export const ToggleHeadlineIcon = styled.div`
  position: relative;
  min-width: 24px;
  height: 24px;
  background-color: ${o => o.theme.color.text.highContrast.on.dark};
  border-radius: 50%;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 14px;
    height: 1px;
    background-color: ${o => o.theme.color.text.highContrast.on.light};
    transform: translate(-50%, -50%);
    box-sizing: border-box;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(90deg);
    transition: background-color ${timingSlower} ease-in-out;
  }

  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1);
`

export const ContentWrapper = styled.div`
  position: relative;
  ${fromMd} {
    padding: 96px 40px 0 40px;
  }
  padding: 40px 16px 0px 16px;
  display: flex;
  flex-direction: column;
  z-index: 0;
`

export const Content = styled.div`
  position: relative;
  z-index: 1;
`

export const CarouselWrapper = styled.div<{ hasBackground: boolean }>`
  width: calc(100% + 32px);
  margin: 0px -16px;
  ${fromMd} {
    width: calc(100% + 80px);
    margin: 0px -40px;
  }
  padding-top: 40px;

  ${o => (o.hasBackground ? 'padding-bottom: 40px' : null)};
  ${fromMd} {
    padding-top: 80px;
    ${o => (o.hasBackground ? 'padding-bottom: 80px' : null)};
  }
  display: flex;
  align-items: center;
  align-self: center;
  box-sizing: border-box;

  ${SliderTile} {
    padding: 0px;
    margin: 0px 8px;
    ${untilMd} {
      &:first-of-type {
        margin-left: 16px;
      }
      &:last-of-type {
        margin-right: 16px;
      }
    }
    ${fromMd} {
      &:first-of-type {
        margin-left: 40px;
      }
      &:last-of-type {
        margin-right: 40px;
      }
    }
    button {
      ${fromMd} {
        top: 24px;
        right: 22px;
      }
      top: 16px;
    }
  }
`

export const EditorialWrapper = styled.div`
  color: ${o => o.theme.color.text.primary.on.background};
  display: grid;
  flex-shrink: 0;
  grid-template-columns: 1fr;

  ${fromMd} {
    gap: 40px;
    grid-template-columns: 1fr 1fr;
  }
`

export const ActionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: ${o => o.theme.spacing(10)};
  height: fit-content;
  ${untilMd} {
    margin-top: ${o => o.theme.spacing(5)};
  }
  ${fromMd} {
    flex-direction: row;
    grid-row: 2;
    gap: ${o => o.theme.spacing(5)};
  }
  > * {
    flex-shrink: 0;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  grid-row: 2;
  gap: ${o => o.theme.spacing(5)};

  height: fit-content;
`

export const CtaButton = styled(StyledButton)`
  height: fit-content;
  width: fit-content;
  text-align: center;
`

export const ContentContainer = styled.div``

export const TileInformationHeadline = styled(
  ({
    expanded,
    onClick,
    className,
    children,
    analyticsContext,
  }: {
    expanded: boolean
    onClick: null | (() => void)
    className?: string
    children: React.ReactNode
    analyticsContext: {
      category: string
      action: string
      pageSectionName?: string
      pageSectionIndex: number
      correspondingAsset?: string
    }
  }) => {
    if (onClick) {
      return (
        <button
          className={className}
          onClick={onClick}
          aria-expanded={expanded}
          {...trackEvent({
            category: analyticsContext?.category,
            action: analyticsContext?.action,
            pageSectionName: analyticsContext?.pageSectionName,
            pageSectionIndex: analyticsContext?.pageSectionIndex,
            correspondingAsset: analyticsContext?.correspondingAsset,
          })}
        >
          {children}
        </button>
      )
    } else {
      return (
        <TypeStyle.BodyDefault tabIndex={0} asTag='h5' className={className}>
          {children}
        </TypeStyle.BodyDefault>
      )
    }
  }
)`
  ${focusOutline};
  display: flex;
  align-items: center;
  gap: 16px;
  height: 52px;
  text-align: left;
`

const expandedTileInformationHeadlineTextCss = css`
  color: ${o => o.theme.color.textOverlay.transparent.content.on.fill};
`

export const TileInformationHeadlineText = styled(TypeStyle.HeadlineExtraExtraSmall)`
  color: ${o => o.theme.color.text.highContrast.on.dark};
  text-transform: uppercase;
`

export const TileInformationDescription = styled(TypeStyle.HeadlineExtraExtraSmall)`
  color: ${o => o.theme.color.textOverlay.transparent.content.on.fill};
  text-align: left;
  padding-top: 16px;
`

export const TileInformationContent = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 24px;
`

const expandedTileInformationBackgroundCss = css`
  background-color: ${o => o.theme.color.textOverlay.transparent.fill};

  ${untilMd} {
    max-height: 100%;
    min-height: 100%;
    height: 100%;
  }
  ${fromMd} {
    max-height: 100%;
    min-height: 72px;
    backdrop-filter: blur(10px);
  }
`

const expandedCss = css`
  ${ToggleHeadlineIcon} {
    ${expandedToggleHeadlineIconCss}
  }
  ${TileInformationHeadlineText} {
    ${expandedTileInformationHeadlineTextCss}
  }
`

export const TileInformationBackground = styled.div<{ expanded: boolean }>`
  display: flex;
  position: absolute;
  flex-direction: column;
  width: 100%;
  max-height: 88px;
  min-height: 72px;
  bottom: 0;
  left: 0;
  background: transparent;
  backdrop-filter: none;
  z-index: 2;
  transition:
    background ${timingSlower} ease-in-out,
    min-height ${timingSlower} ease-in-out,
    max-height ${timingSlower} ease-in-out,
    backdrop-filter ${timingSlower} ease-in-out;

  ${o => (o.expanded ? expandedTileInformationBackgroundCss : null)};
  ${o => (o.expanded ? expandedCss : null)};
  ${withHoverSupport} {
    &:has(:focus-visible) {
      ${expandedTileInformationBackgroundCss};
      ${expandedCss};
    }
  }
`

export const ImageWrap = styled.figure<{
  aspectRatio: number
  roundCorners?: boolean
}>`
  display: block;
  position: relative;
  width: 100%;
  aspect-ratio: ${o => o.aspectRatio};
  overflow: hidden;

  & > img {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }
  ${withHoverSupport} {
    &:hover {
      ${TileInformationBackground} {
        ${expandedTileInformationBackgroundCss};
      }
      ${expandedCss};
    }
  }
`

export const TileContentWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  top: 0;
  padding: ${o => o.theme.spacing(4)};
  gap: ${o => o.theme.spacing(4)};
  width: 100%;
  box-sizing: border-box;
`

export const TileHeadline = styled(TypeStyle.HeadlineLarge)`
  color: ${o => o.theme.color.text.primary.on.background};
  text-align: left;
`

export const HeadlineWrapper = styled.div`
  padding-bottom: ${o => o.theme.spacing(5)};
  ${fromMd} {
    padding-bottom: ${o => o.theme.spacing(10)};
  }
`

export const OverlayColor = styled.div<{ overlayOpacity: number | null }>`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: ${o =>
    o.overlayOpacity === null ? 'transparent' : o.theme.color.fill.background};
  opacity: ${o => (o.overlayOpacity === null ? '1' : o.overlayOpacity)};
  box-sizing: border-box;
  top: 0;
  left: 0;
`
