import * as React from 'react'

import { BaseIcon, IProps } from '../BaseIcon'

export const Warning: React.FC<IProps> = ({
  height = '26px',
  width = '26px',
  fill = '#282828',
  ...rest
}) => {
  return (
    <BaseIcon
      height={height}
      width={width}
      viewBox='0 0 24 24'
      fill={'none'}
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M10.2635 3.03885L1.70987 18.0077C0.94798 19.341 1.91071 21 3.44636 21L20.5536 21C22.0893 21 23.052 19.341 22.2901 18.0077L13.7365 3.03885C12.9687 1.69522 11.0313 1.69522 10.2635 3.03885Z'
        stroke={fill}
        stroke-width='1.5'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.5 15L11 7L13 7L12.5 15L11.5 15Z'
        fill={fill}
      />
      <path fillRule='evenodd' clipRule='evenodd' d='M11 18L11 16L13 16L13 18L11 18Z' fill={fill} />
    </BaseIcon>
  )
}
