import styled from 'styled-components'

import { Carat, timingDefault } from '@syconium/little-miss-figgy'
import { StyledSummary as EPStyledSummary } from '@syconium/little-miss-figgy/dist/components/ExpansionPanel/styles'

import { Body as NavListSection } from '../NavListSection/styles'
import { ScrollWrap as NavTileSection } from '../NavTileSection/styles'

export const SectionBody = styled.div``

export const Body = styled.ul`
  ${NavListSection} {
    padding-top: 0;
    padding-inline-end: 24px;
    padding-bottom: 24px;
    padding-inline-start: 60px;
  }

  ${SectionBody} & {
    padding: 0 0 0 36px;
  }

  ${NavTileSection} {
    padding: 0 0 18px 24px;
  }
`

export const Section = styled.li<{
  isOpen: boolean
  isOpenDisabled: boolean
  isFooterVariant: boolean
}>`
  background: ${o =>
    o.isFooterVariant ? 'transparent' : o.isOpen ? o.theme.color.deprecated.stoneLighter : 'white'};
  border-top: 1px solid
    ${o =>
      o.isFooterVariant
        ? o.theme.color.deprecated.obsidianLighter
        : o.theme.color.deprecated.stoneLight};
  transition: background-color ${timingDefault};

  ${EPStyledSummary} {
    cursor: ${o => (o.isOpenDisabled ? '' : 'pointer')};
  }
`

export const Header = styled.div<{
  isOpen: boolean
}>`
  border-color: ${o => o.theme.color.deprecated.stoneLight};
  padding: ${o =>
    o.isOpen
      ? `18px ${o.theme.dimensions.layout.sidePadding.md}px`
      : `10px ${o.theme.dimensions.layout.sidePadding.md}px`};
  transition: padding ${timingDefault};
`

// hardware accelerated wrapper to prevent icon from disappearing when too much open
// -- let’s not make translate3d(0,0,0) a habit. but these little icons are worth it
export const CaratIconWrapper = styled.div`
  transform: translate3d(0, 0, 0);
`

export const StyledCaret = styled(Carat)<{
  isExpanded: boolean
}>`
  height: ${o => (o.isExpanded ? '13px' : '15px')};
  margin-inline-end: ${o =>
    o.isExpanded
      ? o.theme.dimensions.layout.sidePadding.md + 1
      : o.theme.dimensions.layout.sidePadding.md}px;
  transform: rotate(90deg) ${o => (o.isExpanded ? 'rotateY(180deg)' : '')};
  transition: all ${timingDefault};
  width: ${o => (o.isExpanded ? '13px' : '15px')};
`

export const PlusIconWrapper = styled.div`
  margin-inline-end: ${o => o.theme.dimensions.layout.sidePadding.md}px;
`
