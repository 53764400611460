import React from 'react'

import { BaseIcon, IProps } from '../BaseIcon'

export const Exclamation: React.FC<IProps> = ({ height = '16px', width = '16px', ...rest }) => {
  return (
    <BaseIcon height={height} width={width} viewBox='0 0 16 16' {...rest}>
      <g fillRule='nonzero'>
        <path d='M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm0-1.5a6.5 6.5 0 1 1 0-13 6.5 6.5 0 0 1 0 13z' />
        <path d='M7.076 3.6h1.758l-.14 5.672H7.216L7.077 3.6zm.88 8.602c-.534 0-.956-.405-.956-.92 0-.516.422-.92.955-.92.533 0 .955.404.955.92 0 .515-.422.92-.955.92z' />
      </g>
    </BaseIcon>
  )
}
