import { initialize } from 'launchdarkly-js-client-sdk'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import { createContainer } from 'unstated-next'

import {
  createLaunchDarklyClientConfig,
  createLaunchDarklyContext,
} from '../../app/_config/Experimentation.config'
import { SessionAttributes } from '../../app/_config/Session.config'
import { useAuthentication } from '../../app/_providers/AuthenticationProvider.client'
import { useSession } from '../../app/_providers/SessionProvider.client'
import { HeapTrackContainer } from '../../lib/analytics/hooks/HeapTrackContainer'

const createClientForUser = ({
  account,
  sessionAttributes,
}: {
  account: ReturnType<typeof useAuthentication>['account']
  sessionAttributes: SessionAttributes
}) => {
  const sessionId = sessionAttributes.sessionId
  const visitorId = sessionAttributes.visitorId

  if (typeof window === 'undefined') return undefined
  if (!sessionId || !visitorId) return undefined
  if (!process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_API_KEY) return undefined

  const context = createLaunchDarklyContext({
    account,
    sessionAttributes: {
      ...sessionAttributes,
      sessionId,
      visitorId,
    },
  })

  const config = createLaunchDarklyClientConfig({ bootstrap: undefined })

  return initialize(process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_API_KEY, context, config)
}

const useExperimentationContainerImpl = () => {
  const router = useRouter()
  const { attributes: sessionAttributes } = useSession()
  const { account } = useAuthentication()

  // Purposefully do not make this reactive. It is just a stable and private data store for meta info for this container.
  const [_internals] = useState<{
    disabledFeatures: Record<string, true>
    synchronousFlagsDisabled: boolean
  }>(() => {
    return {
      disabledFeatures: {},
      synchronousFlagsDisabled: true,
    }
  })

  const [isClientReady, setIsClientReady] = useState(false)
  const client = useMemo(() => {
    return createClientForUser({
      account,
      sessionAttributes,
    })
  }, [account, sessionAttributes])

  // If the user interacts with things, enable the client to check new flags.
  useEffect(() => {
    if (_internals.synchronousFlagsDisabled && isClientReady) {
      const interactionCallback = () => {
        _internals.synchronousFlagsDisabled = false
      }
      router.events.on('routeChangeStart', interactionCallback)
      window.addEventListener('mousedown', interactionCallback)
      window.addEventListener('touchstart', interactionCallback)
      window.addEventListener('keydown', interactionCallback)
      return () => {
        window.removeEventListener('mousedown', interactionCallback)
        window.removeEventListener('touchstart', interactionCallback)
        window.removeEventListener('keydown', interactionCallback)
        router.events.off('routeChangeStart', interactionCallback)
      }
    } else {
      return
    }
  }, [_internals, isClientReady, router.events])

  useEffect(() => {
    client
      ?.waitUntilReady()
      .then(() => {
        setIsClientReady(true)
      })
      .catch(() => {})
  }, [_internals, client])

  return {
    client: isClientReady ? client : undefined,
    _internals,
  }
}

const ExperimentationContainer = createContainer(useExperimentationContainerImpl)
export const PagesDirectoryExperimentationContainerProvider = ExperimentationContainer.Provider

export function useFeatureFlag(options: {
  key: string
  defaultVariant: string
  ifAccessedPriorToDecisionInitialization: 'lock-pageview-to-defaultVariant'
  skip?: boolean
}): string

export function useFeatureFlag(options: {
  key: string
  defaultVariant: string
  ifAccessedPriorToDecisionInitialization: 'return-null-while-pending'
  skip?: boolean
}): null | string

export function useFeatureFlag({
  key,
  defaultVariant,
  ifAccessedPriorToDecisionInitialization,
  skip,
}: {
  key: string
  defaultVariant: string
  ifAccessedPriorToDecisionInitialization:
    | 'return-null-while-pending'
    | 'lock-pageview-to-defaultVariant'
  skip?: boolean
}) {
  const { client, _internals } = ExperimentationContainer.useContainer()
  const { sendHeapEvent } = HeapTrackContainer.useContainer()

  if (
    ifAccessedPriorToDecisionInitialization === 'lock-pageview-to-defaultVariant' &&
    _internals.synchronousFlagsDisabled
  ) {
    _internals.disabledFeatures[key] = true
  }

  if (_internals.disabledFeatures[key]) {
    return defaultVariant
  }

  if (skip === true) {
    return defaultVariant
  }

  if (!client) {
    if (ifAccessedPriorToDecisionInitialization === 'lock-pageview-to-defaultVariant') {
      return defaultVariant
    } else {
      return null
    }
  }

  const decision = `${client.variation(key, defaultVariant)}`

  sendHeapEvent({
    eventName: `launch-darkly-feature-flag-${key}`,
    props: {
      [`launch-darkly-feature-flag-${key}-decision`]: decision,
    },
  })

  return decision
}
