import { graphql } from '@syconium/magnolia/src/__generated__/graphql/shopify'

export const CREATE_CHECKOUT_WITH_LINE_ITEMS = graphql(`
  mutation CreateCheckoutWithLineItems(
    $allowPartialAddresses: Boolean
    $lineItems: [CheckoutLineItemInput!]!
    $shippingAddress: MailingAddressInput
    $customAttributes: [AttributeInput!]
    $country: CountryCode!
    $language: LanguageCode
  ) @inContext(country: $country, language: $language) {
    checkoutCreate(
      input: {
        allowPartialAddresses: $allowPartialAddresses
        lineItems: $lineItems
        shippingAddress: $shippingAddress
        customAttributes: $customAttributes
        buyerIdentity: { countryCode: $country }
      }
    ) {
      checkout {
        buyerIdentity {
          countryCode
        }
        id
        webUrl
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`)

export const FETCH_CHECKOUT = graphql(`
  query FetchCheckout($id: ID!) {
    node(id: $id) {
      ... on Checkout {
        completedAt
      }
    }
  }
`)

export const ASSOCIATE_CUSTOMER_TO_CHECKOUT = graphql(`
  mutation AssociateCustomerToCheckout($checkoutId: ID!, $customerAccessToken: String!) {
    checkoutCustomerAssociateV2(
      checkoutId: $checkoutId
      customerAccessToken: $customerAccessToken
    ) {
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`)
