import * as React from 'react'

import { BaseIcon, IProps } from '../../BaseIcon'

interface Props extends IProps {
  viewBox?: string
}

export const LabCoat: React.FC<Props> = ({
  height = '40px',
  width = '40px',
  viewBox = '0 0 40 40',
  ...rest
}) => {
  return (
    <BaseIcon height={height} width={width} viewBox={viewBox} {...rest}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M40 20.9231C40 31.9688 31.0457 40.9231 20 40.9231C8.9543 40.9231 0 31.9688 0 20.9231C0 9.8774 8.9543 0.923096 20 0.923096C31.0457 0.923096 40 9.8774 40 20.9231ZM21 12.9765L22.798 9.9231H26.6104C27.7515 9.9231 28.7685 10.7252 29.1483 11.9246L33 24.9231H28.5145L26.7005 17.1012C26.6277 16.8373 26.3767 16.6892 26.1399 16.7704C25.9799 16.8253 25.8631 16.9736 25.8317 17.1516L27 31.9231H21V12.9765ZM19 12.9765L17.202 9.9231H13.3896C12.2485 9.9231 11.2315 10.7252 10.8517 11.9246L7 24.9231H11.4855L13.2995 17.1012C13.3723 16.8373 13.6233 16.6892 13.8601 16.7704C14.0201 16.8253 14.1369 16.9736 14.1683 17.1516L13 31.9231H19V12.9765Z'
      />
    </BaseIcon>
  )
}
