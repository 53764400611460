import { gql } from '@syconium/magnolia/src/__generated__/graphql/catalog'

// account-services service
export const ACTIVATE_CUSTOMER = gql(`
  mutation ActivateCustomer($activationUrl: String!, $password: String!) {
    activateCustomer(activationUrl: $activationUrl, password: $password) {
      customerAccessToken
      refreshToken
      token
    }
  }
`)
