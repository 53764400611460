import { useCallback, useEffect, useRef, useState } from 'react'

import { useIsBrowser } from './useIsBrowser'

export type ScrollDirection = '' | 'up' | 'down'

type HistoryItem = { event: Event; y: number; t: number }

const historyLength = 32 // Ticks to keep in history.
const historyMaxAge = 512 // History data time-to-live (ms).
const thresholdPixels = 1 // Ignore moves smaller than this.

export function useScrollDirection({
  scrollingElement,
  onChange,
}: {
  scrollingElement?: HTMLElement | null
  onChange?: (dir: ScrollDirection) => void
} = {}): ScrollDirection {
  const currentScrollDirection = useRef<ScrollDirection>('')
  const [delayedScrollDirection, setDelayedScrollDirection] = useState<ScrollDirection>('')

  const isBrowser = useIsBrowser()

  const setScrollDirection = useCallback(
    (newScrollDirection: ScrollDirection) => {
      const changedDirection = currentScrollDirection.current !== newScrollDirection
      currentScrollDirection.current = newScrollDirection
      setDelayedScrollDirection(newScrollDirection)
      if (changedDirection) onChange?.(newScrollDirection)
    },
    [onChange]
  )

  const [element, eventEmitter] = scrollingElement
    ? [scrollingElement, scrollingElement]
    : isBrowser
    ? [window.document.scrollingElement, window]
    : [null, null]

  useEffect(() => {
    if (!element || !eventEmitter) return

    let eventHistory: HistoryItem[] | undefined = Array(historyLength)
    let pivotPoint: Omit<HistoryItem, 'event'> = { t: 0, y: 0 }

    const tick = (event: Event) => {
      const latestHistoryEntry = eventHistory[0]
      if (!latestHistoryEntry || event !== latestHistoryEntry.event) return

      const { t, y } = latestHistoryEntry

      if (y === 0) {
        setScrollDirection('')
        pivotPoint = { t, y }
        return
      }

      const furthest = currentScrollDirection.current === 'down' ? Math.max : Math.min

      // Are we continuing in the same direction?
      if (y === furthest(pivotPoint.y, y)) {
        // Update "high-water mark" for current direction
        pivotPoint = { t, y }
        return
      }
      // else we have backed off high-water mark

      // Apply max age to find current reference point
      const cutoffTime = t - historyMaxAge
      if (cutoffTime > pivotPoint.t) {
        pivotPoint.y = y
        eventHistory.filter(Boolean).forEach(({ y, t }) => {
          if (t > cutoffTime) pivotPoint.y = furthest(pivotPoint.y, y)
        })
      }

      // Have we exceeded threshold?
      if (Math.abs(y - pivotPoint.y) > thresholdPixels) {
        pivotPoint = { t, y }
        const newScrollDirection = currentScrollDirection.current === 'down' ? 'up' : 'down'
        setScrollDirection(newScrollDirection)
      }
    }

    const onScroll = (event: Event) => {
      // Do not consider it a scroll action when we lock scrolling for dialogs.
      if (document.body.getAttribute('data-dialog-prevent-body-scroll')) {
        return
      }

      // Apply bounds to handle rubber banding
      const yMax = element.scrollHeight - element.clientHeight
      let y = element.scrollTop
      y = Math.max(0, y)
      y = Math.min(yMax, y)

      // Insert history entry after removing last value to ensure array never exceeds our predefined length
      eventHistory.pop()
      eventHistory.unshift({ event, t: event.timeStamp, y })

      requestAnimationFrame(() => {
        tick(event)
      })
    }

    eventEmitter.addEventListener('scroll', onScroll, { passive: true })
    return () => {
      eventEmitter.removeEventListener('scroll', onScroll)
    }
  }, [setScrollDirection, element, eventEmitter])

  return delayedScrollDirection
}
