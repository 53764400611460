import React, { useEffect, useRef } from 'react'

import { NavSection } from '@syconium/magnolia/src/types/graphql'

import { renderNavSection } from '../renderNavSection'

import { Body } from './styles'

export const NavTray: React.FC<{
  onKeyDown?: React.KeyboardEventHandler<HTMLElement> | undefined
  onClickWithin?: (event: MouseEvent) => void
  sections: NavSection[]
}> = ({ onKeyDown, sections, onClickWithin }) => {
  const bodyRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const bodyElement = bodyRef.current
    if (!onClickWithin || !bodyElement) return

    bodyElement.addEventListener('click', onClickWithin)
    return () => {
      bodyElement.removeEventListener('click', onClickWithin)
    }
  }, [onClickWithin])

  return (
    <Body ref={bodyRef} onKeyDown={onKeyDown}>
      {sections.map((x, i) => renderNavSection(x, i))}
    </Body>
  )
}
