'use client'

import * as React from 'react'

import { BaseIcon, IProps } from '../BaseIcon'

export const Help: React.FC<IProps> = ({
  className = '',
  height = '20px',
  width = '20px',
  stroke,
}) => {
  return (
    <BaseIcon
      className={className}
      height={height}
      width={width}
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill={stroke || 'currentColor'}
        d='M9.926 0C4.444 0 0 4.444 0 9.926c0 5.483 4.444 9.927 9.926 9.927 5.483 0 9.927-4.444 9.927-9.927C19.853 4.444 15.409 0 9.926 0zm0 1.838a8.088 8.088 0 110 16.177 8.088 8.088 0 010-16.177zm1.828 9.133l-.033.016-.026.017c-.504.306-.824.84-.846 1.434l-.001.067H9.01c0-1.242.636-2.374 1.656-3.026l.124-.076.046-.026.018-.011.035-.022c.47-.308.768-.826.79-1.399V7.88a1.754 1.754 0 00-3.505-.058v.058H6.334a3.592 3.592 0 115.52 3.03l-.07.043-.031.02zm-.908 2.724v1.723H9.008v-1.723h1.838z'
      />
    </BaseIcon>
  )
}
