import React from 'react'

import { BaseIcon, IProps } from '../BaseIcon'

export const CircleCheckMark: React.FC<IProps> = ({ height = '16px', width = '16px', ...rest }) => {
  return (
    <BaseIcon height={height} width={width} viewBox='0 0 24 24' {...rest}>
      <g fillRule='evenodd'>
        <path
          d='M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12zm0-2C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10z'
          fillRule='nonzero'
        />
        <path
          fillRule='nonzero'
          d='M7.916 10.383l-1.414 1.414 4.1 4.101 7.496-7.495-1.414-1.414-6.081 6.08z'
        />
      </g>
    </BaseIcon>
  )
}
