'use client'

import { useCallback, useMemo, useState } from 'react'
import { createContainer } from 'unstated-next'

import { TokenAuthStorageSingleton } from '@syconium/iron-figs'

import { gql } from '../../__generated__/graphql/catalog'
import { QueryStatus } from '../../lib/hooks/types'
import { getItemFromLS, removeItemFromLS, removeUserKeyFromLS } from '../../lib/utils'
import {
  AccountAuthorization,
  convertCookiesToAccountAuthorization,
} from '../_config/Authentication.config'
import { cookieKeys } from '../_config/Cookies.config'

import { useCookies } from './CookiesProvider.client'
import { useNextMutation } from './GraphqlClientsProvider.client'

export type UseAuthenticationOutput = {
  account: AccountAuthorization | undefined
  isLoggedIn: boolean
  logout: (options: { destinationUrl?: string }) => void
  login: (credentials: { email: string; password: string; destinationUrl?: string }) => void
  loginMutationStatus: QueryStatus
}

const useAuthenticationImpl = (): UseAuthenticationOutput => {
  const [cookies, setCookie, removeCookie] = useCookies([
    cookieKeys.authToken.key,
    cookieKeys.authRefreshToken.key,
    cookieKeys.shopifyCustomerToken.key,
  ])

  const authCookie = cookies[cookieKeys.authToken.key]
  const authRefreshCookie = cookies[cookieKeys.authRefreshToken.key]

  // TODO: Deprecated read of local storage key... we should be able to delete that once the old AuthenticationContainer & TokenAuthStorageSingleton is choked out.
  const shopifyCustomerCookie =
    cookies['figs-customer-access-token'] ?? getItemFromLS('shop-token') ?? undefined

  const authenticationStatus = useMemo(() => {
    return convertCookiesToAccountAuthorization({
      authCookie,
      authRefreshCookie,
      shopifyCustomerCookie,
    })
  }, [authCookie, authRefreshCookie, shopifyCustomerCookie])

  const [loginStatus, setLoginStatus] = useState<QueryStatus>('idle')
  const [loginMutation] = useNextMutation(
    gql(`
      mutation LoginUser($email: String!, $password: String!) {
        loginCustomer(email: $email, password: $password) {
          customerAccessToken
          refreshToken
          token
        }
      }
  `)
  )

  const logout = useCallback<UseAuthenticationOutput['logout']>(
    ({ destinationUrl }) => {
      // TODO: Deprecated removal of local storage keys... we should be able to delete this once the old AuthenticationContainer & TokenAuthStorageSingleton is choked out.
      TokenAuthStorageSingleton.getInstance().clearTokens()
      removeUserKeyFromLS()
      removeItemFromLS('shop-token')

      // Clear our 3 auth cookies. This is all we should have to do really.
      removeCookie(cookieKeys.authToken.key, {
        path: '/',
      })
      removeCookie(cookieKeys.authRefreshToken.key, {
        path: '/',
      })
      removeCookie(cookieKeys.shopifyCustomerToken.key, {
        path: '/',
      })

      // It is important to do this hard refresh to make sure all 3rd parties are reset.
      window.location.href = destinationUrl ?? '/'
    },
    [removeCookie]
  )

  const login = useCallback<UseAuthenticationOutput['login']>(
    ({ email, password, destinationUrl }) => {
      if (loginStatus !== 'pending' && email && password) {
        setLoginStatus('pending')

        loginMutation({
          variables: { email, password },
        })
          .then(loginMutationResult => {
            if ((loginMutationResult.errors?.length ?? 0) > 0 || !loginMutationResult.data) {
              setLoginStatus('rejected')
            } else {
              const newAuthToken = loginMutationResult.data.loginCustomer.token
              const newRefreshToken = loginMutationResult.data.loginCustomer.refreshToken
              const newShopifyToken = loginMutationResult.data.loginCustomer.customerAccessToken

              if (!newAuthToken || !newRefreshToken || !newShopifyToken) {
                setLoginStatus('rejected')
              } else {
                // Set our 3 auth cookies.
                setCookie(
                  cookieKeys.authToken.key,
                  `Bearer ${newAuthToken}`,
                  cookieKeys.authToken.options
                )
                setCookie(
                  cookieKeys.authRefreshToken.key,
                  newRefreshToken,
                  cookieKeys.authRefreshToken.options
                )
                setCookie(
                  cookieKeys.shopifyCustomerToken.key,
                  newShopifyToken,
                  cookieKeys.shopifyCustomerToken.options
                )

                // It is important to do this hard refresh to make sure all 3rd parties are reset.
                window.location.href = destinationUrl ?? '/account'
              }
            }
          })
          .catch(_error => {
            setLoginStatus('rejected')
          })
      }
    },
    [loginMutation, loginStatus, setCookie]
  )

  return {
    account: authenticationStatus,
    isLoggedIn: !!authenticationStatus,
    logout,
    login,
    loginMutationStatus: loginStatus,
  }
}

const AuthenticationContainer = createContainer(useAuthenticationImpl)
export const AuthenticationProvider = AuthenticationContainer.Provider
export const useAuthentication = AuthenticationContainer.useContainer
