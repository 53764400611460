import { useCallback, useMemo, useState } from 'react'
import { createContainer } from 'unstated-next'

import { reportClientError } from '../../../app/_components/chrome/scripts/DataDogRumScript'
import { useFeatureFlag } from '../../../containers/ExperimentationContainer'
import { extractNumericShopifyId } from '../../../lib/shopify'
import { CartContainer } from '../cart'
import { generateLineItemProperties } from '../embroidery/utils'
import { useFixturesContext } from '../fixtures'

import type { CartItem, EmbroideryConfig, Open, QuickBuyData, State } from './types'
import type { EmbroideryVariant, Variant } from '../../../types/graphql'

function useQuickBuy() {
  const { addItems } = CartContainer.useContainer()

  const {
    cart: { finalSale, nonReturnable },
  } = useFixturesContext()

  const collectionAttributionPropertyDecision = useFeatureFlag({
    key: 'checkout-collection-attribution',
    defaultVariant: 'no-collection-attribution',
    ifAccessedPriorToDecisionInitialization: 'return-null-while-pending',
  })

  const finalSaleProperty = useMemo(() => {
    return { [finalSale]: nonReturnable }
  }, [finalSale, nonReturnable])

  const [state, setState] = useState<State>({ modalState: 'Unopened' })

  const openModal = useCallback(
    (data: QuickBuyData) => {
      setState({ modalState: 'Open', ...data })
    },
    [setState]
  )

  const closeModal = useCallback(() => {
    setState(current => ({ ...(current as Open), modalState: 'Closed' }))
  }, [setState])

  const addToCart = useCallback(
    ({
      embroideryConfig,
      embroideryVariant,
      finalSale,
      variant,
      swPromoEligible,
      quantity,
      openMiniCartOnAdd,
      collectionAttribution,
    }: {
      embroideryConfig?: EmbroideryConfig
      embroideryVariant?: EmbroideryVariant
      finalSale?: boolean
      variant: Variant
      swPromoEligible?: boolean
      quantity?: number
      openMiniCartOnAdd?: boolean
      collectionAttribution?: string
    }) => {
      const getItems = () => {
        const item: CartItem = { finalSale, item: variant, swPromoEligible }

        if (finalSale) item.properties = finalSaleProperty

        if (
          collectionAttributionPropertyDecision === 'yes-collection-attribution' &&
          collectionAttribution
        ) {
          if (item.properties) {
            item.properties._collectionAttribution = collectionAttribution
          } else {
            item.properties = { _collectionAttribution: collectionAttribution }
          }
        }

        if (!embroideryVariant)
          return [
            {
              ...item,
              quantity,
              openMiniCartOnAdd,
            },
          ]

        const shopifyId = extractNumericShopifyId(variant.shopifyId)

        if (!shopifyId) return [item]

        const properties = generateLineItemProperties(
          {
            embroideryItemVariant: { ...embroideryVariant, images: [] },
            logoName: embroideryConfig?.logoName,
            logoImageSource: embroideryConfig?.image,
          },
          undefined,
          shopifyId
        )

        return [
          { item: embroideryVariant, properties, isPortalLogo: true },
          {
            ...item,
            properties: {
              ...finalSaleProperty,
              _embroideryId: variant.shopifyId,
              _guid: properties._guid,
              _LPROP: properties._LPROP,
            },
            isPortalColor: true,
          },
        ]
      }

      try {
        addItems(getItems())
      } catch (error) {
        reportClientError({
          error,
          context: {
            scope: 'QuickBuy Add to Bag',
          },
        })

        throw error
      }
    },
    [addItems, collectionAttributionPropertyDecision, finalSaleProperty]
  )

  return useMemo(
    () => ({
      addToCart,
      closeModal,
      openModal,
      state,
    }),
    [addToCart, closeModal, openModal, state]
  )
}

export const QuickBuyContainer = createContainer(useQuickBuy)
