import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import { useKey } from 'react-use'

import { useScrollDirection } from '@syconium/little-miss-figgy'
import { NavContainer } from '@syconium/magnolia/src/brunswick/containers/NavContainer'
import { useSearchOverlayContext } from '@syconium/magnolia/src/containers/SearchOverlayContext'
import { disableScroll, enableScroll } from '@syconium/magnolia/src/lib/utils'

export function useViewModel() {
  const {
    promoBarHeightDesktop,
    promoBarHeightDesktopPixels,
    promoBarHeightMobile,
    promoBarHeightMobilePixels,
    promoBarIsOpen,
  } = NavContainer.useContainer()
  const router = useRouter()
  const { closeSearchOverlay, shouldDisplaySearchOverlay } = useSearchOverlayContext()
  const [promoBar, setPromoBar] = useState<boolean>(false)

  const scrollDirection = useScrollDirection()

  useEffect(() => {
    setPromoBar(promoBarIsOpen)
  }, [promoBarIsOpen, setPromoBar])

  useEffect(() => {
    if (shouldDisplaySearchOverlay) {
      disableScroll()
    } else {
      enableScroll()
    }
  }, [shouldDisplaySearchOverlay])

  useEffect(() => {
    router.events.on('routeChangeStart', closeSearchOverlay)
    return () => {
      router.events.off('routeChangeStart', closeSearchOverlay)
    }
  }, [closeSearchOverlay, router])

  useKey(
    'Escape',
    () => {
      if (shouldDisplaySearchOverlay) closeSearchOverlay()
    },
    undefined,
    [closeSearchOverlay, shouldDisplaySearchOverlay]
  )

  const onFormSubmit = useCallback(
    (searchText: string) => {
      router.push({
        pathname: '/search',
        query: { q: searchText },
      })
    },
    [router]
  )

  return {
    closeSearchOverlay,
    onFormSubmit,
    promoBar,
    promoBarHeightDesktop,
    promoBarHeightDesktopPixels,
    promoBarHeightMobile,
    promoBarHeightMobilePixels,
    scrollDirection,
    shouldDisplaySearchOverlay,
  }
}
