import { useMutation } from '@apollo/client'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import * as Yup from 'yup'

import {
  SubmitCustomerSizeProfileInput,
  SubmitCustomerSizeProfileMutation,
} from '../../../../__generated__/graphql/catalog/graphql'
import { cookieKeys } from '../../../../app/_config/Cookies.config'
import { useCookies } from '../../../../app/_providers/CookiesProvider.client'
import { useGraphqlClients } from '../../../../app/_providers/GraphqlClientsProvider.client'
import { useLocalization } from '../../../../app/_providers/LocalizationProvider.client'
import { useFixturesContext } from '../../../../brunswick/containers/fixtures'
import { MagnoliaStrings } from '../../../../brunswick/containers/fixtures/IFixtures'
import { trackEvent } from '../../../../lib/analytics'
import { HeapTrackContainer } from '../../../../lib/analytics/hooks/HeapTrackContainer'
import { SUBMIT_CUSTOMER_SIZE_PROFILE } from '../../../../lib/graphql/mutations/SUBMIT_CUSTOMER_SIZE_PROFILE'
import { MY_SIZE_PREFERENCES } from '../../../../lib/graphql/queries'
import { GET_CUSTOMER_SIZE_PROFILE } from '../../../../lib/graphql/queries/GET_CUSTOMER_SIZE_PROFILE'
import {
  cmsToInches,
  feetAndInchesToInches,
  inchesToCentimeters,
  inchesToFeetAndInches,
  kgsToLbs,
  lbsToKgs,
} from '../../../../lib/utils/unitConversion'
import { useFetchFormEntries } from '../hooks/useFetchRecommendation'
import { FitQuizUserStateContainer, Recommendation } from '../hooks/useFitQuizUserState'

import { GenderSelect, MeasurementInput, SplitTextInputField, TextInputField } from './inputs'
import {
  ButtonInputs,
  CompleteFormButton,
  Divider,
  Form,
  SplitItemsContainer,
  TermAgreementText,
  TermLink,
} from './styles'

export interface FitQuizFormProps
  extends Readonly<{
    gender?: string
    heightInInches?: string
    heightInFeet?: string
    heightInCm?: string
    weight?: string
    age?: string
  }> {}
const createValidationSchema = (formErrors: MagnoliaStrings['fitQuiz']['formErrors']) =>
  Yup.object({
    gender: Yup.string().required(formErrors.requiredField),
    age: Yup.number()
      .typeError(formErrors.nonNumeric)
      .required(formErrors.requiredField)
      .positive(formErrors.negative)
      .lessThan(1000, formErrors.ageError)
      .min(18, formErrors.minAgeError),
    weight: Yup.number()
      .typeError(formErrors.nonNumeric)
      .required(formErrors.requiredField)
      .lessThan(10000, formErrors.weightError)
      .positive(formErrors.negative),
    heightInCm: Yup.number()
      .typeError(formErrors.nonNumeric)
      .positive(formErrors.negative)
      .lessThan(10000, formErrors.centimetersError),
    heightInFeet: Yup.number()
      .typeError(formErrors.nonNumeric)
      .lessThan(11, formErrors.feetError)
      .positive(formErrors.negative),
    heightInInches: Yup.number()
      .lessThan(12, formErrors.inchesError)
      .typeError(formErrors.nonNumeric)
      .min(0, formErrors.negative)
      .default(0),
  })
    .test('heightInCm', formErrors.requiredField, function (values) {
      const { heightInCm, heightInFeet } = values
      if (heightInCm) {
        return true
      }
      if (!heightInFeet) {
        return this.createError({
          path: 'heightInCm',
          message: formErrors.requiredField,
        })
      }
      return true
    })
    .test('heightInFeet', formErrors.requiredField, function (values) {
      const { heightInCm, heightInFeet } = values
      if (heightInCm) {
        return true
      }
      if (!heightInFeet) {
        return this.createError({
          path: 'heightInFeet',
          message: formErrors.requiredField,
        })
      }

      return true
    })

export const FitQuizForm: React.FC<{
  initialGender?: string
}> = ({ initialGender }) => {
  const { cacheOptimizedClient: figsPublicClient, authorizedClient: figsAuthedClient } =
    useGraphqlClients()
  const { region } = useLocalization()
  const initialMetricFormState: boolean = region.id !== 'US'
  const [cookies, setCookie] = useCookies([cookieKeys.menSizeRec.key, cookieKeys.womenSizeRec.key])
  const {
    recommendations: currentRecommendations,
    setRecommendations,
    setStep,
    setSelectedGender,
    setResubmitQuizFlowActive,
  } = FitQuizUserStateContainer.useContainer()
  const [submitCustomerSizeProfile] = useMutation<SubmitCustomerSizeProfileMutation>(
    SUBMIT_CUSTOMER_SIZE_PROFILE
  )
  const { formEntriesWomen, formEntriesMen, loading: formEntriesLoading } = useFetchFormEntries()

  const initialPreferences = initialGender === 'MEN' ? formEntriesMen : formEntriesWomen

  const initialCms = initialPreferences?.height ? initialPreferences?.height * 100 : undefined
  const initialTotalInches = initialCms ? cmsToInches(initialCms) : undefined
  const initialImperialHeight = initialTotalInches
    ? inchesToFeetAndInches(initialTotalInches)
    : undefined

  const initialValues = {
    gender: initialGender,
    heightInInches: initialImperialHeight
      ? Math.round(initialImperialHeight.inches).toString()
      : undefined,
    heightInFeet: initialImperialHeight
      ? Math.round(initialImperialHeight.feet).toString()
      : undefined,
    heightInCm: initialCms ? Math.round(initialCms)?.toString() : undefined,
    weight: initialMetricFormState
      ? initialPreferences?.weight
        ? Math.round(initialPreferences?.weight).toString()
        : undefined
      : initialPreferences?.weight
      ? Math.round(kgsToLbs(initialPreferences?.weight)).toString()
      : undefined,
    age: initialPreferences?.age?.toString() ?? undefined,
  }

  const { sendHeapEvent } = HeapTrackContainer.useContainer()

  const {
    magnolia: {
      fitQuiz: {
        formLabels: { height, weight, age, submit, reset },
        formUnits,
        formUnitsShorthand,
        formErrors,
        termAgreement: {
          byClicking,
          understanding,
          privacyPolicy,
          and,
          humaneticsPrivacyPolicy,
          frontQuote,
          endQuote,
          termsOfUse,
          agreement,
        },
      },
    },
  } = useFixturesContext()
  const handleGenderChange = (id: string, value?: string) => {
    formik.setFieldValue(id, value)
  }
  const validationSchema = createValidationSchema(formErrors)
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async values => {
      await apiHandler(values)
    },
  })

  const apiHandler = async (values: FitQuizFormProps) => {
    setResubmitQuizFlowActive(false)
    setStep('loading')
    const height = isMetricForm
      ? Number(values.heightInCm)
      : Number(values.heightInInches) + 12 * (values.heightInFeet ? Number(values.heightInFeet) : 0)
    let customerSizeProfile: SubmitCustomerSizeProfileInput = {
      age: Number(values.age),
      gender: values.gender === 'WOMEN' ? 'WOMEN' : 'MEN',
      weight: Number(values.weight),
      height: height,
      measurementUnitSystem: isMetricForm ? 'METRIC' : 'IMPERIAL',
    }
    setStep('loading')
    const recId =
      values.gender === 'WOMEN'
        ? cookies[cookieKeys.womenSizeRec.key] ?? null
        : cookies[cookieKeys.menSizeRec.key] ?? null
    try {
      const response = await submitCustomerSizeProfile({
        variables: {
          id: recId,
          input: customerSizeProfile,
        },
      })

      if (
        !response.data ||
        !response.data.submitCustomerSizeProfile?.recommendations ||
        response.errors
      ) {
        setStep('error')
        return
      }
      if (values.gender === 'WOMEN') {
        setCookie(
          cookieKeys.womenSizeRec.key,
          response.data.submitCustomerSizeProfile.id,
          cookieKeys.womenSizeRec.options
        )
      } else {
        setCookie(
          cookieKeys.menSizeRec.key,
          response.data.submitCustomerSizeProfile.id,
          cookieKeys.menSizeRec.options
        )
      }
      sendHeapEvent({
        eventName: 'fit-quiz-submitted',
        props: {
          [values.gender === 'WOMEN' ? 'women-fit-quiz-id' : 'men-fit-quiz-id']:
            response.data.submitCustomerSizeProfile.id,
          [values.gender === 'WOMEN'
            ? 'women-fit-quiz-recommendations'
            : 'men-fit-quiz-recommendations']: JSON.stringify(
            response.data.submitCustomerSizeProfile.recommendations
          ),
        },
      })
      await figsAuthedClient.refetchQueries({
        include: [MY_SIZE_PREFERENCES],
        updateCache(cache) {
          cache.evict({ fieldName: 'my' })
        },
      })
      await figsPublicClient.refetchQueries({
        include: [GET_CUSTOMER_SIZE_PROFILE],
        updateCache(cache) {
          cache.evict({ fieldName: 'getCustomerSizeProfile' })
        },
      })
      const recommendations: Recommendation[] =
        response.data.submitCustomerSizeProfile.recommendations.map(recommendation => {
          return {
            category: recommendation?.category,
            fit: recommendation?.fit,
            size: recommendation?.size,
          }
        })
      const recommendationGender =
        response.data.submitCustomerSizeProfile.gender === 'MEN' ? 'men' : 'women'
      const oppositeRecommendationGender = recommendationGender === 'men' ? 'women' : 'men'
      setSelectedGender(recommendationGender)
      setRecommendations({
        [recommendationGender]: recommendations,
        [oppositeRecommendationGender]: {
          ...currentRecommendations?.[oppositeRecommendationGender],
        },
      })
      setStep('recommendation')
    } catch {
      setStep('error')
    }
  }
  const convertFieldsToMetric = () => {
    const totalHeightInches = formik.values.heightInFeet
      ? feetAndInchesToInches(
          Number(formik.values.heightInFeet),
          Number(formik.values.heightInInches ?? 0)
        )
      : undefined
    formik.setFieldValue(
      'heightInCm',
      totalHeightInches ? Math.round(inchesToCentimeters(totalHeightInches)).toString() : undefined
    )
    formik.setFieldValue(
      'weight',
      formik.values.weight
        ? Math.round(lbsToKgs(Number(formik.values.weight))).toString()
        : undefined
    )
  }
  const convertFieldsToImperial = () => {
    const totalHeightInches = formik.values.heightInCm
      ? cmsToInches(Number(formik.values.heightInCm))
      : undefined
    const imperialHeight = totalHeightInches ? inchesToFeetAndInches(totalHeightInches) : undefined
    formik.setFieldValue('heightInFeet', imperialHeight?.feet.toString())
    formik.setFieldValue(
      'heightInInches',
      imperialHeight ? Math.round(imperialHeight?.inches).toString() : undefined
    )
    formik.setFieldValue(
      'weight',
      formik.values.weight
        ? Math.round(kgsToLbs(Number(formik.values.weight))).toString()
        : undefined
    )
  }
  const [isMetricForm, setIsMetricForm] = useState(initialMetricFormState)
  const [centimetersTouched, setCentimetersTouched] = useState(false)
  const [feetTouched, setFeetTouched] = useState(false)
  const [weightTouched, setWeightTouched] = useState(false)
  const [ageTouched, setAgeTouched] = useState(false)
  const hasAttemptedSubmit = formik.submitCount > 0

  return (
    <Form onSubmit={formik.handleSubmit}>
      <ButtonInputs>
        <MeasurementInput
          checked={isMetricForm}
          onChange={() => {
            !isMetricForm ? convertFieldsToMetric() : convertFieldsToImperial()
            setIsMetricForm(!isMetricForm)
          }}
        />
        <GenderSelect
          id='gender'
          value={formik.values.gender}
          onChange={handleGenderChange}
          errorText={formik.errors.gender}
        />
      </ButtonInputs>
      {isMetricForm ? (
        <TextInputField
          loading={formEntriesLoading}
          id='heightInCm'
          value={formik.values.heightInCm}
          onChange={e => {
            formik.handleChange(e)
            setCentimetersTouched(true)
          }}
          unit={formUnits.centimeters}
          unitShorthand={formUnitsShorthand.centimeters}
          label={height}
          errorText={
            centimetersTouched || hasAttemptedSubmit ? formik.errors.heightInCm : undefined
          }
          inputType={'number'}
        />
      ) : (
        <SplitTextInputField
          textInputFields={[
            {
              id: 'heightInFeet',
              value: formik.values.heightInFeet,
              onChange: e => {
                formik.handleChange(e)
                setFeetTouched(true)
              },
              unit: formUnits.feet,
              unitShorthand: formUnitsShorthand.feet,
              errorText: feetTouched || hasAttemptedSubmit ? formik.errors.heightInFeet : undefined,
              inputType: 'number',
              loading: formEntriesLoading,
            },
            {
              id: 'heightInInches',
              value: formik.values.heightInInches,
              onChange: e => {
                formik.handleChange(e)
              },
              unit: formUnits.inches,
              unitShorthand: formUnitsShorthand.inches,
              errorText: formik.errors.heightInInches,
              inputType: 'number',
              loading: formEntriesLoading,
            },
          ]}
          label={height}
        />
      )}
      <TextInputField
        id='weight'
        value={formik.values.weight}
        onChange={e => {
          formik.handleChange(e)
          setWeightTouched(true)
        }}
        unit={isMetricForm ? formUnits.kilograms : formUnits.pounds}
        unitShorthand={isMetricForm ? formUnitsShorthand.kilograms : formUnitsShorthand.pounds}
        label={weight}
        errorText={weightTouched || hasAttemptedSubmit ? formik.errors.weight : undefined}
        inputType={'number'}
        loading={formEntriesLoading}
      />
      <TextInputField
        id='age'
        value={formik.values.age}
        onChange={e => {
          formik.handleChange(e)
          setAgeTouched(true)
        }}
        unit={formUnits.years}
        unitShorthand={formUnits.years}
        label={age}
        errorText={ageTouched || hasAttemptedSubmit ? formik.errors.age : undefined}
        inputType={'number'}
        loading={formEntriesLoading}
      />
      <Divider />
      <SplitItemsContainer>
        <CompleteFormButton
          variant={'black-on-white'}
          isHighlighted={false}
          isProcessing={false}
          onClick={() => formik.resetForm()}
          type='reset'
          {...trackEvent({
            category: 'fit quiz',
            action: 'reset form',
          })}
        >
          {reset}
        </CompleteFormButton>
        <CompleteFormButton
          isHighlighted={false}
          isProcessing={false}
          type='submit'
          {...trackEvent({
            category: 'fit quiz',
            action: 'submit form',
          })}
        >
          {submit}
        </CompleteFormButton>
      </SplitItemsContainer>
      <div>
        <TermAgreementText>
          {byClicking} {frontQuote}
          {submit.toUpperCase()}
          {endQuote} {understanding} <TermLink href='/pages/terms-of-use'>{termsOfUse}</TermLink>{' '}
          <TermLink href='/pages/privacy'>{privacyPolicy}</TermLink> {and}{' '}
          <TermLink href={'https://www.humaneticsgroup.com/privacy-policy-gdpr'}>
            {humaneticsPrivacyPolicy}
          </TermLink>{' '}
          {agreement}
        </TermAgreementText>
      </div>
    </Form>
  )
}

export default FitQuizForm
