import { stringifyUrl } from 'query-string'
import { useCallback, useMemo } from 'react'

import { Skeleton } from '@syconium/little-miss-figgy'

import { isValidNostoPlacementId } from '../../../app/_components/chrome/scripts/NostoScript'
import { ProductCarousel } from '../../../brunswick/components/ProductCarousel'
import { ProductCarouselSkeleton } from '../../../brunswick/components/ProductCarousel/Skeleton/ProductCarouselSkeleton'
import { useNostoRecos } from '../../../lib/hooks/useNostoRecos'

import { Body, Title } from './styles'

import type { ProductRecommendationsSection as ProductRecommendationsSectionProps } from '../../../types/graphql/page-view'
import type { FC } from 'react'

export const ProductRecommendationsSection: FC<ProductRecommendationsSectionProps> = ({
  hideColors,
  hidePrice,
  name,
  nostoPlacementId,
  productCountLimit,
  title,
  titleAlignmentDesktop,
  titleAlignmentMobile,
  useNostoPlacementTitle,
}) => {
  const { status, placementTitle, productSummaries, resultId } = useNostoRecos({
    nostoPlacementId: isValidNostoPlacementId(nostoPlacementId)
      ? nostoPlacementId
      : 'frontpage-nosto-1',
    productCountLimit: productCountLimit ?? undefined,
  })

  const augmentTileLinkPath = useCallback(
    (path: string) => {
      return stringifyUrl({
        url: path,
        query: { nosto: resultId },
      })
    },
    [resultId]
  )

  const analyticsContext = useMemo(
    () => ({
      category: 'product-carousel-tile-nosto',
      customAttributes: {
        'data-heap-placement-id': nostoPlacementId,
        'data-heap-parent-title': title ?? undefined,
        'data-heap-section-name': name,
        'data-heap-contentful-name': name,
      },
    }),
    [name, nostoPlacementId, title]
  )

  const computedTitle = useNostoPlacementTitle && placementTitle ? placementTitle : title

  if (status === 'rejected' || (status === 'resolved' && productSummaries.length === 0)) return null

  return (
    <Body>
      {status === 'pending' ? (
        <>
          {useNostoPlacementTitle || title ? (
            <Title
              alignmentDesktop={titleAlignmentDesktop ?? undefined}
              alignmentMobile={titleAlignmentMobile ?? undefined}
              asTag='div'
            >
              <Skeleton height='42px' maxWidth='225px' />
            </Title>
          ) : null}
          <ProductCarouselSkeleton />
        </>
      ) : (
        <>
          {computedTitle ? (
            <Title
              alignmentDesktop={titleAlignmentDesktop ?? undefined}
              alignmentMobile={titleAlignmentMobile ?? undefined}
              asTag='h5'
            >
              {computedTitle}
            </Title>
          ) : null}
          <ProductCarousel
            analyticsContext={analyticsContext}
            augmentTileLinkPath={augmentTileLinkPath}
            hideColors={hideColors ?? undefined}
            hidePrice={hidePrice ?? undefined}
            products={productSummaries}
          />
        </>
      )}
    </Body>
  )
}
