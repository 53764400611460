import Script from 'next/script'
import { FC, useEffect } from 'react'
import styled from 'styled-components'

import { SupportedLanguageGroupIdentifier, SupportedRegionIdentifier } from '@syconium/weeping-figs'

import { cookieKeys } from '../../../app/_config/Cookies.config'
import { useCookies } from '../../../app/_providers/CookiesProvider.client'
import { StudentBeansCallbackEvent } from '../../../types/studentbeans'

const StudentBeansScript = () => {
  // For now, not blocking this by any cookie consent requirements unless we find out this third party is doing more than just providing us a form on the page.
  return (
    <Script
      id='stb_root'
      strategy='beforeInteractive' // Scripts that alter the UI are before interactive
      src='https://cdn.studentbeans.com/third-party/all.js'
    />
  )
}

export const StudentBeansSection: FC<{
  region: SupportedRegionIdentifier
  language: SupportedLanguageGroupIdentifier | undefined
}> = ({ region, language }) => {
  let countryCode: string = region
  const dataLang = language === 'en' ? 'us' : '' // leaving this value blank will cause studentbeans to display in the default language for the selected region

  if (region === 'GB') countryCode = 'UK'
  const [_cookie, setCookie] = useCookies([cookieKeys.discountCode.key])

  useEffect(() => {
    const onStudentBeansCallback = (event: StudentBeansCallbackEvent) => {
      if (event.detail.code) {
        setCookie(cookieKeys.discountCode.key, event.detail.code, {
          ...cookieKeys.discountCode.options,
          maxAge: 86400, // student beans discount code expires in 24 hours
        })
      }
    }
    window.addEventListener('sbCodeCallback', onStudentBeansCallback)
    return () => {
      window.removeEventListener('sbCodeCallback', onStudentBeansCallback)
    }
  }, [setCookie])

  return (
    <>
      <StudentBeansScript />
      <Container>
        <Connect
          id='stb-connect'
          data-connect={`figs/${countryCode}`}
          data-language={dataLang}
        ></Connect>
      </Container>
    </>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${o => o.theme.spacing(6)};
`

const Connect = styled.div`
  height: 720px; // 720px is the default height of the Student Beans iframe
  width: 75vw;
`
