import React from 'react'

import { BaseIcon, IProps } from '../../BaseIcon'

interface Props extends IProps {
  viewBox?: string
}

export const Socks: React.FC<Props> = ({
  height = '40px',
  width = '40px',
  viewBox = '0 0 40 40',
  ...rest
}) => {
  return (
    <BaseIcon width={width} height={height} viewBox={viewBox} {...rest}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM11.0662 23.241C10.7113 24.9564 11.8185 26.6404 13.5327 26.9967C14.0841 27.1105 14.6477 27.0759 15.1778 26.8996V26.8259C15.1778 25.2009 20.0672 22.3317 21.4569 21.5491L20.0261 12.0826C20.015 12.0056 20.0339 11.9263 20.0808 11.8638C20.1277 11.8013 20.1969 11.76 20.275 11.7489L24.7438 11.1116C24.7874 10.8091 24.8253 10.5603 24.8532 10.3806L18.8041 10L18.2896 19.4665C18.2829 19.5893 18.2014 19.6942 18.0842 19.7299C15.4849 20.5402 11.2808 22.2098 11.0666 23.241L11.0662 23.241ZM28.59 22.9648L28.59 22.9648L28.5897 22.9643C28.3453 22.3951 28.0417 21.6842 27.7147 20.6116C27.0473 18.4174 26.7214 12.8471 26.6478 11.432L20.6489 12.288L22.0663 21.6619C22.0842 21.7836 22.0262 21.9019 21.919 21.9622C19.5396 23.2825 15.7639 25.7724 15.7639 26.8258C15.7639 26.8883 15.765 26.9821 15.7739 27.0669C15.9012 28.7153 17.2907 30 18.9402 30C19.6601 30 20.3654 29.7523 20.9279 29.3003C20.9324 29.2969 20.9368 29.2936 20.9413 29.2913C21.0161 29.2378 22.8018 27.9722 24.8676 27.1652C25.429 26.9462 25.9981 26.7948 26.5497 26.648L26.5497 26.648L26.5562 26.6463C28.1756 26.2133 29.092 25.9141 29.092 24.7902C29.092 24.1386 28.9034 23.6978 28.59 22.9648Z'
      />
    </BaseIcon>
  )
}
