import {
  AffirmStrings,
  Afterpay,
  CartStrings,
  DetailsSectionStrings,
  EmailCaptureStrings,
  EmbroideryStrings,
  ErrorPageStrings,
  FavoritesStrings,
  FitModelStrings,
  FreeShippingStrings,
  GiftCardStrings,
  GlobalStrings,
  GoogleOptimizeExperiments,
  InitialSiteFixtures,
  MagnoliaStrings,
  PageProductStrings,
  PageSectionStrings,
  RegionAndLocalePickerStrings,
  SizesSectionStrings,
  TestimonialsStrings,
  WaitlistStrings,
} from '../IFixtures'

import { fallbackSiteFixtures as fallback, fallbackNavV1 } from './fallback'
import { enUsFooter } from './footer'

const embroidery: EmbroideryStrings = {
  cta: 'Add Embroidery',
  textCta: 'Add Text Embroidery',
  title: 'Personalize',
  logoTitle: 'Logo Embroidery',
  from: 'From',
  textTitle: 'Text Embroidery',
  promoText: '',
  close: 'Close',
  ariaLabelCloseModal: 'Close embroidery modal',
  embroideryOptionTitles: {
    ICON_ONLY: 'add icon',
    TEXT_ONLY: 'add text',
    LOGO_ONLY: 'add my logo',
    LOGO_SETUP: 'add logo',
    LOGO_BLACK_WHITE: 'add logo',
    LOGO_COLOR: 'add logo',
  },
  discountText: {
    formSection: '{{discountPercentage}}% off embroidery cost at checkout',
    summarySection: 'A {{discountPercentage}}% embroidery discount calculated at checkout',
  },
  validation: {
    logoEmbroideryRequired: 'Logo embroidery is required',
    textEmbroideryRequired: 'Text embroidery is required',
    required: 'required',
  },
  personalizeSection: {
    logoEmbroideryRequired: 'uniforms require logo embroidery',
    textEmbroideryRequired: 'uniforms require text embroidery',
    logoAndTextEmbroideryRequired: 'uniforms require text and logo embroidery',
    discountText: 'A {{discountPercentage}}% discount will be applied at checkout',
  },
  modal: {
    forms: {
      fontSelector: {
        label: 'Select Font Type',
      },
      iconOption: {
        iconPlacementLabel: 'icon placement',
        iconSelectorLabel: 'icon',
        initialsColorLabel: 'Initials Color',
        initialsLabel: 'Initials (Optional)',
        initialsPlaceholder: 'Your Initials',
      },
      textOption: {
        textColorLabel: 'Select Text Color',
        firstLinePlaceholder: 'First Line',
        secondLinePlaceholder: 'Second Line (Optional)',
        textLabel: 'First Line',
        textPlacementLabel: 'Select Text Position',
      },
      submissionButtons: {
        submitCta: 'add',
        resetCta: 'reset',
      },
      validationErrorMessages: {
        allowedTextCharacters: 'Please use letters (A-Z) or numbers (0-9) only',
        allowedInitialsTextCharacters: 'Please use letters (A-Z)',
        placementAlreadySelected: 'Name and Icon cannot be embroidered with the same placement.',
        requiredNamePlacement: 'Name placement is required',
        requiredName: 'Name is required',
        requiredNameColor: 'Name Color is required',
        requiredFont: 'Font is required',
        requiredIconPlacement: 'Icon placement is required',
        requiredLogoPlacement: 'Logo placement is required',
        requiredIcon: 'Icon is required',
        requiredLogo: 'Logo is required',
        requiredSelection: 'Please make a selection',
        requiredText: 'Please fill out this field',
        textMaxCharsTemplate: 'Please only enter up to {{max}} characters',
        requiredFile: 'Please upload a file',
        requiredDigitizationSetting: 'Logo color is required',
        fileUploadError: 'There was an unexpected error uploading your logo',
        requiredTos: 'Please accept the terms and conditions',
      },
      logoOption: {
        logoLabel: 'Logo',
        logoPlacementLabel: 'Logo Placement',
        logoNoteLabel: 'Note',
        logoColorLabel: 'Color',
      },
      dtcLogoEmbroideryForm: {
        addNewLogo: 'Add New Logo',
        previewItemText: 'Your Logo Here',
        digitizationRequestTooltip:
          'This custom logo requires digitization approval before your order can be confirmed. You will be contacted within 2-3 business days of your order to begin the digitization process.',
        title: 'Logo Digitization',
        logoColorLabel: 'Logo Color',
        ariaLabelLogoColorTooltip: '"More information about custom logo embroidery color selection',
        logoPreviewImageAlt: 'Logo Preview',
        fullColor: 'Full Color',
        blackWhite: 'Black & White',
        uploadCta: 'Upload a Logo',
        ariaLabelRemoveLogo: 'Remove uploaded logo',
        uploadSpecs:
          'We accept PNG, JPG, EPS with 3MB max file size. Recommended dimensions are 3000 px x 4000 px.',
        learnMore: 'Learn More',
        ariaLabelLearnMoreLink: 'Learn more about logo digitization',
        logoName: 'Logo Name',
        logoNamePlaceholder: 'Placeholder',
        termsOfService: {
          agree: 'I have read and agree to the',
          termsOfUse: 'Terms of Use',
          andThe: 'and the',
          supplementalTerms: 'Supplemental Terms for Logo Embroidery Orders.',
        },
        nextCta: 'Next',
        noteLabel: 'Add a note',
        fileSizeError: 'File size cannot exceed 3MB',
        digitizationPending: 'digitization pending',
        digitizationRequest: 'digitization request',
        digitizationRequested: 'digitization requested',
        edit: 'Edit',
        noteIncluded: 'Note included.',
        logoPlacement: 'Logo Placement',
        leftChest: 'Left Chest',
        rightChest: 'Right Chest',
        existingLogoOrText: 'or',
        existingLogoTitle: 'Use an existing logo from $20',
        existingLogoSubtitle: 'For those with previously approved logos.',
        loginCta: 'Login to continue',
        logoStrings: {
          LOGO_COLOR: {
            title: 'Full Color',
          },
          LOGO_BLACK_WHITE: {
            title: 'Black & White',
          },
        },
      },
    },
    new: 'New',
    previewTitle: 'Personalize your FIGS',
    previewSubtitle: 'Personalizing your uniform is a great way to make it yours.',
    backButton: 'Back',
    optionsTitle: 'Embroidery Options',
    title: 'personalize your figs',
    confirm: 'Confirm',
    iconUnavailable: 'Not available with Logo embroidery',
    logoUnavailable: 'Not available with Icon embroidery',
  },
  disclosureText:
    'Embroidered items are FINAL SALE. Orders with embroidered items will take up to two additional weeks to ship.',
  summary: {
    editEmbroidery: 'edit',
    iconInitialsColorLabel: 'Initials Color',
    iconInitialsLabel: 'Initials',
    iconNameLabel: 'Icon',
    iconPlacementLabel: 'Icon Placement',
    textColorLabel: 'Text Color',
    textFirstLineLabel: 'First Line',
    textFontLabel: 'Font Type',
    textPlacementLabel: 'Text Placement',
    textSecondLineLabel: 'Second Line',
    toggleAddEmbroidery: 'add embroidery',
  },
  embroideryCostBreakdown: {
    title: 'Details',
    logoDigitizationFee: 'logo digitization fee',
    embroideryTitles: {
      LOGO_EMBROIDERY_TEXT_COLOR: 'Text & Logo Embroidery - Full Color',
      LOGO_EMBROIDERY_TEXT_BLACK_WHITE: 'Text & Logo Embroidery - Black & White',
      LOGO_EMBROIDERY_BLACK_WHITE: 'Logo Embroidery - Black & White',
      LOGO_EMBROIDERY_COLOR: 'Logo Embroidery - Full Color',
      EMBROIDERY_ICON_TEXT: 'Text & Icon Embroidery',
      EMBROIDERY: 'Text Embroidery',
      EMBROIDERY_ICON_ONLY: 'Icon Embroidery',
    },
  },
}

const fitModel: FitModelStrings = {
  height: 'Height',
  pantsSize: 'Pants Size',
  switchModel: 'Switch Model',
  topSize: 'Top Size',
}

const globals: GlobalStrings = {
  siteName: 'FIGS',
  description:
    'FIGS Scrubs: FIGS makes 100% awesome medical apparel. Why wear scrubs when you can wear FIGS?',
  metaImage:
    'https://cdn.shopify.com/s/files/1/0139/8942/files/FIGS_LOGO_BLACK-02-large.png?v=1574898420',
  canonicalBaseUrl: {
    collections: 'https://www.wearfigs.com/collections/',
    home: 'https://www.wearfigs.com/',
    kits: 'https://www.wearfigs.com/kits/',
    products: 'https://www.wearfigs.com/products/',
  },
  socialMediaHandles: {
    instagram: '@wearfigs',
    twitter: '@wearfigs',
    hashtag: 'wearfigs',
  },
  facebookAppID: '2608783789444248',
}

const notFound: ErrorPageStrings = {
  canonicalPath: 'pages/404',
  heading: '404 Page Not Found',
  image: {
    alt: 'FIGS Electrocardiogram',
    src: 'https://cdn.shopify.com/s/files/1/0139/8942/files/ekg_4970b0c7-3fe4-4f12-8684-4e1d946935d6.gif?v=1636655846',
  },
  links: {
    shopMen: {
      href: '/collections/best-sellers-mens',
      title: "Men's Best Sellers",
    },
    shopWomen: {
      href: '/collections/best-sellers-womens',
      title: "Women's Best Sellers",
    },
  },
  subheading: "Let's get back to a healthy rhythm.",
}

const pageGoneError: ErrorPageStrings = {
  canonicalPath: 'pages/410',
  heading: '410 Page Gone',
  image: {
    alt: 'FIGS Electrocardiogram',
    src: 'https://cdn.shopify.com/s/files/1/0139/8942/files/ekg_4970b0c7-3fe4-4f12-8684-4e1d946935d6.gif?v=1636655846',
  },
  links: {
    shopMen: {
      href: '/collections/best-sellers-mens',
      title: "Men's Best Sellers",
    },
    shopWomen: {
      href: '/collections/best-sellers-womens',
      title: "Women's Best Sellers",
    },
  },
  subheading: "Let's get back to a healthy rhythm.",
}

const product: PageProductStrings = {
  imageThumbnailAltText: 'Product Thumbnail Alt Text',
  figsScrubsAre:
    'FIGS scrubs are always *anti-wrinkle, ridiculously soft, moisture-wicking* and feature *four-way stretch* and *Silvadur™ antimicrobial technology* for odor control and fabric durability.',
  gallery: {
    closeZoom: 'Close',
    nextImage: 'View Next Image',
    openZoom: 'Zoom',
    previousImage: 'View Previous Image',
    viewImage: 'View Image',
    seeItLive: 'See It Live',
  },
  productRecommendations: {
    title: 'You May Also Like',
  },
  scrubCategories: ['Scrub Top', 'Scrub Pants', 'Scrub Jackets'],
  scrubPantsFitTitle: 'length',
  scrubTopsFitTitle: 'size type',
  notEligibleForDiscount: 'This product is excluded from site promotions and discounts.',
  whyWeLoveThis: 'Why We Love This',
}

const giftCard: GiftCardStrings = {
  anyQuestions: 'Any questions? Visit our',
  dropdownPlaceholder: 'Select an amount',
  dropdownSoldOutOptionText: '(Sold Out)',
  emailMyself: 'Email Myself',
  emailSomeoneElse: 'Email Someone Else',
  giftMyself: 'Add to Bag',
  giftSomeone:
    'Select the gift card amount and then select “Gift Someone Else”. From there, you will be able to customize your message, gift card image and even the date it will be delivered!',
  giftSomeoneElse: 'Gift Someone else',
  giftSomeoneNote:
    "If purchasing for multiple recipients, be sure to purchase each individually! In addition to your order confirmation email, you'll receive a notification once your friend receives the gift card email.",
  giftYourself: "Select the gift card amount and then select 'Gift Myself'",
  helpCenter: 'Help Center.',
  here: 'here.',
  lookingForDigital: 'Looking for our Digital Gift Card? Check it out',
  lookingForPhysical: 'Looking for our Physical Gift Card? Check it out',
  note: 'Note:',
  seeOurTOCs: 'See our Gift Card Terms and Conditions',
  theyDeserveIt:
    "When you're not sure what colors they can wear or what size they are or if they're more into joggers or cargo pants. 100% guaranteed to make the Awesome Human in your life feel 100% more awesome — because they deserve it.",
  wantToGiftSomeone: 'Want to gift someone else?',
  wantToGiftYourself: 'Want to gift yourself?',
}

const cart: CartStrings = {
  add: 'Add',
  beforeYouGo: 'Before You Go',
  checkoutCTA: 'check out',
  checkoutErrorMessage: 'Something went wrong. Please try again.',
  close: 'Close The Mini Cart',
  embroideryItemLabel: 'Embroidery',
  embroideryTimingMessage:
    '*Embroidered items are final sale and ship within 10-15 business days. Items are non-refundable and discounts do not apply.',
  emptyCartMessage: 'Your cart is currently empty.',
  finalSale: 'Final Sale',
  item: {
    decrementQuantity: 'Decrease Quantity By One',
    incrementQuantity: 'Increase Quantity By One',
  },
  keepShopping: 'Keep shopping',
  nonReturnable: 'Non-Returnable',
  selectSize: 'Select Size',
  size: 'Size',
  soldOutLabel: '(Sold Out)',
  stipendBalanceLabel: 'Stipend Balance',
  title: 'My Bag',
  totalLabel: 'Total',
  portalDiscountMessage: '% off discount applied at checkout',
  /**
   * Temporary addition to support Product Bundle Discounts for the Ribbed Longsleeve Underscrub.
   * @deprecated do not use outside of temporary Product Bundle Discounts work.
   * @todo remove this after we remove this feature. Also remove from `magnolia/i18n/en-US.json`
   */
  discountAppliedAtCheckout: 'Discount applied at checkout',
}

const freeShipping: FreeShippingStrings = {
  moreForFreeShipping: 'more for Free Shipping!',
  enjoyFreeShipping: 'Enjoy FREE Shipping!',
}

const detailsSection: DetailsSectionStrings = {
  accordionTitles: {
    fabricAndCareTitle: 'Fabric & Care',
    productDetailsTitle: 'Details & Fit',
    fitTitle: 'Fit',
  },
  addToBagCTA: 'add to bag',
  collectionLinksTitle: 'shop more',
  colorTitle: 'Color',
  detailsModal: {
    title: 'Product Details',
    viewDetails: 'View Product Details',
    viewFullDetails: 'Shop Item Separately',
  },
  freeShippingMessage: 'Free shipping for $50+ orders',
  freeReturnsMessage: 'and free returns',
  inFlightAddToBagCTA: 'adding...',
  noReviewsMessage: 'Be the first to write a review.',
  quantitySelectorTemplate: 'Qty: {{q}}',
  reviewsCountNoun: 'Review',
  reviewsTooltipPrefix: 'Rating of this product is',
  reviewsTooltipSuffix: 'out of 5.',
  seeAllReviewsCTA: 'See All Reviews',
  soldOutAddToBagCTA: 'sold out',
  soldOutSectionTitleMessage: '(Sold Out)',
  waitlistAddToBagCTA: 'notify me when available',
  writeReviewCTA: 'Write a Review',
  reviewsTitle: 'The Reviews Are In',
  reviewsEmptyTitle: "There Aren't Any Reviews Yet",
  reviewBaseOn: 'Based On',
  reviewStarRating: 'Rating of this product is {{rating}} out of 5.',
  reviewShowMore: 'Read More',
  reviewShowLess: 'Show Less',
  reviewFit: 'Fit',
  reviewPhotosTitle: 'Review Photos',
  portalPromoText: '% off at checkout',
}

const sizesSection: SizesSectionStrings = {
  sizeChartCTA: 'Size Chart',
  sizeChart: {
    headline: 'Size Chart',
    howToMeasure: 'How To Measure',
    imageAlt: 'Measurement details',
    modalHeadline: 'Sizing Information',
    toggleLabels: {
      inches: 'IN',
      centimeters: 'CM',
    },
    wantToKnowLine1: 'Want to know more in detail?',
    wantToKnowLine2: 'Find the measurement below',
  },
  navigation: {
    tabs: {
      womens: "WOMEN'S",
      mens: "MEN'S",
    },
  },
  noChart: 'No chart available',
  pageTitle: 'Size Information',
  title: 'size',
  amount: 'amount',
  titleWhenInvalid: 'select size',
  dropdownPlaceholder: 'Select a size',
}

const waitlist: WaitlistStrings = {
  modal: {
    form: {
      cta: 'Keep me posted',
      email: {
        placeholder: 'Email address',
        requiredMessage: 'Please enter an email address.',
        validEmailMessage: 'Please enter a valid email address.',
      },
    },
    errorMessage: {
      emailAlreadyRegistered: 'This email address is already registered on our waitlist.',
      unexpectedError: 'An unexpected error has occurred. Please try again.',
    },
    successMessage: "You've successfully signed up for our waitlist!",
    headline: 'I want this',
    instructions: "Enter your email below and we'll let you know when these FIGS are available.",
  },
}

const googleOptimizeExperiments: GoogleOptimizeExperiments = {}

const regionAndLocalePicker: RegionAndLocalePickerStrings = {
  displayPrefix: "You're in",
  loadingText: 'Loading region and locale...',
  modal: {
    ctaText: 'Enter',
    labelLanguage: 'Language',
    labelCountry: 'Country',
    placeholderCountry: 'Select Country',
    placeholderLanguage: 'Selected Language',
    titleLanguage: 'And Language',
    title: 'Select a Country',
    figsPrivacyLink: "FIGS' Privacy and Cookie Policy",
    gePrivacyLink: "Global-e's Privacy Policy",
  },
}

const affirm: AffirmStrings = {
  logoSrc:
    'https://cdn.shopify.com/s/files/1/0139/8942/files/affirm_black_indigo_logo-white_bg.svg?v=1629850575',
  ariaLabel: 'Pay over time with Affirm',
  siteModalCssClass: 'affirm-product-modal',
  ctaMessageAboveThresholdTemplate: '4 easy payments of {{payment}} with',
  ctaMessageBelowThreshold: 'Pay over time on $35+ orders with',
  learnMoreCta: 'Learn More',
  promoId: 'promo_set_default',
}

const afterpay: Afterpay = {
  ctaMessageTemplate: '4 payments of {{payment}} with',
}

const pageSections: PageSectionStrings = {
  collectionSection: {
    viewAll: 'View All',
  },
  pageHeroSection: {
    playVideo: 'Play video',
  },
}

const testimonials: TestimonialsStrings = {
  nextSlideButtonLabel: 'Next Slide',
  prevSlideButtonLabel: 'Previous Slide',
}

export const emailCapture: EmailCaptureStrings = {
  genderRadioGroup: {
    legend: 'Select a Gender for Email Newsletter',
    men: 'Select a Gender For Email Newsletter: Men',
    women: 'Select a Gender For Email Newsletter: Women',
  },
  title: 'Take 15% off',
  message:
    'Subscribe to take 15% OFF your first purchase and stay in the know on exclusive new colors, styles and promotions.',
  // TODO: refactor once react-intl is used
  legal: {
    before: 'By clicking "sign up", you agree to receive emails from FIGS and accept our ',
    middle: 'and our',
    after: '.',
    termsLinkHref: '/pages/terms-of-use',
    termsLinkText: 'Terms of Use',
    privacyLinkHref: '/pages/privacy',
    privacyLinkText: 'Privacy Policy',
  },
  input: {
    placeholder: 'Enter your email',
    validation: {
      required: 'Please enter an email address.',
      invalid: 'Please enter a valid email address.',
    },
  },
  submit: 'Sign up',
  success: 'Success!',
  // TODO: refactor once react-intl is used
  promo: {
    before: 'Use code',
    middle: 'FIRSTFIGS',
    after: 'to take 15% OFF your first purchase.',
  },
}

const giftCards: MagnoliaStrings['giftCards'] = {
  addToBag: 'Select "{addToBagCTA}".',
  addToBagCTA: 'Add to Bag',
  cardSrc2500:
    'https://cdn.shopify.com/s/files/1/0139/8942/products/Giftcard_25Artboard_1_copy_900x900.jpg',
  cardSrc5000:
    'https://cdn.shopify.com/s/files/1/0139/8942/products/Giftcard_50Artboard_1_copy_900x900.jpg',
  cardSrc7500:
    'https://cdn.shopify.com/s/files/1/0139/8942/products/Giftcard_75Artboard_1_copy_900x900.jpg',
  cardSrc10000:
    'https://cdn.shopify.com/s/files/1/0139/8942/products/Giftcard_100Artboard_1_copy_900x900.jpg',
  cardSrc25000:
    'https://cdn.shopify.com/s/files/1/0139/8942/products/Giftcard_250Artboard_1_copy_900x900.jpg',
  cardSrcBlurb:
    'https://cdn.shopify.com/s/files/1/0139/8942/products/Giftcard_ThumbnailArtboard_1_copy_2_1da5493a-4803-4440-b406-cda6d17b3d23_900x900.jpg',
  enterRecipient: "Enter the lucky recipient's name and email address.",
  forSomeoneElseExplanation:
    "You'll be able to select the date, enter in a custom message and choose a gift card image — so awesome!",
  giftCard: 'Gift Card',
  giftFigs: 'Gift FIGS',
  giftSomeoneElse: 'Gift someone else',
  giftYourself: 'Want to gift yourself?',
  multipleRecipients:
    'If purchasing for multiple recipients, be sure to purchase each individually!',
  pageTitle: 'FIGS Digital Gift Card',
  selectAmount: 'Select the gift card amount.',
  selectGiftYourself: 'Select "Gift Yourself".',
  sendingToAFriend: 'Sending to a friend? Select "Gift Someone Else".',
  theyDeserveIt:
    "When you're not sure what colors they can wear or what size they are or if they're more into joggers or cargo pants. 100% guaranteed to make the Awesome Human in your life feel 100% more awesome — because they deserve it.",
  wantToGiftSomeoneElse: 'Want to gift someone else?',
}
export const favorites: FavoritesStrings = {
  addToBag: 'ADD TO BAG',
  appExclusive: 'APP EXCLUSIVE',
  getTheApp: 'GET THE APP',
  noSizeSelected: 'No size selected',
  os: 'O/S',
  selectSize: 'See My Size',
  soldOut: 'Sold Out',
  viewKit: 'VIEW KIT',
  waitlist: 'WAITLIST',
}

/**
 * This defaultFixtureData is used by unit tests throughout Brunswick and Magnolia.
 * It includes fallback data for Contentful SiteFixtures, to be overridden on load.
 * Fallback is in production. It is not mock data—it is real and must remain valid.
 */
export const defaultFixtureData: InitialSiteFixtures = {
  magnolia: {
    account: {
      activate: {
        activateButtonText: 'Activate',
        activateYourAccount: 'Activate your FIGS account',
        activationBlurb: 'Please enter your password to complete account activation.',
        image: {
          alt: 'Activate Account',
          src: 'https://cdn.shopify.com/s/files/1/3101/9164/files/login-600x600-v2-1.jpg?v=1639073776',
        },
        pageTitle: 'Activate Account',
      },
      addNewAddress: 'Add New Address',
      addNewShippingAddress: 'Add a New Shipping Address',
      addressForm: {
        address1: 'Address Line 1',
        address2: 'Address Line 2',
        addressRequired: 'Address Is Required',
        cancel: 'Cancel',
        city: 'City',
        cityRequired: 'City Is Required',
        deleteAddress: 'Delete Address',
        firstName: 'First name',
        firstNameRequired: 'First Name Is Required',
        lastName: 'Last name',
        lastNameRequired: 'Last Name Is Required',
        phoneNumber: 'Phone Number',
        province: 'State/Province',
        provincePlaceholder: 'State Or Province',
        save: 'Save',
        setDefaultAddress: 'Set as Default Shipping Address',
        zip: 'Zip/Postal',
        zipPlaceholder: 'Zip Or Postal',
        zipRequired: 'Zip Is Required',
      },
      addressTile: {
        edit: 'Edit',
        defaultAddress: 'Default Address',
      },
      cancelOrder: 'Cancel Order',
      cancelledDate: 'Cancelled Date',
      completeProfile: {
        title: 'Complete Your Profile',
        dismiss: `I'll do this later`,
      },
      contactSupport: 'Contact Us',
      contactSupportLink: 'https://help.wearfigs.com/hc/en-us/requests/new',
      createAccount: 'Create an Account',
      creationError: 'There was a problem processing your request. Please try again later.',
      dashboardShippingAddress: {
        title: 'Shipping Address',
        description: 'Save your shipping address and checkout faster.',
        addAddress: 'Add A Shipping Address',
        defaultAddress: 'Default Address',
      },
      date: 'Order Date',
      deleteAccountModal: {
        title: 'Are you sure you want to delete your account?',
        description:
          'This action cannot be undone and you will not be able to access your profile.',
        cancel: 'Cancel',
        delete: 'Delete Account',
      },
      deleteAddressModal: {
        title: 'Are you sure you want to delete this shipping address?',
        description: 'This action cannot be undone.',
        cancel: 'Cancel',
        delete: 'Delete Address',
      },
      delivered: 'Delivered',
      editPassword: {
        currentPassword: 'Current Password',
        currentPasswordPlaceholder: 'Enter Current Password',
        currentPasswordRequired: 'Current Password Is Required',
        errorOccurred: 'An error occurred. Something went wrong.',
        newPassword: 'New Password',
        newPasswordPlaceholder: 'Enter New Password',
        newPasswordRequired: 'New Password Is Required',
        passwordsDoNotMatch: 'Passwords Do Not Match',
        repeatPassword: 'Repeat Password',
        repeatPasswordPlaceholder: 'Enter Repeat Password',
        repeatPasswordRequired: 'Please Repeat Your New Password',
        save: 'Save',
        title: 'Edit Password',
      },
      editProfile: {
        birthday: 'Birthday',
        birthdayDescription: 'Get a FIGS surprise every year on your Birthday.',
        birthdayFormat: 'Please Enter Your Birthday In The Following Format: MM/DD',
        cancel: 'Cancel',
        changePassword: 'Change Password',
        edit: 'Edit',
        errorOccurred: 'An Error Occurred When Trying To Edit Your Profile',
        firstName: 'First name',
        firstNameRequired: 'First Name Is Required',
        lastName: 'Last name',
        lastNameRequired: 'Last Name Is Required',
        occupation: 'Occupation',
        password: 'Password',
        save: 'Save',
        stylePreference: 'Shopping Preference',
        stylePreferenceDescription: 'Determines your default shopping settings.',
        title: 'Edit Profile',
      },
      editShippingAddress: 'Edit Shipping Address',
      error: 'Error',
      errorOccurred: 'An error occurred. Something went wrong.',
      giftCard: 'Gift Card',
      inProgress: 'In Progress',
      intlMessage: 'Please refer to your email confirmation for the amount paid in local currency',

      loadingHistory: 'Loading history',
      loadingOrder: 'Loading order',
      loadMoreHistory: 'Load More Orders',
      login: {
        clickHere: 'Click here',
        createAnAccount: 'Create an account',
        hiFirstName: 'Hi, {firstName}!',
        image: {
          alt: 'Login',
          src: 'https://cdn.shopify.com/s/files/1/3101/9164/files/login-600x600-v2-1.jpg?v=1639073776',
        },
        newToFigs: 'New to FIGS?',
        pageTitle: 'Login',
        passwordResetSuccess: "We've sent you an email with a link to update your password.",
        welcomeBack: 'Welcome Back!',
        welcomeBackToFigs: 'Welcome back to FIGS',
      },
      logIn: 'Log In',
      logOut: 'Log Out',
      myAccount: 'My Account',
      navigation: {
        profile: 'Profile',
        orders: 'Orders',
        addresses: 'Addresses',
        menu: {
          hi: 'Hi, ',
        },
      },
      noHistory: 'You have no past orders. Start shopping our awesome products!',
      noOrders: 'You Have No Orders.',
      order: 'Order',
      orderCancelledShort: 'Cancelled',
      orderHistory: 'Order History',
      orderNumber: 'Order Number',
      orderPlaced: 'Order Placed',
      orderPlacedShort: 'Placed',
      orderPrice: {
        shipping: 'Shipping',
        subtotal: 'Subtotal',
        tax: 'Tax',
        totalPaid: 'Total Paid',
      },
      orderShippedShort: 'Shipped',
      orderStatus: 'Order Status',
      orderStatuses: {
        cancelled: 'Cancelled',
        delivered: 'Delivered',
        orderPlaced: 'Order Placed',
        partiallyDelivered: 'Partially Delivered',
        shipped: 'Shipped',
      },
      pageTitle: 'Account',
      partialNoHistoryError: 'not find an order history',
      pastOrders: 'Past Orders',
      paymentMethod: 'Payment Method',
      paymentStatus: 'Payment Status',
      productInfo: 'Product Info',
      refunded: 'Refunded',
      register: {
        alreadyRegistered: 'The email address you entered has already been registered. Please ',
        createYourAccount: 'Create your FIGS account',
        creationBlurb:
          "C'mon, everybody's doing it. Create an account to apply discount codes at checkout and get access to exclusive offers and promotions.",
        haveAnAccount: 'Have an account?',
        image: {
          alt: 'Register',
          src: 'https://cdn.shopify.com/s/files/1/3101/9164/files/login-600x600-v2-1.jpg?v=1639073776',
        },
        logInHere: 'Log In Here',
        pageTitle: 'Register',
      },
      reorderAll: 'Reorder all',
      reset: {
        enterNewPassword: 'Enter a new password below',
        errorMessage: 'Something went wrong. Please reset your password again',
        image: {
          alt: 'Reset Account',
          src: 'https://cdn.shopify.com/s/files/1/3101/9164/files/login-600x600-v2-1.jpg?v=1639073776',
        },
        pageTitle: 'Reset Account',
        submit: 'Reset Password',
        successMessage: 'Password reset successful! Please login to continue.',
      },
      returnOrExchange: 'Return or exchange order',
      shipmentOnTheWay: 'Shipment Is On The Way',
      shippingAddress: 'Shipping Address',
      shippingAddresses: 'Shipping Addresses',
      shopMen: 'Shop Men',
      shopWomen: 'Shop Women',
      startAReturn: 'Start a Return',
      startAReturnLink: 'https://returns.wearfigs.com',
      stylePreferenceSelector: {
        title: 'Style Preference',
        men: 'Men',
        women: 'Women',
      },
      total: 'Total',
      trackOrder: 'Track Order',
      viewDetails: 'View Details',
    },

    claimGift: {
      errorMessage: {
        notFound:
          'This gift package does not exist. Please make sure the URL you have visited is correct.',
        internalError: 'There was an unknown error while loading your gift package.',
      },
      styleChoice: 'What styles are you shopping for?',
      optionChoice: 'Choose your gift',
      embroideryNotice:
        'embroidery has been added to your product(s) and is included with your order.',
    },
    fitQuiz: {
      size: 'Size',
      sizeType: 'Size Type',
      bestFit: 'Your Best Fit',
      findMyFit: 'Find My Fit',
      toolTipBody:
        "If your recommended size type isn't offered for this product, we advise you choose:",
      loadingText: 'AWESOME IS ON THE WAY 🙌',
      progressText1: 'We give a fit',
      progressText2: 'YOUR FIT RESULTS 📃',
      termAgreement: {
        byClicking: 'By clicking',
        understanding: 'and using our Fit Tool, you agree to our',
        and: 'and',
        termsOfUse: 'Terms of Use,',
        privacyPolicy: 'Privacy Policy',
        humaneticsPrivacyPolicy: 'the Humanetics Privacy Policy',
        agreement:
          '(collectively, the "Privacy Policies"). You further understand and agree that the information provided may be used by FIGS and Humanetics to suggest products and sizes as set forth in the Privacy Policies.',
        frontQuote: '"',
        endQuote: '"',
      },
      intro: {
        pointSymbol: '👉',
        login: 'Login',
        or: 'or',
        createAccount: 'Create an account',
        endText: 'to save your results for next time!',
      },
      formLabels: {
        height: 'height',
        weight: 'weight',
        age: 'age',
        gender: 'collection',
        male: 'Men',
        female: 'Women',
        measurements: 'measurements',
        submit: 'find your fit',
        reset: 'reset',
        cm: 'cm',
        inches: 'in',
        slideToggleCheckmarkLabel: 'metric units',
      },
      formUnits: {
        inches: 'inches',
        feet: 'feet',
        centimeters: 'centimeters',
        kilograms: 'kilograms',
        pounds: 'pounds',
        years: 'years',
      },
      formUnitsShorthand: {
        inches: 'in.',
        feet: 'ft.',
        centimeters: 'cm.',
        kilograms: 'kg.',
        pounds: 'lbs.',
      },
      formErrors: {
        requiredField: 'Please complete this field.',
        nonNumeric: 'Numeric value required',
        negative: 'Positive value required',
        weightError: 'Invalid weight',
        inchesError: 'Enter a value less than or equal to 12',
        ageError: 'Invalid age',
        feetError: 'Invalid height in feet',
        centimetersError: 'Invalid height in centimeters',
        minAgeError: 'Must be 18 or older to Find Your Fit',
      },
      apiError: {
        unavailable: 'unavailable',
        errorTitle: 'Uh oh!',
        errorDescription: 'We apologize but we are experiencing an issue getting your results.',
      },
      recommendations: {
        editFit: 'Edit Fit',
        myFit: 'My Fit',
        accordionTitle: 'Fits By Category',
        labels: {
          jackets: 'jackets',
          pants: 'pants',
          jumpsuits: 'scrubjumpsuits™',
          tops: 'tops',
          size: 'size',
          sizeType: 'size type',
        },
        sizes: {
          xxs: 'XXS',
          xs: 'XS',
          s: 'S',
          m: 'M',
          l: 'L',
          xl: 'XL',
          twoxl: '2XL',
          threexl: '3XL',
          fourxl: '4XL',
          fivexl: '5XL',
        },
        fits: {
          petite: 'Petite',
          regular: 'Regular',
          short: 'Short',
          tall: 'Tall',
        },

        restartQuiz: 'try again',
        finishedQuiz: 'all done!',
      },
    },
    general: {
      cancel: 'Cancel',
      confirmPassword: 'Confirm Password',
      create: 'Create',
      email: 'Email',
      emailLabel: 'email',
      emailOptIn:
        'Yes, I would like to receive emails from FIGS about products, updates, and exclusive offers and promotions. You can unsubscribe at any time. See our',
      emailWarning: 'Please enter a valid email',
      errorHasOccurred: 'An error has occurred, please visit https://help.wearfigs.com',
      firstName: 'First Name',
      forgotYourPassword: 'Forgot your password?',
      here: 'here.',
      hidePassword: 'Hide Password',
      incorrectCredentials: 'Incorrect Email or Password',
      lastName: 'Last Name',
      logIn: 'Log In',
      logout: 'Logout',
      maxPasswordLengthValidationMessage: 'Password length cannot exceed 40 characters',
      muteVideo: 'Mute video',
      unmuteVideo: 'Unmute video',
      pauseVideo: 'Pause video',
      password: 'Password',
      passwordLabel: 'password',
      passwordResetInstructions:
        'Enter your email below and we will email you instructions to reset your password within a few minutes!',
      passwordsDoNotMatch: 'Passwords do not match',
      passwordWarning: 'Please enter a password',
      playVideo: 'Play video',
      privacyPolicy: 'Privacy Policy',
      requestedEmailNotFound:
        'The requested email address could not be found. Please register your account',
      requiredText: 'Please fill out this field',
      resetYourPassword: 'Reset your password',
      showPassword: 'Show Password',
      submit: 'Submit',
      termsAcceptance: 'By clicking the button above, you agree to our',
      termsOfUse: 'Terms of Use',
    },
    giftCards,
    imageLibrary: {
      imageLink: 'Image Link',
      pageTitle: 'FIGS Image Library',
      productLink: 'Product Link',
      switchToDetailedView: 'Switch View (Detailed)',
      switchToGridView: 'Switch View (Grid)',
    },
    productCarouselColorCount: 'Color',
    reviewsPage: {
      averageFit: {
        averageFit: 'Average Fit',
        tooSmall: 'Too Small',
        trueToSize: 'True to Size',
        tooBig: 'Too Big',
      },
      backToKit: 'Back to Kit',
      backToProduct: 'Back to Product',
      clearFilters: 'Clear all filters',
      emptyMessage: 'There are no reviews yet. Be the first to comment on this product.',
      filteredEmptyMessage:
        'Sorry, no reviews match your criteria. Clear or modify your filters and try again.',
      filters: {
        header: 'Filter',
        clearAll: 'Clear All',
        filter: {
          'rating': { title: 'Rating', singular: '{{rating}} Star', plural: '{{rating}} Stars' },
          'Photos': {
            title: 'Photos',
            values: { 'Show Reviews with Photos': 'Show Reviews with Photos' },
          },
          'Size Purchased': {
            title: 'Size Purchased',
            values: {
              'XXS': 'XXS',
              'XS': 'XS',
              'S': 'S',
              'M': 'M',
              'L': 'L',
              'XL': 'XL',
              '2XL': '2XL',
              '3XL': '3XL',
              '4XL': '4XL',
              '5XL': '5XL',
              '6XL': '6XL',
              'S/M': 'S/M',
              'M/L': 'M/L',
              'N/A': 'N/A',
            },
          },
          'Fit': {
            title: 'Fit',
            values: {
              'Too Small': 'Too Small',
              'Small': 'Small',
              'True to Size': 'True to Size',
              'Big': 'Big',
              'Too Big': 'Too Big',
            },
          },
          'Height': {
            title: 'Height',
            values: {
              "5'0 or less": "5'0 or less",
              "5'1 - 5'3": "5'1 - 5'3",
              "5'4 - 5'6": "5'4 - 5'6",
              "5'7 - 5'9": "5'7 - 5'9",
              "5'10 - 6'0": "5'10 - 6'0",
              "6'1 - 6'3": "6'1 - 6'3",
              "6'4 - 6'6": "6'4 - 6'6",
              "6'7 or above": "6'7 or above",
            },
          },
          'Weight': {
            title: 'Weight',
            values: {
              'Under 100 lbs': 'Under 100 lbs',
              '101 - 125 lbs': '101 - 125 lbs',
              '126 - 150 lbs': '126 - 150 lbs',
              '151 - 175 lbs': '151 - 175 lbs',
              '176 - 200 lbs': '176 - 200 lbs',
              '201 - 225 lbs': '201 - 225 lbs',
              '226 - 250 lbs': '226 - 250 lbs',
              'Over 251 lbs': 'Over 251 lbs',
            },
          },
          'Occupation': {
            title: 'Occupation',
            values: {
              Dental: 'Dental',
              Nursing: 'Nursing',
              Physician: 'Physician',
              Specialize: 'Specialize',
              Student: 'Student',
              Teacher: 'Teacher',
              Tech: 'Tech',
              Veterinarian: 'Veterinarian',
              Other: 'Other',
            },
          },
          'Length Purchased': {
            title: 'Length Purchased',
            values: {
              Regular: 'Regular',
              Short: 'Short',
              Petite: 'Petite',
              Tall: 'Tall',
            },
          },
        },
      },
      filterSortButton: {
        sort: 'Sort',
        filter: 'Filter',
      },
      fitSurvey: 'Fit Survey',
      headline: 'Product Reviews',
      loadingInProgress: 'Loading...',
      reviewForm: {
        formSubmissionErrorMessage: 'Whoops! Something went wrong on our end. Please try again.',
        inputLabels: {
          email: 'email',
          name: 'name',
          occupation: 'occupation',
          reviewBody: 'review',
          starRating: 'score',
          subject: 'subject',
        },
        submitCta: 'post',
        validationErrorMessages: {
          requiredStarRating: 'Please select a star rating',
          requiredText: 'Please fill out this field',
          validEmail: 'Please enter a valid email address',
        },
      },
      readLess: 'Read Less',
      readMore: 'Read More',
      reviewsTotalTitle: {
        text: 'All',
        pluralSuffix: 'Reviews',
        suffix: 'Review',
      },
      reviewsViewBaseUrl: 'https://shop.wearfigs.com/products',
      search: 'Search...',
      sort: {
        sortBy: 'Sort By',
        newest: 'Newest',
        highestRating: 'Highest Rating',
        lowestRating: 'Lowest Rating',
      },
      thankYouHeadline: 'thank you for posting a review!',
      thankYouParagraph:
        'We value your input. Share your review so everyone else can enjoy it too.',
      writeReviewCta: 'Write a Review',
    },
    searchPage: {
      relatedSearchesTitle: 'Related Searches',
      resultNoun: 'result',
    },
    promoBar: {
      previous: 'previous',
      next: 'next',
    },
    navigation: {
      accessibilityLink: 'Click to view our accessibility statement link',
      cartButtonAriaLabel: 'Shopping Bag',
      closeButtonLabel: 'Close modal',
      hamburgerAriaLabel: 'Navigation menu',
      logoTitle: 'FIGS Home',
      skipToMainContent: 'Skip to content',
    },
    orders: {
      emailPlaceHolder: 'name@email.com',
      haveAccount: 'Have an account? Sign in to manage your order:',
      logIntoAccount: 'Log Into Account',
      orderNotFound: "We couldn't find that order number.",
      orderNumber: 'Order Number',
      orderNumberPlaceHolder: 'ie. #123456789',
      orderWarning: 'Please enter an order number',
      pageTitle: 'View Order Details',
      retry: 'Retry',
      seeMyOrders: 'See My Orders',
      viewOrder: 'View Order',
      viewOrderDetails: 'View Order Details',
      orderDigitsOnly: 'Order number must be only digits',
      orderNotValid: 'Not a valid order number',
    },
    unsupportedBanner: {
      headline: 'Your browser is out-of-date.',
      links: {
        chrome: {
          text: 'Chrome',
          url: 'https://www.google.com/chrome/',
        },
        firefox: {
          text: 'Firefox',
          url: 'https://www.mozilla.org/en-US/firefox/',
        },
        opera: {
          text: 'Opera',
          url: 'https://www.opera.com/',
        },
        safari: {
          text: 'Safari',
          url: 'https://www.apple.com/safari/',
        },
      },
      text: "To get the best experience using our site, you'll need to update to a newer browser.",
    },
    priceInfo: {
      percentageOff: '{{percentage}}% off',
      upToPercentageOff: 'Up to {{percentage}}% off',
    },
  },
  intlWelcomeModal: {
    welcome: 'WELCOME',
    easierToBuy: "Now It's Easier Than Ever To Buy FIGS!",
    shopInYourLocalCurrency: 'Shop in your local currency',
    continueShopping: 'Continue Shopping',
    changeCountry: 'Change Country',
  },
  cancellationModal: {
    wantToCancel: 'Want to cancel your order?',
    itemsRemoved:
      'All items will be removed and a refund will be issued in 7 - 9 business days to the original payment method.',
    cancelOrder: 'Cancel Order',
    hangOn: 'Hang on while we process your request...',
    cancellationConfirmed: 'Cancellation Confirmed.',
    cancellationBody:
      'You have received a confirmation email and a refund will be issued in 7 - 9 business days to the original payment method.',
    close: 'Close',
    goBack: 'Go Back',
    unableToCancel: 'Unable to cancel order.',
    wereSorry: "We're sorry, but we are unable to complete your order cancellation at this time.",
    contactSupport: 'Contact Support',
  },
  collections: {
    filtering: {
      visualFilters: {
        label: 'Style Filters',
      },
      desktopFilterPanel: {
        clearAllFiltersCta: 'Clear all',
        headline: 'Filter',
      },
      desktopFilterBar: {
        clearAllFiltersCta: 'CLEAR ALL',
      },
      filterModal: {
        applyFiltersCta: 'Apply',
        clearAllFiltersCta: 'Clear All',
        ellipsis: '...',
        headline: 'Filter By',
        resetAllFiltersCta: 'Reset',
        viewCtaPrefix: 'View',
        viewCtaPostfix: 'Items',
      },
      mobileFilterPanel: {
        clearAllFiltersCta: 'Clear All',
        filterCta: 'Filter',
        items: 'items',
      },
      noResults: {
        title: 'No results found.',
        subtitle: 'Change your filters.',
      },
      categoryLabelOverrides: {
        productKit: 'Kits',
      },
    },
    heroSection: {
      playVideo: 'Play video',
    },
    productGroupTile: {
      fitsAvailabilityBeginningText: 'Also in',
      colorsAvailabilityNoun: 'Color',
      valueSignalLabel: 'Value',
      selectColorsOnSale: 'Select Colors on Sale',
    },
    productGroupTilesPanel: {
      backToTopText: 'Back to Top',
      loadMoreProductsCta: 'Load More Products',
    },
    genderNavSection: {
      womens: 'Women',
      mens: 'Men',
    },
  },
  loadMoreButton: {
    viewing: 'Viewing',
    of: 'of',
    loading: 'Loading',
  },
  embroidery,
  detailsSection,
  favorites,
  fitModel,
  footerV1: enUsFooter,
  globals,
  idme: {
    failure: 'Problem authenticating ID.me. Please log in with ID.me in checkout.',
    loading: 'Loading...',
    missing: 'Please log in with ID.me in checkout.',
    success: 'Redirecting to checkout, please wait.',
  },
  search: {
    errorMessage: 'There was a problem with your request. Please try again.',
    inputPlaceholder: 'Search',
    inputRequiredMessage: 'Please fill out this field',
    popularSearchTitle: 'popular searches',
    searchButtonAriaLabelClose: 'Close Search',
    searchButtonAriaLabelClear: 'Clear Search Text',
    searchButtonAriaLabelOpen: 'Open Search',
    searchButtonAriaLabelSubmit: 'Submit Search',
  },
  cart,
  freeShipping,
  giftCard,
  pages: {
    notFound,
    pageGoneError,
    product,
  },
  sizesSection,
  trayModal: {
    close: 'Close Modal',
  },
  waitlist,
  googleOptimizeExperiments,
  regionAndLocalePicker,
  affirm,
  afterpay,
  pageSections,
  testimonials,
  emailCapture,
  quickBuy: {
    overlay: {
      addingIndication: 'Adding...',
      cta: 'Quick Buy',
    },
    modal: {
      headline: 'Quick Buy',
    },
    soldOutOverlayMessage: 'sold out',
    waitlistOverlayMessage: 'waitlist',
  },
  selectAndAddVariant: {
    addToBagButton: {
      cta: 'add to bag',
      inFlightAddToBagCta: 'adding...',
      selectEmbroidery: 'select embroidery',
      soldOutCta: 'sold out',
      viewProduct: 'View Product',
      selectOptions: 'Select Options',
    },
    colorTitle: 'Color',
    fitSectionTitle: 'length',
    sizeSection: {
      title: 'size',
      titleWhenInvalid: 'select size',
      sizeChartCta: 'Size Chart',
    },
    soldOutMessage: '(Sold Out)',
  },
  shopTheLook: {
    headline: 'Wear It With',
  },
  bulk: {
    header: 'Bulk Order',
    alertCta:
      'Orders that require text or logo embroidery need to be completed from individual product pages at this time.',
    styleGuideDownloadCta: 'Download Style Guide',
    bagSoFar: {
      title: 'Your bag so far',
      items: 'Item(s) Added to Bag',
    },
  },
  studentPage: {
    header: 'How to get your student discount',
    title1: 'step 1',
    title2: 'step 2',
    instructions1: 'Click on the Student Discount link in Checkout.',
    instructions2: 'Verify your info with ID.me, and your discount will be automatically applied.',
    disclaimer:
      "*Discount applies to college, university, community college, and technical college students at US institutions only. To get your FIGS discount, complete this super-easy verification and we'll issue promo code for 15% off most products. Use your promo code at checkout. Voila! FIGS at 15% OFF — you've earned it. Eligible for students at US institutions only.*",
  },
  /**
   * Contentful SiteFixtures fields, overridden in FixturesContext on query success or failure.
   *
   * Contentful SiteFixtures fields with fallbacks (navigation, footer) are treated differently
   * from fields without a fallback, which can be much simpler. When fallback.foo is null, just
   * use null here, directly, so we do not need to inspect the fallback data and can easily see
   * which fields have actual fallback data.
   *
   * Be careful not to confuse fallback data with mock data.
   * - Fallback is in production. It is not mock data, it is real and must remain valid backup.
   * - For unit test mock data, see src/containers/fixtures/hooks/__mocks__/useCmsProvidedSiteFixtures.ts.
   */
  disableOneTrust: null,
  disableOptimizely: null,
  embroideryIcon: null,
  enablePDPAccentColor: null,
  footer: fallback.footer,
  miniCartMessage: null,
  navigation: fallback.navigation,
  navV1: fallbackNavV1,
  promoBar: null,
  upsellHandles: null,
  nostoUpsell: null,
  nostoUpsellId: null,
  siteWidePromo: null,
  logoColorSelectionTooltip: null,
  logoDigitizationDisclaimer: null,
  logoDigitizationWarning: null,
  logoLearnMorelink: null,
  logoDigitizationRequestedTooltip: null,
}
