import { useRouter } from 'next/router'
import { type ReactNode, useMemo } from 'react'

import { Bag, TypeStyle } from '@syconium/little-miss-figgy'
import { CartContainer } from '@syconium/magnolia/src/brunswick/containers/cart'
import { useFixturesContext } from '@syconium/magnolia/src/brunswick/containers/fixtures'
import { MiniCartSlideOutContainer } from '@syconium/magnolia/src/brunswick/containers/mini-cart-slideout'
import { useGoogleTagManager } from '@syconium/magnolia/src/lib/hooks/useGoogleTagManager'
import { NavTab } from '@syconium/magnolia/src/types/graphql'

import { useLocalization } from '../../../app/_providers/LocalizationProvider.client'
import { useFeatureFlag } from '../../../containers/ExperimentationContainer'

import { CartButtonWrapper, CartNotification } from './styles'

export function useCartTab(): NavTab {
  const {
    magnolia: { navigation: strings },
  } = useFixturesContext()
  const { cart } = CartContainer.useContainer()
  const { toggleIsRevealed } = MiniCartSlideOutContainer.useContainer()
  const { pushOpenMiniCart } = useGoogleTagManager()
  const { pathname } = useRouter()
  const { region } = useLocalization()

  const cartIconDecision = useFeatureFlag({
    key: 'cart-icon-test',
    defaultVariant: 'no-cart-notification',
    ifAccessedPriorToDecisionInitialization: 'return-null-while-pending',
  })

  const isCartPage: boolean = pathname === '/cart'

  const cartUI: ReactNode = useMemo(() => {
    if (cart.itemCount < 1 || cartIconDecision === null) {
      return <Bag />
    }

    if (cartIconDecision !== 'yes-cart-notification') {
      return (
        <>
          <Bag />
          <TypeStyle.HeadlineMicro>{cart.itemCount}</TypeStyle.HeadlineMicro>
        </>
      )
    }
    // TODO: following A/B test, if we go with the variant we need to update our styles
    return (
      <CartButtonWrapper>
        <Bag />
        <CartNotification notificationLength={cart.itemCount.toString().length}>
          {cart.itemCount > 99 ? '99+' : cart.itemCount}
        </CartNotification>
      </CartButtonWrapper>
    )
  }, [cart.itemCount, cartIconDecision])

  const cartTab: NavTab = {
    ariaLabel: strings.cartButtonAriaLabel,
    href: null,
    id: 'cart',
    onClickish: () => {
      if (isCartPage) return
      toggleIsRevealed()
      pushOpenMiniCart(cart, region.currency)
    },
    render: () => cartUI,
    sections: [],
    text: '',
    textColor: null,
    wrapperElement: 'button',
  }

  return cartTab
}
