import { useGoogleTagManager } from '@syconium/magnolia/src/lib/hooks/useGoogleTagManager'

import { CartItemProperties } from '../../../../../types/figs'
import { filterPropertiesToDisplay } from '../../utils'

export function useViewModel(properties: CartItemProperties) {
  const { pushProductClick } = useGoogleTagManager()
  const propertiesToDisplay = filterPropertiesToDisplay(properties)

  return {
    pushProductClick,
    propertiesToDisplay,
  }
}
