'use client'

import * as React from 'react'
import { useTheme } from 'styled-components'

import { IProps } from '../BaseIcon'
import { BaseIcon } from '../BaseIcon/BaseIcon'

export const Plus: React.FC<IProps> = ({ height = '12px', stroke, width = '12px', ...rest }) => {
  const { color } = useTheme()

  return (
    <BaseIcon height={height} width={width} viewBox='0 0 12 12' {...rest}>
      <g
        stroke={stroke || color.deprecated.black.primary}
        strokeWidth='1.5'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='square'
      >
        <path d='M6 1.665v8.92M10.335 6h-8.92' />
      </g>
    </BaseIcon>
  )
}
